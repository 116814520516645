import moment from 'moment';
import { BasicUserData } from '../../../users/pages/ProfilePage';
import { IEmploymentObject } from '../../../users/components/work/WorkExperienceUpdate';
import { IEducationObject } from '../../../users/components/education/EducationUpdate';


const isValidDate = (date: string | number) => {
  if (!date) return false;

  return moment(date).isValid();
};

const isValidDateRange = (startDate: string | number, endDate: string | number) => {
  return moment(startDate).isBefore(endDate);
};

const isValidWorkExperience = (workExperience: IEmploymentObject) => {
  const { startDate, endDate, current, companyName, title } = workExperience;
  if (!companyName || !title) return false;
  if (!isValidDate(startDate)) return false;

  return current
    ? isValidDateRange(startDate, moment().format('YYYY-MM-DD'))
    : isValidDate(endDate) && isValidDateRange(startDate, endDate);
}

const isValidEducation = (education: IEducationObject) => {
  const { startDate, endDate, school, status, degree, major } = education;
  if (!school || !status || !degree || !major) return false;
  
  return isValidDate(startDate) && isValidDate(endDate) && isValidDateRange(startDate, endDate);
}

export const validatedCandidate = (candidateToValidate?: BasicUserData) => {
  if (!candidateToValidate) return;
  const { education, employment } = candidateToValidate;

  const validEducationItems = (education || []).map((educationItem: IEducationObject) => {
    return {
      ...educationItem,
      isValid: isValidEducation(educationItem)
    }
  });

  const validEmploymentItems = (employment || []).map((employmentItem: IEmploymentObject) => {
    return {
      ...employmentItem,
      isValid: isValidWorkExperience(employmentItem)
    }
  });

  const result = {
    ...candidateToValidate,
    education: education ? validEducationItems : undefined,
    employment: employment ? validEmploymentItems : undefined
  }

  return result;
}


export default validatedCandidate;