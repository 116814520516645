import React, { useContext, useEffect, useState, useCallback } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "../../shared/util/validators";
import ProfileHeader from "../../shared/components/navigation/ProfileHeader";
import FileUpload from "../../shared/components/FormElements/FileUpload";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import { useResume } from "../../shared/hooks/useResume";
import Card from "../../shared/components/UIElements/Card";
import { errorHandler } from "../../shared/util/helpers";
import { useForm } from "../../shared/hooks/form-hook";

import { ETHINICITY_LIST, GENDERS_LIST, PRONOUNS_LIST, VETERAN_STATUS_LIST } from "../../shared/util/constants";

import { BasicUserData } from "../pages/ProfilePage";

import { useUpdateCandidate } from "../../components/Candidate/hooks/useUpdateCandidate";
import { AuthContext } from "./context/auth-context";
import IconTitle from "./IconTitle";
import "./BasicData.css";

const mimeTypes: { [key: string]: string } = {
  "image/bmp": "bmp",
  "image/gif": "gif",
  "image/tiff": "tif",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/svg+xml": "svg",
  "image/webp": "webp",
};

const BasicData = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [disabledPic, setDisabledPic] = useState(false);
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const { email, thorUser, getToken, isOnboarding } = useContext(AuthContext);

  const { isEditing = false } = location.state ?? {};

  const { parsedUser } = useResume(email);

  const isOther = (value: string | undefined, list: { label: string; value: string }[]): boolean => {
    return !!value && !list.find((i: { label: string; value: string }) => i.label === value);
  };

  const isInitOtherGender = isOther(thorUser?.gender, GENDERS_LIST);
  const isInitOtherPronouns = isOther(thorUser?.pronouns, PRONOUNS_LIST);
  const isInitOtherEthnicity = isOther(thorUser?.ethnicity, ETHINICITY_LIST);

  const [formState, inputHandler] = useForm(
    {
      ...(!isOnboarding && {
        firstName: {
          value: "",
          isValid: false,
        },
        lastName: {
          value: "",
          isValid: false,
        },
      }),
      phoneNumber: {
        value: thorUser?.phone || parsedUser?.phone || "",
        isValid: false,
      },
      imgProfile: {
        value: null,
        isValid: true,
      },
      gender: {
        value: isInitOtherGender ? "Prefer to self describe" : thorUser?.gender || "",
        isValid: true,
      },
      otherGender: {
        value: isInitOtherGender ? thorUser?.gender : "",
        isValid: !isInitOtherGender,
      },
      pronouns: {
        value: isInitOtherPronouns ? "Prefer to self describe" : thorUser?.pronouns || "",
        isValid: true,
      },
      otherPronouns: {
        value: isInitOtherPronouns ? thorUser?.pronouns : "",
        isValid: !isInitOtherPronouns,
      },
      ethnicity: {
        value: isInitOtherEthnicity ? "Prefer to self describe" : thorUser?.ethnicity || "",
        isValid: true,
      },
      otherEthnicity: {
        value: isInitOtherEthnicity ? thorUser?.ethnicity : "",
        isValid: !isInitOtherEthnicity,
      },
      veteran: {
        value: thorUser?.veteran ?? "",
        isValid: true,
      },
    },
    false
  );

  const image = formState.inputs.imgProfile.value;
  const isOtherGender = formState.inputs.gender?.value === "Prefer to self describe";
  const isOtherPronouns = formState.inputs.pronouns?.value === "Prefer to self describe";
  const isOtherEthnicity = formState.inputs.ethnicity?.value === "Prefer to self describe";

  const { mutate: updateCandidate } = useUpdateCandidate();

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    const token = await getToken();
    event.preventDefault();
    const {
      inputs: {
        firstName,
        lastName,
        phoneNumber,
        gender,
        otherGender,
        pronouns,
        otherPronouns,
        ethnicity,
        otherEthnicity,
        veteran,
      },
    } = formState;

    let profileURL = "";
    if (image) {
      const contentType = image.split(";")[0].split(":")[1].toLowerCase();
      const ext = mimeTypes[contentType];

      setDisabledPic(true);

      const getImgUrls = await axios.get(`${process.env.REACT_APP_AWS_DEV_URL}/picturePostURL/${email}?suffix=.${ext}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (getImgUrls.status === 200) {
        try {
          const bStr = atob(image.split(",")[1]);
          let n = bStr.length;
          const fileArr = new Uint8Array(n);

          while (n--) {
            fileArr[n] = bStr.charCodeAt(n);
          }

          const putImg = await axios.put(
            getImgUrls.data.putURL,
            new File([fileArr], `${encodeURIComponent(email || "error")}picture.${ext}`, { type: ext }),
            { headers: { "Content-Type": contentType } }
          );

          if (putImg.status === 200) {
            profileURL = getImgUrls.data.getURL;
          }
        } catch (error) {
          if (error instanceof Error) errorHandler({ error });
        }
      }
      setDisabledPic(false);
    }

    const requestObject = {
      firstName: firstName?.value,
      lastName: lastName?.value,
      phone: phoneNumber.value,
      profilePicture: profileURL,
      gender: isOtherGender ? otherGender.value : gender.value,
      pronouns: isOtherPronouns ? otherPronouns.value : pronouns.value,
      ethnicity: isOtherEthnicity ? otherEthnicity.value : ethnicity.value,
      veteran: veteran?.value,
    };

    try {
      updateCandidate(requestObject);
      navigate(isEditing ? "/profile/page" : "/profile/job-status");
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "There was an error saving your data" }).message,
        });
      }
    }
  };

  useEffect(() => {
    if (!email) navigate("/");
  }, [email, navigate]);

  const getValueToFill = useCallback(
    (key: keyof BasicUserData): string | undefined => {
      if (thorUser?.[key]) return thorUser[key];
      if (parsedUser?.[key]) return parsedUser[key];
      return undefined;
    },
    [parsedUser, thorUser]
  );

  const handleSelectChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    const val = newValue?.label === "Select..." ? "" : newValue?.label;
    return inputHandler(actionMeta.name || "", val || "", true);
  };

  const setSelectValue = (
    val: string | undefined,
    options: { value: string; label: string }[]
  ): { value: string; label: string } => {
    const other = "Prefer to self describe";
    return options.find((i) => i.label === val) || (val ? { value: "other", label: other } : { value: "", label: "Select..." });
  };

  useEffect(() => {
    let isOtherGenderValid = formState.inputs.otherGender.value;
    let isOtherPronounsValid = formState.inputs.otherPronouns.value;
    let isOtherEthnicityValid = formState.inputs.otherEthnicity.value;

    if (!isOtherGender) {
      isOtherGenderValid = true;
    }
    if (!isOtherPronouns) {
      isOtherPronounsValid = true;
    }

    if (!isOtherEthnicity) {
      isOtherEthnicityValid = true;
    }

    inputHandler("otherGender", formState.inputs.otherGender.value, isOtherGenderValid);
    inputHandler("otherPronouns", formState.inputs.otherPronouns.value, isOtherPronounsValid);
    inputHandler("otherEthnicity", formState.inputs.otherEthnicity.value, isOtherEthnicityValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.inputs.gender.value, formState.inputs.pronouns.value, formState.inputs.ethnicity.value]);

  return (
    <div className="basic-data__container">
      <Card className={"signup-content__container"}>
        <ProfileHeader progress={22} />
        <div className="signup-form__container">
          <h1>{!isOnboarding ? "Edit Profile" : "Let's start with the basics"}</h1>
          <form className="form-container" onSubmit={submitHandler} encType="multipart/form-data">
            {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
            <FileUpload
              type="Image"
              buttonText={""}
              id="imgProfile"
              showWarning={true}
              label="Upload Profile photo (optional)"
              onInput={inputHandler}
              initialValue={thorUser?.profilePicture}
              disabled={disabledPic}
            />
            {!isOnboarding && (
              <>
                <Input
                  type={"text"}
                  id="firstName"
                  element="input"
                  label="First Name"
                  placeholder="Jane"
                  errorText="Please enter a name"
                  validators={[VALIDATOR_REQUIRE()]}
                  initialValue={getValueToFill("firstName")}
                  inputCallback={inputHandler}
                  initialIsValid={!!getValueToFill("firstName")}
                />
                <Input
                  type={"text"}
                  id="lastName"
                  element="input"
                  label="Last Name"
                  placeholder="Doe"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter your Last Name"
                  initialValue={getValueToFill("lastName")}
                  initialIsValid={!!getValueToFill("lastName")}
                  inputCallback={inputHandler}
                />
              </>
            )}
            <Input
              type={"text"}
              element="input"
              id="phoneNumber"
              label="Phone Number"
              placeholder="(888)888-8888"
              errorText="Enter a valid phone Number"
              inputCallback={inputHandler}
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(10)]}
              initialValue={getValueToFill("phone") ?? ""}
              initialIsValid={!!getValueToFill("phone")}
              description="We may call or text you regarding job opportunities presented on this platform"
            />
            <div className="form-control select">
              <IconTitle id="gender" title="Gender" />
              <Select
                id="gender"
                name={"gender"}
                options={GENDERS_LIST}
                onChange={handleSelectChange}
                defaultValue={setSelectValue(getValueToFill("gender"), GENDERS_LIST)}
                menuPlacement="top"
              />
              {isOtherGender && (
                <Input
                  type={"text"}
                  id="otherGender"
                  element="input"
                  noLabel
                  inputCallback={inputHandler}
                  validators={[VALIDATOR_REQUIRE()]}
                  initialIsValid
                  initialValue={formState.inputs.otherGender.value}
                />
              )}
            </div>
            <div className="form-control select">
              <IconTitle id="pronouns" title="Preferred Pronouns" />
              <Select
                id="pronouns"
                name={"pronouns"}
                options={PRONOUNS_LIST}
                onChange={handleSelectChange}
                defaultValue={setSelectValue(getValueToFill("pronouns"), PRONOUNS_LIST)}
                menuPlacement="top"
              />
              {isOtherPronouns && (
                <Input
                  type={"text"}
                  id="otherPronouns"
                  element="input"
                  noLabel
                  inputCallback={inputHandler}
                  validators={[VALIDATOR_REQUIRE()]}
                  initialIsValid
                  initialValue={formState.inputs.otherPronouns.value}
                />
              )}
            </div>
            <div className="form-control select">
              <IconTitle id="ethnicity" title="Race/Ethnicity" />
              <Select
                id="ethnicity"
                name={"ethnicity"}
                options={ETHINICITY_LIST}
                onChange={handleSelectChange}
                defaultValue={setSelectValue(getValueToFill("ethnicity"), ETHINICITY_LIST)}
                menuPlacement="top"
              />
              {isOtherEthnicity && (
                <Input
                  type={"text"}
                  id="otherEthnicity"
                  element="input"
                  noLabel
                  inputCallback={inputHandler}
                  validators={[VALIDATOR_REQUIRE()]}
                  initialIsValid
                  initialValue={formState.inputs.otherEthnicity.value}
                />
              )}
            </div>
            <div className="form-control select">
              <IconTitle id="veteran" title="Veteran Status" />
              <Select
                id="veteran"
                name={"veteran"}
                options={VETERAN_STATUS_LIST}
                onChange={handleSelectChange}
                defaultValue={setSelectValue(getValueToFill("veteran"), VETERAN_STATUS_LIST)}
                menuPlacement="top"
              />
            </div>
            {isEditing ? (
              <div className="edit-form__button-container">
                <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                  Cancel
                </Button>
                <Button size="big" disabled={!formState.isValid || disabledPic}>
                  Save
                </Button>
              </div>
            ) : (
              <Button disabled={!formState.isValid || disabledPic} size={"whole"}>
                Next
              </Button>
            )}
          </form>
        </div>
      </Card>
    </div>
  );
};

export default BasicData;
