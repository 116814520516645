export interface AvailableOptions {
  value: string;
  label: string;
  isSelected: boolean;
  data?: any;
}

export const selectInitialOptionsFromState = (
  wholeList: Array<AvailableOptions>,
  selectedList: Array<string>,
  data?: Array<any>,
): Array<AvailableOptions> => {
  const initialOptions: Array<AvailableOptions> = [];
  const initialData: Array<any> = [];
  wholeList.forEach((o) => initialOptions.push({ ...o }));
  data?.forEach((o) => initialData.push({ ...o }));

  const locations = selectedList.filter((selOption) => selOption !== "Remote");
console.log("locations", locations)
  if (locations.length > 0) {
    for (let i = 0; i < initialOptions.length; i++) {
      for (let x = 0; x < locations.length; x++) {
        if (initialOptions[i].label === locations[x]) {
          locations.splice(x, 1);
          initialOptions[i].isSelected = true;
          if (data) {
            initialOptions[i].data = {...data[x], ...initialOptions[i].data};
            initialData.splice(x, 1);
          }
        }
      }
    }
  }
  locations.forEach((location, index) =>{
    initialOptions.push({
      value: `location_${index}`,
      label: location,
      isSelected: true,
      ...(data && { data: initialData[index] }),
    })
});
  
  return initialOptions;
}