import { Button } from "@vendition/vendition-frontend";
import styled from "styled-components";

export const ActionContainer = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  .title-holder {
    max-width: 50%;
    text-align: left;
  }

  .title-holder .title {
    font-weight: 600;
  }

  .title-holder .description {
    font-size: 14px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .title-holder {
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const PanelContainer = styled.div`
  padding: 2rem;
  box-sizing: border-box;

  h2 {
    color: #bebebe;
    font-size: 17px;
    font-weight: 500;
  }
`;


// AnchorButton is a styled Button as an anchor
export const AnchorButton = styled(Button).attrs({
  as: "a",
  target: "_blank",
  rel: "noreferrer"
})`
  text-decoration: none;
  background-color: #0A9AFF;
  border-radius: 6px;
  width: 184px;
  padding: 12px 32px;
  border: 1px solid #0A9AFF;
  font-size: 0.9rem;
  font-weight: bold;
  color: white;
  margin: 10px 0 5px;
`;

export const StyledButton = styled(Button)`
  width: 250px;
  margin: 10px 0 5px;
`;