import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Button } from "@vendition/vendition-frontend";
import { useRedirection } from './useRedirection';
import { IProfileCompletion } from '../Dashboard/Dashboard';
import { useCandidate } from '../../../../components/Candidate/hooks/useCandidate';

interface IDashboardHeaderProps {
  profileCompletion: IProfileCompletion;
  companiesInterested: boolean;
}
interface IHeaders {
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
}

const DashboardHeader = ({ profileCompletion, companiesInterested }: IDashboardHeaderProps) => {
  const navigate = useNavigate();
  const redirect = useRedirection();
  const { refetch } = useCandidate();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const createHeaders = (): IHeaders => {
    if (companiesInterested)
      return {
        title: "You Got Matches",
        description: "Choose the companies you’re interested to work with.",
        buttonText: "Select Companies",
        buttonAction: () => navigate('/interviews/'),
      };
    if (profileCompletion.isComplete)
      return {
        title: "We Got Your Application",
        description: "After reviewing your profile, we’ll reach out to you if we find matches.",
        buttonText: "View Profile",
        buttonAction: () => navigate("/profile/page"),
      };

    return {
      title: "Help Us Get To Know You",
      description: "Your profile helps our team know who you are and match you to employers",
      buttonText: "Complete Your Profile",
      buttonAction: () => redirect(),
    };
  };

  return (
    <div className="dashboard-container__header">
      <h1>{createHeaders().title}</h1>
      <h3>{createHeaders().description}</h3>
      <Button size="big" onClick={createHeaders().buttonAction}>
        {createHeaders().buttonText}
      </Button>
    </div>
  );
};

export default DashboardHeader;
