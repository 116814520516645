import { AvailableOptions } from "../../users/components/location_info/selectedLocationsHelper";

export const DUMMY_YEARS = [
  { value: "1", label: "1900" },
  { value: "2", label: "1901" },
  { value: "3", label: "1902" },
  { value: "4", label: "1903" },
  { value: "5", label: "1904" },
  { value: "6", label: "1905" },
  { value: "7", label: "1906" },
  { value: "8", label: "1907" },
  { value: "9", label: "1908" },
  { value: "10", label: "1909" },
  { value: "11", label: "1910" },
  { value: "12", label: "1911" },
  { value: "13", label: "1912" },
  { value: "14", label: "1913" },
  { value: "15", label: "1914" },
  { value: "16", label: "1915" },
  { value: "17", label: "1916" },
  { value: "18", label: "1917" },
  { value: "19", label: "1918" },
  { value: "20", label: "1919" },
  { value: "21", label: "1920" },
  { value: "22", label: "1921" },
  { value: "23", label: "1922" },
  { value: "24", label: "1923" },
  { value: "25", label: "1924" },
  { value: "26", label: "1925" },
  { value: "27", label: "1926" },
  { value: "28", label: "1927" },
  { value: "29", label: "1928" },
  { value: "30", label: "1929" },
  { value: "31", label: "1930" },
  { value: "32", label: "1931" },
  { value: "33", label: "1932" },
  { value: "34", label: "1933" },
  { value: "35", label: "1934" },
  { value: "36", label: "1935" },
  { value: "37", label: "1936" },
  { value: "38", label: "1937" },
  { value: "39", label: "1938" },
  { value: "40", label: "1939" },
  { value: "41", label: "1940" },
  { value: "42", label: "1941" },
  { value: "43", label: "1942" },
  { value: "44", label: "1943" },
  { value: "45", label: "1944" },
  { value: "46", label: "1945" },
  { value: "47", label: "1946" },
  { value: "48", label: "1947" },
  { value: "49", label: "1948" },
  { value: "50", label: "1949" },
  { value: "51", label: "1950" },
  { value: "52", label: "1951" },
  { value: "53", label: "1952" },
  { value: "54", label: "1953" },
  { value: "55", label: "1954" },
  { value: "56", label: "1955" },
  { value: "57", label: "1956" },
  { value: "58", label: "1957" },
  { value: "59", label: "1958" },
  { value: "60", label: "1959" },
  { value: "61", label: "1960" },
  { value: "62", label: "1961" },
  { value: "63", label: "1962" },
  { value: "64", label: "1963" },
  { value: "65", label: "1964" },
  { value: "66", label: "1965" },
  { value: "67", label: "1966" },
  { value: "68", label: "1967" },
  { value: "69", label: "1968" },
  { value: "70", label: "1969" },
  { value: "71", label: "1970" },
  { value: "72", label: "1971" },
  { value: "73", label: "1972" },
  { value: "74", label: "1973" },
  { value: "75", label: "1974" },
  { value: "76", label: "1975" },
  { value: "77", label: "1976" },
  { value: "78", label: "1977" },
  { value: "79", label: "1978" },
  { value: "80", label: "1979" },
  { value: "81", label: "1980" },
  { value: "82", label: "1981" },
  { value: "83", label: "1982" },
  { value: "84", label: "1983" },
  { value: "85", label: "1984" },
  { value: "86", label: "1985" },
  { value: "87", label: "1986" },
  { value: "88", label: "1987" },
  { value: "89", label: "1988" },
  { value: "90", label: "1989" },
  { value: "91", label: "1990" },
  { value: "92", label: "1991" },
  { value: "93", label: "1992" },
  { value: "94", label: "1993" },
  { value: "95", label: "1994" },
  { value: "96", label: "1995" },
  { value: "97", label: "1996" },
  { value: "98", label: "1997" },
  { value: "99", label: "1998" },
  { value: "100", label: "1999" },
  { value: "101", label: "2000" },
  { value: "102", label: "2001" },
  { value: "103", label: "2002" },
  { value: "104", label: "2003" },
  { value: "105", label: "2004" },
  { value: "106", label: "2005" },
  { value: "107", label: "2006" },
  { value: "108", label: "2007" },
  { value: "109", label: "2008" },
  { value: "110", label: "2009" },
  { value: "111", label: "2010" },
  { value: "112", label: "2011" },
  { value: "113", label: "2012" },
  { value: "114", label: "2013" },
  { value: "115", label: "2014" },
  { value: "116", label: "2015" },
  { value: "117", label: "2016" },
  { value: "118", label: "2017" },
  { value: "119", label: "2018" },
  { value: "120", label: "2019" },
  { value: "121", label: "2020" },
  { value: "122", label: "2021" },
  { value: "123", label: "2022" },
  { value: "124", label: "2023" },
  { value: "125", label: "2024" },
  { value: "126", label: "2025" },
  { value: "127", label: "2026" },
  { value: "128", label: "2027" },
  { value: "129", label: "2028" },
  { value: "130", label: "2029" },
  { value: "131", label: "2030" },
];

export const DUMMY_MONTHS = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const DUMMY_CITIES: Array<AvailableOptions> = [
  {
    value: "ChIJIQBpAG2ahYAR_6128GcTUEo",
    label: "San Francisco, CA",
    isSelected: false,
    data: {
      lat: 37.7749295,
      lon: -122.4194155,
    }
  },
  {
    value: "ChIJE9on3F3HwoAR9AhGJW_fL-I",
    label: "Los Angeles, CA",
    isSelected: false,
    data: {
      lat: 34.0522342,
      lon: -118.24368489999999,
    }
  },
  {
    value: "ChIJSx6SrQ9T2YARed8V_f0hOg0",
    label: "San Diego, CA",
    isSelected: false,
    data: {
      lat: 32.715738,
      lon: -117.1610838,
    }
  },
  {
    value: "ChIJLwPMoJm1RIYRetVp1EtGm10",
    label: "Austin, TX",
    isSelected: false,
    data: {
      lat: 30.267152999999997,
      lon: -97.7430608,
    }
  },
  {
    value: "ChIJOwg_06VPwokRYv534QaPC8g",
    label: "New York, NY",
    isSelected: false,
    data: {
      lat: 40.7127753,
      lon: -74.0059728,
    }
  },
  {
    value: "ChIJGzE9DS1l44kRoOhiASS_fHg",
    label: "Boston, MA",
    isSelected: false,
    data: {
      lat: 42.3600825,
      lon: -71.0588801,
    }
  },
  { value: "0", label: "Other", isSelected: false },
];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const GENDERS_LIST = [
  { value: "", label: "Select..." },
  { value: "Female", label: "Female" },
  { value: "Male", label: "Male" },
  { value: "Non-binary/non-conforming", label: "Non-binary/non-conforming" },
  { value: "Prefer not to respond", label: "Prefer not to respond" },
  { value: "other", label: "Prefer to self describe" },
];

export const PRONOUNS_LIST = [
  { value: "", label: "Select..." },
  { value: "She/Her", label: "She/Her" },
  { value: "He/Him", label: "He/Him" },
  { value: "They/Them", label: "They/Them" },
  { value: "Prefer not to respond", label: "Prefer not to respond" },
  { value: "other", label: "Prefer to self describe" },
];

export const ETHINICITY_LIST = [
  { value: "Asian/Asian", label: "Asian/Asian" },
  { value: "Black/African American", label: "Black/African American" },
  { value: "Hispanic/LatinX", label: "Hispanic/LatinX" },
  { value: "Native American/Indigenous", label: "Native American/Indigenous" },
  { value: "Native Hawaiian/Pacific Islander", label: "Native Hawaiian/Pacific Islander" },
  { value: "White/Caucasian", label: "White/Caucasian" },
  { value: "Prefer not to respond", label: "Prefer not to respond" },
  { value: "Prefer to self describe", label: "Prefer to self describe" },
];

export const VETERAN_STATUS_LIST = [
  { value: "Not a Veteran", label: "Not a Veteran" },
  { value: "Active Duty", label: "Active Duty" },
  { value: "Veteran", label: "Veteran" },
  { value: "Protected Veteran", label: "Protected Veteran" },
  { value: "Prefer not to respond", label: "Prefer not to respond" },
];

export const DUMMY_ROLE_TYPES: Array<AvailableOptions> = [
  {
    value: "Remote",
    label: "Remote",
    isSelected: false,
  },
  {
    value: "Hybrid",
    label: "Hybrid",
    isSelected: false,
  },
  {
    value: "On-site",
    label: "On-site",
    isSelected: false,
  },
];

export const SPONSORSHIP_OPTIONS = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
  {
    label: "Yes - will require sponsorship now or in the future",
    value: "Yes - will require sponsorship now or in the future",
  },
];

export const INDUSTRY_OPTIONS = [
  { label: "Construction", value: "Construction", isSelected: false },
  { label: "Business Services", value: "Business Services", isSelected: false },
  { label: "Retail", value: "Retail", isSelected: false },
  { label: "Energy & Mining", value: "Energy & Mining", isSelected: false },
  { label: "Manufacturing", value: "Manufacturing", isSelected: false },
  { label: "Finance", value: "Finance", isSelected: false },
  { label: "Recreation & Travel", value: "Recreation & Travel", isSelected: false },
  { label: "Health Care", value: "Health Care", isSelected: false },
  { label: "Real Estate", value: "Real Estate", isSelected: false },
  { label: "Consumer Goods", value: "Consumer Goods", isSelected: false },
  { label: "Technology & IT Services", value: "Technology & IT Services", isSelected: false },
  { label: "Agriculture", value: "Agriculture", isSelected: false },
  { label: "Entertainment", value: "Entertainment", isSelected: false },
  { label: "Wellness & Fitness", value: "Wellness & Fitness", isSelected: false },
  { label: "Transportation & Logistics", value: "Transportation & Logistics", isSelected: false },
  { label: "Hospitality", value: "Hospitality", isSelected: false },
];

export const JOB_TITLE_OPTIONS = [
  {
    label: "Sales Representative",
    value: "Sales Representative",
    isSelected: false,
    data: {
      oNetTitle: "Sales Represntative",
      onetSocScore: "41-2031.00",
      shortTitle: "",
      function: "Sales",
      levelTwoAggregation: "Sales Represntative",
      id: 16584,
      levelOneAggregation: "Sales Represntative",
      title: "Sales Representative",
    }
  },
  {
    label: "Customer Service Representative",
    value: "Customer Service Representative",
    isSelected: false,
    data: {
      oNetTitle: "Customer Service Representative",
      onetSocScore: "43-4041.00",
      shortTitle: "",
      function: "Support",
      levelTwoAggregation: "Customer Service Representative",
      id: 17913,
      levelOneAggregation: "Customer Service Representative",
      title: "Customer Service Representative"
    }
  },
  {
    label: "Marketing Specialist",
    value: "Marketing Specialist",
    isSelected: false,
    data: {
      oNetTitle: "Market Research Analysts and Marketing Specialists",
      onetSocScore: "13-1161.00",
      shortTitle: "",
      function: "Marketing",
      levelTwoAggregation: "Marketing Specialist",
      id: 2705,
      levelOneAggregation: "Market Research Analysts and Marketing Specialist",
      title: "Marketing Specialist"
    }
  },
  {
    label: "Office Administrator",
    value: "Office Administrator",
    isSelected: false,
    data: {
      oNetTitle: "Executive Secretaries and Executive Administrative Assistants",
      onetSocScore: "43-6011.00",
      shortTitle: "",
      function: "Operations",
      levelTwoAggregation: "Office Administrator",
      id: 18979,
      levelOneAggregation: "Office Administrator",
      title: "Office Administrator"
    }
  },
  { label: "Other", value: "Other", isSelected: false },
]
