import { IAppointment, IRequisition } from "@vendition/vendition-frontend";
import { Client } from "../../../../shared/interfaces/Requisitions";

export interface ClientForCard extends Client {
  id: number;
  employmentLocation: string;
  employmentType: string;
  startDate: number;
  submissionId?: number;
  requisitionId?: number;
  appointmentsData?: IAppointment;
  interviewSchedulingMethod?: string;
  interviewSchedulingLink?: string;
  submissionStatus?: string;
  resumeReview?: string;
}

export const filterCompanies = (requisitions?: IRequisition[], submissionStatus?: Array<String>): ClientForCard[] | undefined => {
  if (!requisitions || !Array.isArray(requisitions)) {
    return undefined;
  }

  const interestedReq: ClientForCard[] = [];

  (requisitions as IRequisition[]).forEach((requisition) => {
    const submissions = requisition.submissions.filter((submission) => submissionStatus?.includes(submission.status));
    if (!submissions.length) return;

    submissions.forEach((submission) => {
      interestedReq.push({
        employmentLocation: requisition.location,
        employmentType: requisition.title,
        startDate: requisition.startDate,
        name: requisition.client.name,
        id: requisition.client.id,
        submissionId: submission.id,
        requisitionId: submission.requisitionId,
        interviewSchedulingMethod: requisition.interviewSchedulingMethod,
        interviewSchedulingLink: requisition.interviewSchedulingLink,
        submissionStatus: submission.status,
        resumeReview: requisition.resumeReview,
      });
    });
  });

  return interestedReq;
};

export const filterInterviewsReadyToSchedule = (requisitions?: IRequisition[]): ClientForCard[] => {
  if (!requisitions) return [];
  const companies = filterCompanies(requisitions, ["Resume Approved", "Candidate Interested"]) || [];

  const filteredCompanies = companies.filter(company => {
    if (!company.interviewSchedulingMethod) return false;

    if (company.submissionStatus === "Resume Approved") return true;
    if (company.submissionStatus === "Candidate Interested" && company.resumeReview === "Not Required") return true;

    return false;
  })

  return filteredCompanies;
}
