import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Amplify from "aws-amplify";
import { adminConfig, AmplifyConfig } from "../../config";

import MainNavigation from "../../shared/components/navigation/MainNavigation";
import Overlay from "../../shared/components/UIElements/Overlay";

// * Context Related Imports
import { AuthContext } from "../../users/components/context/auth-context";
import { OverlayContext } from "../../users/components/context/overlay-context";
import { useAuthentication } from "../../shared/hooks/auth-hook";

import AppRoutes from "./AppRoutes";
import "./CandidateApp.css";


// * React-Query imports
import { queryClient } from "../../react-query/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Loading from "./Loading";
import { ToastContainer } from "react-toastify";
import { getStoredAdmin } from "../Candidate/hooks/admin-storage";

function App() {
  const [wait, setWait] = useState(false);

  const { assessLoggedInState, ...others } = useAuthentication();

  Amplify.configure(getStoredAdmin() ? adminConfig : AmplifyConfig);

  useEffect(() => {
    assessLoggedInState(!!others.email);
  }, [assessLoggedInState]);

  return (
    <AuthContext.Provider value={{ isLoggedIn: !!others.token, assessLoggedInState, ...others }}>
      <QueryClientProvider client={queryClient}>
        <OverlayContext.Provider value={{ wait, setWait }}>
          <Router>
            <MainNavigation />
            <main className={!others.isOnboarding ? "main_logged_in" : "main_logged_out"}>
              <AppRoutes />
            </main>
            <Overlay />
          </Router>
          <Loading />
          <ToastContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </OverlayContext.Provider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}

export default App;
