import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { isProfileCompleted } from "../../profile/ProfileRedirection";

export const useRedirection = () => {
  const navigate = useNavigate();
  const { thorUser } = useContext(AuthContext);
  if (!thorUser?.email) return () => {};

  const { section, payload } = isProfileCompleted(thorUser!);
  return () => navigate(`/profile/${section}`, { state: payload });
};
