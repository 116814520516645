import "./BlogArticle.css";

interface IBlogArticleProps {
  link: string;
  image: string;
  title: string;
  textPreview: string;
}

const BlogArticle = ({ link, image, title, textPreview }: IBlogArticleProps): JSX.Element => {
  return (
    <a href={link} className="blog-article" target="_blank" rel="noreferrer">
      <div className="img-container" style={{ backgroundImage: `url(${image})` }} />
      <label>{title}</label>
      <p>{textPreview}</p>
    </a>
  );
};

export default BlogArticle;
