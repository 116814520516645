import Card from "../../../shared/components/UIElements/Card";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

import Button from "../../../shared/components/FormElements/Button";
import OptionSelect from "../../../shared/components/FormElements/OptionSelect";
import { useOptions } from "../../../shared/hooks/options-hooks";
import { selectInitialOptionsFromState } from "../location_info/selectedLocationsHelper";

import { AuthContext } from "../context/auth-context";
import { errorHandler } from "../../../shared/util/helpers";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";

import "../../components/TraitSelection";
import { INDUSTRY_OPTIONS } from "../../../shared/util/constants";

const IndustrySelection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, thorUser } = useContext(AuthContext);
  const { mutateAsync: updateCandidate } = useUpdateCandidate();
  // Redirection evaluation
  const { isEditing = false } = location.state ?? {};

  const initialIndustries = location.state?.preferredIndustry || thorUser?.preferredIndustry || [];

  const [optionState, optionHandler, selectedIndustries] = useOptions({
    options: isLoggedIn ? selectInitialOptionsFromState(INDUSTRY_OPTIONS, initialIndustries) : INDUSTRY_OPTIONS,
  });

  // Component State
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let requestObject = {
      preferredIndustry: selectedIndustries.map(({ label }) => label),
    };
    try {
      await updateCandidate(requestObject);
      navigate(isEditing ? "/profile/page" : "/profile/job-title");
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  return (
    <Card className={"signup-content__container main-trait__container"}>
      <ProfileHeader progress={55} backNavigation={"/profile/work-history"} />
      <h1>What industries do you want to work in?</h1>
      <form className="signup-form__container" onSubmit={submitHandler}>
        {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
        <div className="trait-container">
          <div className="form-container list-container">
            <ul className={"trait-list"}>
              {optionState.options.map((item) => (
                <OptionSelect
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  initialSelected={item.isSelected}
                  optionCallback={optionHandler}
                  isDisabled={false}
                />
              ))}
            </ul>
          </div>
          <div className="edit-form__button-container">
            {isEditing ? (
              <>
                <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                  Cancel
                </Button>
                <Button size="big" disabled={!selectedIndustries.length}>
                  Save
                </Button>
              </>
            ) : (
              <Button size={"big"} disabled={!selectedIndustries.length}>
                Next
              </Button>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default IndustrySelection;
