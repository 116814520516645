import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "@vendition/vendition-frontend";

import chevron from "../../../resources/graphics/arrow-back.svg";
import { ReactComponent as Plus } from "../../../resources/graphics/plus.svg";
import EducationSummary from "./EducationSummary";

import { AuthContext } from "../context/auth-context";
import { useResume } from "../../../shared/hooks/useResume";
import { BasicUserData } from "../../pages/ProfilePage";
import { errorHandler } from "../../../shared/util/helpers";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

import "./EducationHistory.css";

const EducationHistory = (): JSX.Element => {
  const navigate = useNavigate();
  const { parsedUser } = useResume();
  const { setThorUser, thorUser } = useContext(AuthContext);
  const { mutate: updateCandidate } = useUpdateCandidate();

  useEffect(() => {
    if (parsedUser?.education && !thorUser?.education) {
      setThorUser({ ...(thorUser as BasicUserData), education: parsedUser.education });
    }
  }, [parsedUser, setThorUser, thorUser]);

  const handleSaveAllEducation = async (): Promise<void> => {
    const education = [...(thorUser?.education || [])];
    try {
      if (education.length > 0) {
        const requestObject = {
          ...(!thorUser?.educationLevel && thorUser?.education?.length ? { educationLevel: "College" } : {}),
          education,
        };
        // Execute mutation
        updateCandidate(requestObject);
      }

      if (parsedUser?.employment?.length || thorUser!.employment?.length) {
        navigate("/profile/work-history");
      } else {
        navigate("/profile/employment");
      }

    } catch (error) {
      if (error instanceof Error) errorHandler({ error });
    }
  };

  return (
    <Card className={"signup-content__container"}>
      <div className="progress-bar">
        <div className="progress-bar__progress" style={{ width: "44%" }} />
      </div>
      <div className="signup-content__header-interaction">
        <div className="signup-header__chevron">
          <span onClick={() => navigate("/profile/level")}>
            <img src={chevron} alt="back" width="20" height="20" />
          </span>
        </div>

        <div
          className="profile__basic-info__category-icon-container clickable"
          onClick={() =>
            navigate("/profile/education", {
              state: { educationList: thorUser?.education || []},
            })
          }
        >
          <Plus width={11} />
        </div>
      </div>
      <div className="signup-form__container">
        <h1>Your Education History</h1>
        <EducationSummary education={thorUser?.education || []} />
        <Button type="button" outline onClick={handleSaveAllEducation}>
          Next
        </Button>
      </div>
    </Card>
  );
};

export default EducationHistory;
