import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { ProfileInterface } from ".";
import Button from "../../../shared/components/FormElements/Button";

import { RadioButton } from "@vendition/vendition-frontend";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import Card from "../../../shared/components/UIElements/Card";
import { IOptionSelection, useOptions } from "../../../shared/hooks/options-hooks";
import { DUMMY_ROLE_TYPES, SPONSORSHIP_OPTIONS } from "../../../shared/util/constants";
import { setInitialRoles } from "./selectedRoleType.helper";
import { AuthContext } from "../context/auth-context";
interface RoleTypeProps {
  onSelectRoleType: (data: ProfileInterface) => void;
  isEditing: boolean;
}

const RoleType = ({ onSelectRoleType, isEditing }: RoleTypeProps) => {
  const { thorUser } = useContext(AuthContext);
  const location = useLocation();
  const { preferredWorkplaceType, workAuthorized: _workAuthorized } = location.state || thorUser || {};
  const [workAuthorized, setWorkAuthorized] = useState(_workAuthorized);

  const [{ options: roles }, selectRoleHandler] = useOptions({
    options: preferredWorkplaceType ? setInitialRoles(DUMMY_ROLE_TYPES, preferredWorkplaceType) : DUMMY_ROLE_TYPES,
  });

  const propagateRole = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onSelectRoleType({
      preferredWorkplaceType: roles.filter((role) => role.isSelected).map((role) => role.value),
      workAuthorized,
    });
  };

  const selectedRoles = roles.filter((role) => role.isSelected);
  const isOnlyRemote = selectedRoles.length === 1 && selectedRoles[0].value === "Remote";

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader progress={44} backNavigation="/profile/work-history" />
      <div className="signup-form__container">
        <h1>What type(s) of roles are you open to?</h1>
        <p></p>
        <form className="form-container" onSubmit={propagateRole}>
          <label>Select as many as you'd like</label>
          {roles.map((role: IOptionSelection) => (
            <RadioButton
              name={`rd_${role.value}`}
              selected={{ id: role.isSelected ? role.value : "", value: role.label }}
              value={role.value}
              handleChange={({ id, value }) => selectRoleHandler(id, value, !role.isSelected)}
              text={role.label}
              size="big"
            />
          ))}
          <hr />
          <div className="work-form__select-container">
            <div className="form-control select">
              <label htmlFor="sponsorship">
                Are you legally authorized to work in the US or will you now or in the future require sponsorship?
              </label>
              <Select
                id="sponsorship"
                name={"sponsorship"}
                options={SPONSORSHIP_OPTIONS}
                onChange={({ value }: any) => setWorkAuthorized(value)}
                defaultValue={workAuthorized ? { value: workAuthorized, label: workAuthorized } : undefined}
                placeholder="Make your selection..."
                menuPlacement="top"
              />
            </div>
          </div>
          <div className="edit-form__button-container">
            <Button disabled={!roles.filter((role) => role.isSelected).length || !workAuthorized} size="big">
              {isOnlyRemote && isEditing ? "Save" : "Next"}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default RoleType;
