import { useContext } from "react";
import { useNavigate } from "react-router";

import "./NotFound.css";
import { AuthContext } from "./context/auth-context";
import Button from "../../shared/components/FormElements/Button";

const NotFound = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate("/");
  }

  return (
    <div className={`not-found__container ${isLoggedIn ? "loggedin" : "loggedout"}`}>
      <div className={`not-found__container__image-lowres ${isLoggedIn ? "loggedin" : "loggedout"}`} />

      <div className="not-found__container__form-container">
        <div className="not-found__container__content">
          <h1>Whoops, We Can’t Find That Page</h1>
          <h2>But we can help you find your dream job</h2>
          <form className="not-found__form">
            <Button type="button" onClick={() => navigate("/dashboard")} size="big">
              View your Dashboard
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
