export const AmplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
    cookieStorage: {
      domain: window.location.hostname,
      secure: process.env.NODE_ENV !== "development",
      sameSite: "lax",
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};

export const adminConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_ADMIN_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_ADMIN_CLIENT_ID,
    cookieStorage: {
      domain: window.location.hostname,
      secure: process.env.NODE_ENV !== "development",
      sameSite: "lax",
    },
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
};
