import { useContext } from "react";
import { AuthContext } from "../../../users/components/context/auth-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/constants";

export const useSaveVSAT = () => {
  const { getToken, email, setThorUser} = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_AWS_DEV_URL}/candidate/${email}/vsat`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      return response.json();
    },
    onSuccess: (data) => {
      queryClient.setQueriesData([queryKeys.candidate], data);
      setThorUser(data);
    }
  });

  return {
    ...mutation
  }
};