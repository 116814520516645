import styled from "styled-components";

export const AddButton = styled.button`
  width: 22px;
  height: 24px;
  background-color: #0a9aff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 1rem 1rem 1.5rem;
  box-sizing: content-box;
  align-self: end;
`;

export const FormContainer = styled.div`
  display: flex;
`;