import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "../../../shared/components/FormElements/Button";

import circleCheck from "../../../resources/graphics/circle-check.svg";

const Container = styled.div`
  width: 100%;
  max-width: 100vw;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Card = styled.div`
  width: 600px;
  margin: 1rem auto;
  padding: 2.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
  }

  h1 {
    margin: 0 0 0.625rem;
    font-size: 2.75rem;
    line-height: 3.75rem;
    text-transform: capitalize;
  }

  p {
    font-size: 0.85rem;
    margin: 0 0 1.85rem;
  }

  @media (max-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem;
    margin: 0;

    h1 {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }
`;

const ThankYou = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navToDashboard = (e: React.MouseEvent): void => {
    e.stopPropagation();
    navigate("/dashboard");
  };

  return (
    <Container>
      <Card>
        <img src={circleCheck} alt="Job applied" />
        <h1>Thanks for taking the VSAT</h1>
        <p>We'll send you an email with your results and use this data to match you to jobs.</p>
        <Button onClick={navToDashboard} centred>View Dashboard</Button>
      </Card>
    </Container>
  );
};

export default ThankYou;
