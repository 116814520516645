import { createContext, Dispatch, SetStateAction } from "react";

export interface IOverlayContext {
  wait: boolean;
  setWait: Dispatch<SetStateAction<boolean>>;
}

export const OverlayContext = createContext<IOverlayContext>({
  wait: false,
  setWait: () => {},
});
