import { useContext, useState } from "react";
import axios from "axios";

import { AuthContext } from "../../../users/components/context/auth-context";
import { baseUrl } from "../../../axiosInstance/constants";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/constants";

const fetchApprenticeship = async (email, getToken) => {
  const token = await getToken();
  const response = await axios.get(`${baseUrl}/candidate/${email}/apprenticeships`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response?.status !== 200) {
    return undefined;
  }

  return response.data[0];
}

const patchApprenticeship = async (data, getToken) => {
  const token = await getToken();
  const { id, ...body } = data;


  const response = await axios.patch(`${baseUrl}/apprenticeship/${id}`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response?.status !== 200) {
    return undefined;
  }

  return response.data;
}

export const useApprenticeship = () => {
  const queryClient = useQueryClient();
  const { email, getToken } = useContext(AuthContext);

  const { data: apprenticeship } = useQuery([queryKeys.apprenticeship], () => fetchApprenticeship(email, getToken), {
    enabled: !!email,
  });

  const { mutate: updateApprenticeship } = useMutation((data) => patchApprenticeship(data, getToken), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.apprenticeship]);
    },
  });

  return { apprenticeship, updateApprenticeship }
};