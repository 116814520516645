import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
`;

export const List = styled.ul`
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  top: 70px;
  max-height: 500px;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;
  word-break: break-word;
  &:hover {
    color: white;
    font-weight: bold;
    background-color: #0a9aff;
  }
`;