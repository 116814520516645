import "./Card.css";

interface ICardProps {
  className?: string;
  style?: any;
  children: React.ReactNode;
}

const Card = (props: ICardProps): JSX.Element => {
  return (
    <div className={`card ${props.className}`} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card;
