import { useEffect, useReducer } from "react";
import plus from "../../../resources/graphics/plus-black.svg";
import close from "../../../resources/graphics/close.svg";
import "./OptionSelect.css";

export interface ITrait {
  value: string;
  label: string;
}

interface IOptionSelectProps extends ITrait {
  isDisabled?: boolean;
  initialSelected: boolean;
  optionCallback: (value: string, label: string, isSelected: boolean, data?: any) => void;
}

interface IOptionAction {
  type: string;
  val: string;
  isSelected: boolean;
}

interface IOptionState {
  value: string;
  isSelected: boolean;
}

const optionReducer = (state: IOptionState, action: IOptionAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isSelected: action.isSelected,
      };
    default:
      return state;
  }
};

const OptionSelect = ({ value, label, initialSelected, isDisabled, optionCallback }: IOptionSelectProps): JSX.Element => {
  const [optionState, dispatch] = useReducer(optionReducer, {
    value: value || "",
    isSelected: initialSelected || false,
  });

  const optionClickHandler = () => {
    dispatch({ type: "CHANGE", val: value, isSelected: !optionState.isSelected });
  };

  const { isSelected } = optionState;

  useEffect(() => {
    optionCallback(value, label, isSelected);
  }, [optionCallback, value, label, isSelected]);

  return (
    <li className={`trait-item ${optionState.isSelected && "trait-item__selected"}`} onClick={optionClickHandler}>
      <button
        className="clickable"
        type="button"
        disabled={isDisabled}
        style={{ color: optionState.isSelected ? "white" : "black" }}
      >
        {!optionState.isSelected && <img src={plus} alt="preview" width="8" style={{ marginRight: "0.4rem" }} />}
        {label}
        {optionState.isSelected && <img src={close} alt="preview" width="8" style={{ marginLeft: "0.4rem" }} />}
      </button>
    </li>
  );
};

export default OptionSelect;
