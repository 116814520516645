import React, { useEffect, useReducer } from "react";
import { IValidator, validate } from "../../util/validators";
import { ReactComponent as Invalid } from "../../../resources/graphics/close-red.svg";
import { ReactComponent as Checked } from "../../../resources/graphics/checked.svg";
import "./Input.css";
import { snakeCaseToCamelCase } from "../../util/helpers";

const VALIDATOR_TYPE_REQUIRE = "REQUIRE";
type TInput = "input" | "textarea";

export interface IInput {
  id: string;
  label?: string;
  element?: TInput;
  validators: Array<IValidator>;
  inputCallback: (id: string, value: string, isValid: boolean) => void;
  type?: string;
  placeholder?: string;
  rows?: number;
  errorText?: string;
  initialValue?: string;
  initialIsValid?: boolean;
  disabled?: boolean;
  verbose?: boolean;
  noLabel?: boolean;
  autocomplete?: string;
  description?: string;
  onChange?: (e: string) => void;
  onOptionSelect?: (e: string) => void;
}

interface IReducerAction {
  type: string;
  val: string;
  validators: Array<IValidator>;
}

interface IInputState {
  value: string;
  isValid: boolean;
  isTouched: boolean;
  validators: IValidator[];
}

const inputReducer = (state: IInputState, action: IReducerAction) => {
  switch (action.type) {
    case "CHANGE":
      const validation = validate(action.val, action.validators);
      return {
        ...state,
        value: action.val,
        isValid: action.validators.length > 0 ? validation.isValid : true,
        validators: validation.validators,
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const Input = ({
  initialValue,
  validators,
  initialIsValid,
  id,
  inputCallback,
  label,
  element = "input",
  type,
  placeholder,
  disabled,
  verbose,
  rows,
  errorText,
  noLabel,
  autocomplete,
  description,
  onChange
}: IInput) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || "",
    isValid: initialIsValid || false,
    isTouched: false,
    validators: [],
  });

  const changeHandler = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    onChange && onChange(value);
    dispatch({
      type: "CHANGE",
      val: event.currentTarget.value,
      validators: validators,
    });
  };

  const touchHandler = () => {
    dispatch({
      type: "TOUCH",
      val: "",
      validators: validators,
    });
  };

  const { value, isValid } = inputState;

  useEffect(() => {
    inputCallback(id, value, isValid);
  }, [id, inputCallback, value, isValid]);

  useEffect(() => {
    if (initialValue) {
      dispatch({
        type: "CHANGE",
        val: initialValue,
        validators: validators,
      });
      inputCallback(id, initialValue, initialIsValid || false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, id, inputCallback, initialIsValid]);

  return (
    <div className={`form-control ${!inputState.isValid && inputState.isTouched && "form-control--invalid"}`}>
      {!noLabel && <label htmlFor={id}>
        {label} {validators.find(v => v.type === VALIDATOR_TYPE_REQUIRE) && <span>*</span>}{" "}
      </label>}
      {
        description && <p className="description">{description}</p>
      }
      {element === "input" ? (
        <React.Fragment>
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            onChange={changeHandler}
            value={inputState.value}
            onBlur={touchHandler}
            disabled={disabled}
            autoComplete={autocomplete}
            name={snakeCaseToCamelCase(id)}
          />
          {verbose && (
            <div className="form-control__validation-log">
              {inputState.validators.map((validator, index) => (
                <div key={`validator_${index}`} className="form-control__validation-log__container">
                  {inputState.validators[index].isValid ? (
                    <Checked width={15} style={{ marginRight: "0.5rem" }} />
                  ) : (
                    <Invalid width={15} style={{ marginRight: "0.5rem" }} />
                  )}
                  <span key={`validator_${index}`} className={`${inputState.validators[index].isValid ? "valid" : ""}`}>
                    {validator.message}
                  </span>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ) : (
        <textarea
          id={id}
          rows={rows}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={inputState.value}
          disabled={disabled}
        />
      )}
      {!inputState.isValid && inputState.isTouched && !verbose && (
        <p>{errorText || "There are invalid information in the current form"}</p>
      )}
    </div>
  );
};

export default Input;
