import CompletedActions from "./CompletedActions";
import { useApprenticeship } from "./useApprenticeship";
import styled from "styled-components";

import CompanyIcon from "../../../resources/graphics/company.svg";
import CalendarIcon from "../../../resources/graphics/calendar.svg";

const Wrapper = styled.div`
  width: 100%;

  & h4 {
    margin: 0;
  }
`;

const Header = styled.h1`
  font-family: "Roboto", sans-serif;
  text-align: center;
`;
const Content = styled.div`
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
  margin: 2rem auto;
  max-width: 60rem;

  h3 {
    font-family: "Roboto", sans-serif;
    margin: 0;
    font-size: 1.2rem;
  }
`;

const TextFieldWrapper = styled.div`
  margin: 1rem 0;

  & input {
    display: block;
    width: 100%;
    font: inherit;
    border: 1px solid #efefef;
    background-color: #f8f8f8;
    padding: 0.15rem 0.25rem;
  }

  & div {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    & img {
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

const toDateString = (date) => {
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date));
}

const TextField = ({ label, value, icon }) => {
  return (
    <TextFieldWrapper>
      <div>
        <img src={icon} alt={label} />
        <h4>{label}</h4>
      </div>
      <input type="text" value={value} readOnly />
    </TextFieldWrapper>
  );
}

const Apprenticeship = () => {
  const { apprenticeship, updateApprenticeship } = useApprenticeship();

  if (!apprenticeship) {
    return (
      <Wrapper>
        <Header>Apprenticeship</Header>
        <div className="dashboard-container__content">
          <Content>
            <h3>No apprenticeship found</h3>
            <p>
              It looks like you don't have an apprenticeship yet.
            </p>
          </Content>
        </div>
      </Wrapper>
    );
  }

  const update = (data) => {
    updateApprenticeship({ id: apprenticeship.id, ...data });
  }

  return (
    <Wrapper>
      <Header>Apprenticeship</Header>
      <div className="dashboard-container__content">
        <Content key={apprenticeship.id}>
          <TextField label="Company" value={apprenticeship.client.name} icon={CompanyIcon} />
          <TextField label="Start Date" value={toDateString(apprenticeship.startDate)} icon={CalendarIcon} />
          <TextField label="End Date" value={toDateString(apprenticeship.endDate)} icon={CalendarIcon} />
          <CompletedActions
            startDate={apprenticeship.startDate}
            completedActions={apprenticeship.completedActions}
            updateApprenticeship={update}
          />
        </Content>
      </div>
    </Wrapper>
  );
};

export default Apprenticeship;