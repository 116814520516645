import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";

import circleCheck from "../../../resources/graphics/circle-check.svg";

import "./applied.css";

const AppliedPage = () => {
  const {
    state: { company },
  } = useLocation();
  const navigate = useNavigate();

  const navToDashboard = (e: React.MouseEvent): void => {
    e.stopPropagation();
    navigate("/dashboard");
  };

  return (
    <div className="applied-page__container">
      <Card>
        <img src={circleCheck} alt="Job applied" />
        <h1>{company.name} will review your profile</h1>
        <p>We’ll let you know if they want to schedule an interview.</p>
        <Button onClick={navToDashboard} centred>View Dashboard</Button>
      </Card>
    </div>
  );
};

export default AppliedPage;
