import { FetchCompanyProps } from "./Company.types";
import { ICompany } from "@vendition/vendition-frontend";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../react-query/constants";
import axios from "axios";
import { baseUrl } from "../../../axiosInstance/constants";
import { useContext } from "react";
import { AuthContext } from "../../../users/components/context/auth-context";

const fetchCompanyData = async ({ companyId, getToken }: FetchCompanyProps): Promise<ICompany | undefined> => {
  const token = await getToken();
  const response = await axios.get(`${baseUrl}/client/${companyId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  if (response?.status !== 200) {
    return undefined;
  }

  let tempData = { ...response.data };
  if (response.data.benefits && !Array.isArray(response.data.benefits) && typeof response.data.benefits === "string") {
    tempData.benefits = response.data.benefits.split(",").map((b: string) => b.trim());
  }

  if (!response.data.benefits) {
    tempData.benefits = {};
  }

  return tempData;
};

export const useCompany = (companyId: number): ICompany | undefined => {
  const { email, getToken } = useContext(AuthContext);

  const { data: company } = useQuery([queryKeys.client, companyId], () => fetchCompanyData({ companyId, getToken }), {
    enabled: !!(companyId && email),
  });

  return company;
};

export const usePrefetchCompany = (companyIds: Array<number>): void => {
  const { getToken } = useContext(AuthContext);

  const queryClient = useQueryClient();
  companyIds.forEach((companyId) =>
    queryClient.prefetchQuery([queryKeys.client, companyId], () => fetchCompanyData({ companyId, getToken }))
  );
};
