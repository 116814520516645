import React, { FunctionComponent } from "react";
import CompanyItem from "../../../users/components/dashboard/CompanyItem";
import { IInterview } from "./Interviews.types";

interface CompanySectionProps {
  title: string;
  list: Array<IInterview>;
}

const CompanySection: FunctionComponent<CompanySectionProps> = ({ title, list }) => {
  if (!list.length) return <React.Fragment />;

  return (
    <React.Fragment>
      <h1>{title}</h1>
      <div className="interview-container__company-container">
        {list.map((reqInterview) => (
          <CompanyItem
            key={reqInterview.id}
            validationStatus={reqInterview.status}
            requisition={reqInterview.requisition}
            company={reqInterview.company}
            submissionId={reqInterview.submissionId}
            submission={reqInterview.submission}
            appointmentStartDate={reqInterview.appointmentStartDate}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default CompanySection;
