import { BasicUserData } from "../../../users/pages/ProfilePage";
import { IEmploymentObject } from "../../../users/components/work/WorkExperienceUpdate";
import { IEducationObject } from "../../../users/components/education/EducationUpdate";
import { IParsedCandidate } from './interfaces'
import { IEducation } from "./interfaces/IEducation";
import { IWorkExperience } from "./interfaces/IWorkExperience";
import { removeEmpty } from "../../util/removeEmpty";

export const getEducationStatus = (education: IEducation): string => {
  if (education.dates?.completionDate) return "Graduated";
  if (education.dates?.isCurrent) return "In Progress";
  return "Incomplete";
};

export const mapEducationValues = (education: IEducation[]): IEducationObject[] => {
  if (!education) return [];

  return education.map((ed) => {
    return {
      id: ed.id,
      school: ed.organization ?? '',
      status: getEducationStatus(ed),
      startDate: ed.dates?.startDate ?? '' as unknown as number,
      endDate: ed.dates?.completionDate ?? '' as unknown as number,
      degree: ed.accreditation?.educationLevel ?? '',
      major: ed.accreditation?.education ?? '',
      minor: '',
      gpa: ed.grade?.value ?? '',
      comments: '',
    } as IEducationObject;
  });
};

export const mapWorkHistory = (workHistory: IWorkExperience[]): IEmploymentObject[] => {
  if (!workHistory) return [];

  return workHistory.map((work) => {
    return {
      id: work.id,
      companyName: work.organization ?? '',
      title: work.jobTitle,
      current: work.dates?.isCurrent ? "true" : "false",
      startDate: work.dates?.startDate ?? '' as unknown as number,
      endDate: work.dates?.endDate ?? '' as unknown as number,
      comments: work.jobDescription ?? '',
    } as IEmploymentObject;
  });
};

export const parseDataToTHOR = (parsedCandidate: IParsedCandidate): BasicUserData => {
  const user = {} as BasicUserData;
  user.firstName = parsedCandidate.name?.first ?? "";
  user.lastName = parsedCandidate.name?.last ?? "";
  user.email = parsedCandidate.emails?.[0] ?? "";
  user.phone = parsedCandidate.phoneNumbers?.[0] ?? "";
  user.education = mapEducationValues(parsedCandidate.education);
  user.employment = mapWorkHistory(parsedCandidate.workExperience);

  return removeEmpty(user);
};
