import { useCallback, useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { errorHandler } from "../../util/helpers";
import { BasicUserData } from "../../../users/pages/ProfilePage";
import { AuthContext } from "../../../users/components/context/auth-context";
import { IToastNotificationProps } from "../../components/FormElements/ToastNotification";

import { IEmploymentObject } from "../../../users/components/work/WorkExperienceUpdate";
import { IEducationObject } from "../../../users/components/education/EducationUpdate";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import { IResumeResponse } from './interfaces'
import { parseDataToTHOR } from "./helper";
import validatedCandidate from "../../../components/Candidate/hooks/candidateValidation";

type DeletableProps = "education" | "employment";

export const useResume = (email?: string | null, onUpload?: () => void) => {
  const [error, setCustomError] = useState<IToastNotificationProps | null>(null);
  const [parsedUser, setParsedUser] = useState<BasicUserData | undefined>(undefined);
  const [fileLocation, setFileLocation] = useState<string>("");
  const [type, setType] = useState<string>("pdf");
  const [file, setResume] = useState<string | null>();
  const { getToken } = useContext(AuthContext);

  const handleSuccess = async (data: IResumeResponse) => {
    const tData = data as IResumeResponse;
    const thorData = await parseDataToTHOR(tData.parsedCandidate.data)
    storeParsedFile(thorData, tData.fileLocation.Location);
    setCustomError(null);
  }

  const { mutate: sendFile, isLoading } = useMutation(
    async (data: string): Promise<any> => {
      const token = await getToken();

      const response = await axios.post(
        `${process.env.REACT_APP_AWS_DEV_URL}/resume?type=${type}&email=${email || ""}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    },
    {
      onSuccess: async ({ data }) => handleSuccess(data),
      onError: (error) => {
        if (error instanceof Error) {
          errorHandler({ error });
          setCustomError({
            type: "Error",
            title: "",
            message: error.message,
          });
        }
      }
    }
  );

  const { mutateAsync: patchToThor } = useUpdateCandidate();

  const storeParsedFile = useCallback(
    async (profile: BasicUserData, location: string) => {
      try {
        const requestObj = {
          resume: JSON.stringify(profile),
          resumeURL: `${location}`,
        };
        await patchToThor(requestObj);

        localStorage.setItem("resume", JSON.stringify(profile));
        localStorage.setItem("resume_location", location);
        setParsedUser(profile);
        setFileLocation(location);

        if (onUpload) onUpload();
      } catch (error) {
        if (error instanceof Error) {
          errorHandler({ error });
          setCustomError({
            type: "Error",
            title: "",
            message: error.message,
          });
        }
      }
    },
    [patchToThor]
  );

  const getStoredData = useCallback(() => {
    setParsedUser(JSON.parse(localStorage.getItem("resume") || "{}") || {});
    setFileLocation(localStorage.getItem("resume_location") || "");
  }, []);

  const clearStoredData = useCallback(() => {
    localStorage.removeItem("resume");
    localStorage.removeItem("resume_location");
    setParsedUser(undefined);
    setFileLocation("");
  }, []);

  const deleteStoredProperty = useCallback(
    (property: DeletableProps): boolean => {
      if (!parsedUser) return false;

      try {
        const newUser = { ...parsedUser };
        const key = property as keyof BasicUserData;
        delete newUser[key];
        localStorage.setItem("resume", JSON.stringify(newUser));
        setParsedUser(newUser);
        return true;
      } catch (error) {
        if (error instanceof Error) {
          errorHandler({ error, customMessage: "Error deleting parsed property" });
        }
        return false;
      }
    },
    [parsedUser]
  );

  const removeIdFromArray = (id: string | number, from: DeletableProps) => {
    let afterRemove;
    let replacedEducation: IEducationObject[] = [],
      replacedWork: IEmploymentObject[] = [];
    if (from === "education" && parsedUser && parsedUser.education)
      replacedEducation = parsedUser.education.filter((edRecord) => edRecord.id !== id);
    if (from === "employment" && parsedUser && parsedUser.employment)
      replacedWork = parsedUser.employment.filter((workRecord) => workRecord.id !== id);
    if (parsedUser) {
      afterRemove = {
        ...parsedUser,
        education: replacedEducation,
        employment: replacedWork,
      };

      storeParsedFile(afterRemove, fileLocation);
    }
    return afterRemove;
  };

  const uploadFile = useCallback(async (): Promise<void> => {
    if (file) {
      if (!file.includes("base64"))
        throw new Error("The file is not formatted correctly, please make sure is `base64` encoded.");

      sendFile(file.split(",")[1]);
    }
  }, [file, sendFile]);

  useEffect(() => {
    if (file && file !== "") uploadFile();
  }, [uploadFile, file]);

  useEffect(() => {
    getStoredData();
  }, [getStoredData]);

  return {
    loading: isLoading,
    error,
    setType,
    setResume,
    parsedUser: validatedCandidate(parsedUser),
    fileLocation,
    clearStoredData,
    removeIdFromArray,
    deleteStoredProperty,
  };
};
