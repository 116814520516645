import ReactDOM from "react-dom";
import "./Backdrop.css";

interface IBackdropProps {
  onClick: () => void;
}

const Backdrop = (props: IBackdropProps): JSX.Element => {
  return ReactDOM.createPortal(
    <div className="backdrop" onClick={props.onClick}></div>,
    document.getElementById("backdrop-hook") as HTMLElement
  );
};

export default Backdrop;
