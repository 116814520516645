import Card from "../../shared/components/UIElements/Card";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useUpdateCandidate } from "../../components/Candidate/hooks/useUpdateCandidate";

import Button from "../../shared/components/FormElements/Button";
import OptionSelect from "../../shared/components/FormElements/OptionSelect";
import { IOptionSelection, useOptions } from "../../shared/hooks/options-hooks";

import { AuthContext } from "./context/auth-context";
import { errorHandler } from "../../shared/util/helpers";
import ProfileHeader from "../../shared/components/navigation/ProfileHeader";
import { selectInitialOptionsFromState } from "./location_info/selectedLocationsHelper";
import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";

import "./TraitSelection.css";

const DUMMY_TRAITS: Array<IOptionSelection> = [
  { value: "1", label: "Strong work ethic", isSelected: false },
  { value: "2", label: "Sales experience", isSelected: false },
  { value: "3", label: "Strong education background", isSelected: false },
  { value: "4", label: "Eager to learn", isSelected: false },
  { value: "5", label: "Strong work history", isSelected: false },
  { value: "6", label: "Excellent communication skills", isSelected: false },
];

const TraitSelection = () => {
  const limit = 3;
  // Hooks Usage
  const location = useLocation();
  const navigate = useNavigate();
  const { email, isLoggedIn, thorUser } = useContext(AuthContext);
  const { mutate: updateCandidate } = useUpdateCandidate();
  // Redirection evaluation
  const { isEditing = false } = location.state ?? {};

  const initialTraits = location.state?.traits || thorUser?.traits || [];

  const [optionState, optionHandler, selectedTraits] = useOptions({
    options: isLoggedIn ? selectInitialOptionsFromState(DUMMY_TRAITS, initialTraits) : DUMMY_TRAITS,
  });

  // Component State
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let requestObject = {
      traits: selectedTraits.map(({ label }) => label),
    };
    try {
      updateCandidate(requestObject);
      navigate(isEditing ? "/profile/page" : "/profile/level");
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
        });
      }
    }
  };

  useEffect(() => {
    if (!email) navigate("/");
  }, [email, navigate]);

  return (
    <Card className={"signup-content__container main-trait__container"}>
      <ProfileHeader progress={55} backNavigation={"/profile/job-status"} />
      <h1>
        Pick 3 traits you most <br /> identify with
      </h1>
      <form className="signup-form__container" onSubmit={submitHandler}>
        {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
        <div className="trait-container">
          <div className="form-container list-container">
            <ul className={"trait-list"}>
              {optionState.options.map((trait) => (
                <OptionSelect
                  key={trait.value}
                  value={trait.value}
                  label={trait.label}
                  initialSelected={trait.isSelected}
                  optionCallback={optionHandler}
                  isDisabled={!(selectedTraits.length < limit) && !trait.isSelected}
                />
              ))}
            </ul>
          </div>
          <div className="edit-form__button-container">
            {isEditing ? (
              <>
                <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                  Cancel
                </Button>
                <Button size="big" disabled={selectedTraits.length < limit}>
                  Save
                </Button>
              </>
            ) : (
              <Button size={"big"} disabled={selectedTraits.length < limit}>
                Next
              </Button>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default TraitSelection;
