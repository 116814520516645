import ApplicationStart from "../components/ApplicationStart";
import CodeConfirmation from "../components/CodeConfirmation";
import Curriculum from "../components/Curriculum";
import { Route, Routes } from "react-router-dom";
import NotFound from "../components/NotFound";
import "./SignUp.css";

const Signup = (): JSX.Element => {
  return (
    <div className="signup-flow__container">
      <Routes>
        <Route path="/" element={<ApplicationStart />} />
        <Route path="confirmation" element={<CodeConfirmation />} />
        <Route path="resume" element={<Curriculum />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <p className="form-container__subtle-text">
        Need help? Email us at <a href="mailto:support@vendition.com">support@vendition.com</a>
      </p>
    </div>
  );
};

export default Signup;
