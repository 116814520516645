import React, { useState } from 'react';
import styled from 'styled-components';
import { quiz } from './quiz';
import { Button } from '@vendition/vendition-frontend';
import { useSaveVSAT } from './useSaveVSAT';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Container = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
`;

const Table = styled.table`
  max-width: 600px;
  margin: 20px auto;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  padding: 10px;
  
  & th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
`;

const QuizElement = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  overflow: hidden;

  & > p {
    margin: 0;
    padding: 10px;
    background-color: #0a9aff;
    color: #fff;
    font-weight: bold;
  }

  & > div {
    display: flex;
    justify-content: space-between;

    & > input {
      display: none;
    }
  }
`;


const QuizElementOption = styled.label`
  flex: 1;
  display: block;
  padding: 10px;
  background-color: #fff;
  color: #0a9aff;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    background-color: lightgray;
    color: #fff;
  }

  ${props => props.active && `
    background-color: #b7b7b7;
    color: #fff;
  `};
`


const VSATQuiz = () => {
  const [quizState, setQuizState] = useState({});
  const navigate = useNavigate();

  const { mutateAsync } = useSaveVSAT();


  const setQuizValue = (item, value) => {
    setQuizState({
      ...quizState,
      [item]: value
    });
  };

  const onSubmit = async () => {
    try {
      await mutateAsync(quizState);
      navigate('/vsat/thank-you');
    } catch {
      toast.error('Something went wrong', { position: toast.POSITION.BOTTOM_CENTER });
    }
  };

  return (
    <Container>
      <div>
        <h1>Vendition Success Aptitude Test</h1>
        <h2>Quiz Instructions</h2>
        <p>Welcome to the VSAT!   There are no right or wrong answers here and higher score do not necessarily mean better results so <strong>please complete the entire assessment and answer honestly.</strong></p>

        <p>In this assessment you will see a list of activities or things you could do. <strong>For each one, please select a response to indicate how well you can do that thing.</strong></p>

        <Table>
          <thead>
            <tr>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Not Well At All</td>
              <td>Not Very Well</td>
              <td>Pretty Well</td>
              <td>Very Well</td>
              <td>Extremely Well</td>
            </tr>
          </tbody>
        </Table>
        <p>This assessment should take <strong>less than 5 minutes</strong> and after you take it we'll email you the results as well as use this data to help match you to job opportunities.  If you don't get an email 30 minutes after you submit the assessment please
          email <a href='mailto:support@vendition.com'>support@vendition.com</a>.</p>
      </div>

      <div>
        {
          quiz.map((item, index) => {
            return (
              <QuizElement key={index}>
                <p>{item}</p>
                <div>
                  {
                    [1, 2, 3, 4, 5].map((value, index) => {
                      return (
                        <React.Fragment key={index}>
                          <QuizElementOption
                            htmlFor={"quiz_" + item}
                            active={quizState[item] === value}
                            onClick={() => setQuizValue(item, value)}
                          >{value}</QuizElementOption>
                          <input type="radio" id={"" + item} name={"quiz_" + item} value={item} defaultChecked={quizState[item] === item} />
                        </React.Fragment>
                      )
                    })
                  }
                </div>
              </QuizElement>
            )
          })
        }
      </div>

      <div style={{ textAlign: "center", marginTop: 60 }}>
        <Button type="button" onClick={onSubmit} disabled={
          Object.keys(quizState).length !== quiz.length
        }>Submit</Button>
      </div>
    </Container>
  );
}

export default VSATQuiz;