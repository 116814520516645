import { CompanyPage, ICompany } from "@vendition/vendition-frontend";
import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "./useCompany";

const CompanyPageHolder = () => {

  const { companyId = 0 } = useParams();
  const navigate = useNavigate();

  const company = useCompany(+companyId);

  return <CompanyPage company={company ?? {} as ICompany} handleNavBack={() => navigate(-1)} />;
};

export default CompanyPageHolder;
