import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  VALIDATOR_CONTAINS_NUMBERS,
  VALIDATOR_CONTAINS_UPPERCASE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_SPECIAL_CHARACTERS,
} from "../../shared/util/validators";

import useAxios from "axios-hooks";
import { AuthContext } from "./context/auth-context";
import { useForm } from "../../shared/hooks/form-hook";

import Card from "../../shared/components/UIElements/Card";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import Checkbox from "../../shared/components/FormElements/Checkbox";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { errorHandler } from "../../shared/util/helpers";

// Code to test baseDirectory
const ApplicationStart = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsOnboarding, assessLoggedInState, isOnboarding, isLoggedIn } = useContext(AuthContext);
  const paramsFirstName = searchParams.get("firstName");
  const paramsLastName = searchParams.get("lastName");
  const paramsEmail = searchParams.get("email");

  const [{ loading }, executePut] = useAxios(
    {
      url: `${process.env.REACT_APP_AWS_DEV_URL}/candidate/`,
      method: "PUT",
    },
    { manual: true }
  );

  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const [formState, inputHandler] = useForm(
    {
      emailAddress: {
        value: "",
        isValid: false,
      },
      newPassword: {
        value: "",
        isValid: false,
      },
      firstName: {
        value: "",
        isValid: false,
      },
      lastName: {
        value: "",
        isValid: false,
      },
      chkTerms: {
        value: false,
        isValid: false,
      },
    },
    false
  );

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const {
      emailAddress: { value: emailAddress },
      newPassword: { value: password },
      firstName: { value: firstName },
      lastName: { value: lastName },
    } = formState.inputs;
    

    try {
      const lowercaseEmail = (emailAddress as string).trim().toLowerCase();
      Sentry.setUser({ email: lowercaseEmail });
      await Auth.signUp({
        username: lowercaseEmail,
        password,
        attributes: { email: lowercaseEmail },
      });

      let urlParamUtm: Record<string, any> = {};
      let candidateData: Record<string, any> = {
        email: lowercaseEmail,
        firstName: firstName,
        lastName: lastName,
      };

      if (searchParams.get("utm_term") !== null) urlParamUtm["utm_term"] = searchParams.get("utm_term");
      if (searchParams.get("utm_source") !== null) urlParamUtm["utm_source"] = searchParams.get("utm_source");
      if (searchParams.get("utm_medium") !== null) urlParamUtm["utm_medium"] = searchParams.get("utm_medium");
      if (searchParams.get("utm_content") !== null) urlParamUtm["utm_content"] = searchParams.get("utm_content");
      if (searchParams.get("utm_campaign") !== null) urlParamUtm["utm_campaign"] = searchParams.get("utm_campaign");

      if (Object.keys(urlParamUtm).length) candidateData["urlParamUtm"] = urlParamUtm;
      // Get vendition_utm Cookie
      const venditionUTM = Cookies.get("vendition_utm");
      if (venditionUTM) {
        try {
          candidateData["cookieUtm"] = JSON.parse(decodeURIComponent(venditionUTM));
        } catch (error) {
          if (error instanceof Error) errorHandler({ error });
        }
      }

      candidateData.timeLastLogin = new Date().getTime();

      const { status } = await executePut({ data: candidateData });

      if (status >= 300) throw new Error("Could not fulfill our request");
      else if (status === 200) {
        await assessLoggedInState(true);
      }

      navigate(`/signup/confirmation`, {
        state: {
          firstName: firstName,
          lastName: lastName,
          email: lowercaseEmail,
          password: password,
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error }).message,
        });
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
    else setIsOnboarding(true);
  }, [setIsOnboarding, isOnboarding, isLoggedIn, navigate]);

  if (loading)
    return (
      <div className="loading-backdrop">
        <LoadingSpinner />
      </div>
    );

  return (
    <Card className="signup-content__container">
      <div className="signup-form__container">
        <h1>
          Create Your <br /> Profile Now
        </h1>
        <h3>We'll match your completed profile to our hiring partners.</h3>
        <h3>No Fees. No Deposit. No Catch.</h3>
        {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
        <form className="form-container" onSubmit={submitHandler}>
          <Input
            type={"text"}
            id="firstName"
            element="input"
            label="First Name"
            placeholder="Jane"
            errorText="Please enter a name"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={paramsFirstName ?? ""}
            initialIsValid={!!paramsFirstName}
            inputCallback={inputHandler}
          />
          <Input
            type={"text"}
            id="lastName"
            element="input"
            label="Last Name"
            placeholder="Doe"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter your Last Name"
            initialValue={paramsLastName ?? ""}
            initialIsValid={!!paramsLastName}
            inputCallback={inputHandler}
          />
          <Input
            id="emailAddress"
            element="input"
            type={"text"}
            label="Email Address"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
            errorText="Enter a valid email"
            initialValue={paramsEmail ?? ""}
            initialIsValid={!!paramsEmail}
            inputCallback={inputHandler}
            placeholder="jane.doe@example.com"
          />
          <Input
            id="new-password"
            element="input"
            type={"password"}
            label="Password"
            validators={[
              VALIDATOR_REQUIRE(),
              VALIDATOR_MINLENGTH(8),
              VALIDATOR_CONTAINS_UPPERCASE(),
              VALIDATOR_SPECIAL_CHARACTERS(),
              VALIDATOR_CONTAINS_NUMBERS(),
            ]}
            errorText="Enter a valid password (minimum of 8 characters)"
            inputCallback={inputHandler}
            placeholder="Create a password"
            verbose={true}
            autocomplete="new-password"
          />
          <Checkbox id={"chkTerms"} inputCallback={inputHandler} validators={[VALIDATOR_REQUIRE()]}>
            <div className="terms-container">
              <div>
                <span>By signing up you agree to Vendition’s </span>{" "}
              </div>
              <div>
                <a href={"https://vendition.com/terms-and-conditions-of-use/ "} rel="noreferrer" target="_blank">
                  Terms of Service
                </a>{" "}
              </div>
              <div>
                <span>and</span>
              </div>
              <div>
                <a href={"https://vendition.com/privacy-policy/"} rel="noreferrer" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div>
          </Checkbox>
          <Button disabled={!formState.isValid} size={"whole"}>
            Let's Go!
          </Button>
          <div className="sign-in" onClick={() => navigate("/login")}>
            <span>
              Already have an account? Sign In <b className="link">Here</b>
            </span>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default ApplicationStart;
