import { Route, Routes } from "react-router-dom";
import AboutYourself from "../AboutYourself";
import NotFound from "../NotFound";
import VideoUpdate from "../profile/VideoUpdate";
import VideoAbout from "../VideoAbout";
import VideoQuestion from "../VideoQuestion";

const VideoRecordingRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<AboutYourself />} />
      <Route path="/edit" element={<VideoUpdate />} />
      <Route path="/recording" element={<VideoAbout />} />
      <Route path="/question" element={<VideoQuestion />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default VideoRecordingRouter;
