import React, { useCallback, useContext, useState } from 'react';
import Card from '../../../shared/components/UIElements/Card';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ToastNotification, {
  IToastNotificationProps,
} from '../../../shared/components/FormElements/ToastNotification';
import Input from '../../../shared/components/FormElements/Input';
import { VALIDATOR_REQUIRE } from '../../../shared/util/validators';
import { useForm } from '../../../shared/hooks/form-hook';
import moment from 'moment';
import Checkbox from '../../../shared/components/FormElements/Checkbox';
import Select, { ActionMeta, SingleValue } from 'react-select';
import Button from '../../../shared/components/FormElements/Button';
import { getDateSelectObject } from '../education/EducationUpdate';
import { AuthContext } from '../context/auth-context';
import {
  DUMMY_MONTHS,
  DUMMY_YEARS,
  MONTHS,
} from '../../../shared/util/constants';
import { BasicUserData } from '../../pages/ProfilePage';
import ProfileHeader from '../../../shared/components/navigation/ProfileHeader';
import { errorHandler } from '../../../shared/util/helpers';
import { useUpdateCandidate } from '../../../components/Candidate/hooks/useUpdateCandidate';
import './NewWorkExperience.css';
import TitleAutocomplete from '../../../shared/components/FormElements/TitleAutocomplete';

export interface IEmploymentObject {
  id?: number | string;
  companyName: string;
  title: string;
  current: string;
  startDate: number;
  endDate: number;
  comments: string;
  isValid?: boolean;
}

const WorkExperienceUpdate = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const { setThorUser, thorUser, isOnboarding } = useContext(AuthContext);
  const {
    isEditing = false,
    history = {},
    historyList = [],
  } = location.state || thorUser || {};
  const { mutate: updateCandidate } = useUpdateCandidate();
  const { companyName, title, startDate, endDate, comments, current } = history;
  const sDate = startDate ? moment(startDate) : moment();
  const eDate = endDate ? moment(endDate) : moment();
  const currentVal = current === "true";

  const [formState, inputHandler] = useForm(
    {
      companyName: { value: companyName || "", isValid: !!companyName },
      txtTitle: { value: title || "", isValid: true },
      chkCurrent: { value: currentVal, isValid: true },
      slMonthStart: {
        value: sDate.format("MMMM"),
        isValid: true,
      },
      slYearStart: {
        value: sDate.format("YYYY"),
        isValid: true, //* Since we made current year as default value there is no way this is not valid,
      },
      slMonthEnd: {
        value: eDate.format("MMMM"),
        isValid: currentVal || (!currentVal && !endDate) ? true : false,
      },
      slYearEnd: {
        value: eDate.format("YYYY"),
        isValid: true, //* Since we made current year as default value there is no way this is not valid
      },
      txtSummary: {
        value: comments || "",
        isValid: !!comments,
      },
    },
    false
  );

  const isValidDateRange = (): boolean =>
    (formState.inputs.chkCurrent.value
      ? moment()
      : moment({
        year: +formState.inputs.slYearEnd.value,
        month: MONTHS.indexOf(formState.inputs.slMonthEnd.value),
        day: 1,
      })
    ).isAfter(
      moment({
        year: +formState.inputs.slYearStart.value,
        month: MONTHS.indexOf(formState.inputs.slMonthStart.value),
        day: 1,
      })
    );

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValidDateRange()) {
      setToast({
        message: 'Please make sure the end date is after the start date',
        title: '',
        type: 'Error',
      });
      return;
    }

    let allWork: Array<any> = [];
    allWork = [...(historyList as IEmploymentObject[])];

    const {
      companyName,
      txtTitle,
      chkCurrent,
      slYearStart,
      slMonthStart,
      slYearEnd,
      slMonthEnd,
      txtSummary,
    } = formState.inputs;

    const newWorkRecord: IEmploymentObject = {
      companyName: companyName.value,
      title: txtTitle.value,
      current: '' + chkCurrent.value,
      startDate: +moment({
        year: +slYearStart.value,
        month: MONTHS.indexOf(slMonthStart.value),
        day: 1,
      }),
      endDate: chkCurrent.value
        ? NaN
        : +moment({
          year: +slYearEnd.value,
          month: MONTHS.indexOf(slMonthEnd.value),
          day: 1,
        }),
      comments: txtSummary.value,
    };

    if (params.workId !== undefined) {
      allWork[+params.workId] = newWorkRecord;
    } else {
      allWork.push(newWorkRecord);
    }

    if (isEditing) {
      const requestObject = {
        employmentExperience: 'true',
        employment: allWork,
      };

      try {
        updateCandidate(requestObject);
        navigate('/profile/page');
      } catch (error) {
        if (error instanceof Error) {
          setToast({
            type: 'Error',
            title: '',
            message: errorHandler({
              error,
              customMessage: 'Your information was not updated',
            }).message,
          });
        }
      }
    } else {
      setThorUser({ ...(thorUser as BasicUserData), employment: allWork });
      navigate('/profile/work-history');
    }
  };

  const handleCheckChange = useCallback(
    (id: string, value: string, isValid: boolean) => {
      const monthEnd = formState.inputs.slMonthEnd.value;
      const yearEnd = formState.inputs.slYearEnd.value;
      inputHandler('slMonthEnd', monthEnd, !!monthEnd || !!value);
      inputHandler('slYearEnd', yearEnd, !!yearEnd || !!value);
      inputHandler(id, value, isValid);
    },
    [
      formState.inputs.slMonthEnd.value,
      formState.inputs.slYearEnd.value,
      inputHandler,
    ]
  );

  const handleSelectChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    inputHandler(actionMeta.name || '', newValue?.label || '', true);
  };

  const handleCancel = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate('/profile/page');
  };

  const backNav = isOnboarding
    ? historyList.length
      ? '/profile/work-history'
      : '/profile/education/history'
    : '/profile';

  const newEntry = Object.keys(history).length <= 0;

  const skipScreen = async () => {
    const requestObject = {
      employmentExperience: 'false',
    };

    updateCandidate(requestObject);
    navigate('/profile/industry');
  };

  return (
    <Card className={'signup-content__container'}>
      <ProfileHeader progress={75} backNavigation={backNav} />
      <div className='signup-form__container'>
        <h1>{`${newEntry ? 'Edit' : 'Add'} Work Experience`}</h1>
        {newEntry && !isEditing && (
          <button
            className='btn-link clickable'
            type='button'
            onClick={() => skipScreen()}
          >
            Skip, I do not have work history yet
          </button>
        )}
        <form className={`form-container`} onSubmit={submitHandler}>
          {toast && (
            <ToastNotification
              title={toast.title}
              message={toast.message}
              type='Error'
            />
          )}
          <Input
            id='companyName'
            element='input'
            type={'text'}
            label='Company Name'
            validators={[VALIDATOR_REQUIRE()]}
            errorText='Please enter a company name'
            inputCallback={inputHandler}
            placeholder='Enter a company name'
            initialValue={companyName || ''}
            initialIsValid={true}
          />
          <TitleAutocomplete
            id="txtTitle"
            label="Title"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a title for your position"
            inputCallback={inputHandler}
            placeholder="Enter your title at this company"
            initialValue={title || ""}
          />
          <div className='chkContainer' style={{ margin: '0 auto' }}>
            <Checkbox
              id={'chkCurrent'}
              inputCallback={handleCheckChange}
              validators={[]}
              className='checkLeft'
              initialValue={currentVal || false}
            >
              <span>I currently work here</span>
            </Checkbox>
          </div>
          <div className='work-form__select-container'>
            <label htmlFor='slCities'>
              Start Date<span>*</span>
            </label>
            <div className='work-form__select-group'>
              <Select
                id='slMonthStart'
                name={'slMonthStart'}
                options={DUMMY_MONTHS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(sDate.format('MMMM'), DUMMY_MONTHS)}
              />
              <Select
                id='slYearStart'
                name={'slYearStart'}
                options={DUMMY_YEARS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(sDate.format('YYYY'), DUMMY_YEARS)}
              />
            </div>
          </div>
          <div className='work-form__select-container'>
            <label htmlFor='slCities'>
              End Date<span>*</span>
            </label>
            <div className='work-form__select-group'>
              <Select
                id='slMonthEnd'
                name='slMonthEnd'
                isDisabled={formState.inputs['chkCurrent'].value}
                onChange={handleSelectChange}
                options={DUMMY_MONTHS}
                defaultValue={getDateSelectObject(eDate.format('MMMM'), DUMMY_MONTHS)}
              />
              <Select
                id='slYearEnd'
                name='slYearEnd'
                isDisabled={formState.inputs['chkCurrent'].value}
                onChange={handleSelectChange}
                options={DUMMY_YEARS}
                defaultValue={getDateSelectObject(eDate.format('YYYY'), DUMMY_YEARS)}
              />
            </div>
          </div>
          <Input
            id='txtSummary'
            element='textarea'
            type={'text'}
            label='Summary of your position'
            validators={[VALIDATOR_REQUIRE()]}
            errorText='Please enter a title for your position'
            inputCallback={inputHandler}
            placeholder='Please enter a summary'
            rows={10}
            initialValue={comments || ''}
            initialIsValid={!!comments}
          />
          <div className='edit-form__button-container'>
            {isOnboarding ? (
              <Button size='whole' disabled={!formState.isValid}>
                Save & Continue
              </Button>
            ) : (
              <>
                <Button outline onClick={handleCancel}>
                  Cancel
                </Button>
                <Button disabled={!formState.isValid}>Save</Button>
              </>
            )}
          </div>
        </form>
      </div>
    </Card>
  );
};

export default WorkExperienceUpdate;
