import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, ICompany, OfferPage } from "@vendition/vendition-frontend";
import Notification from "../../../shared/components/UIElements/Notification";

import { errorHandler } from "../../../shared/util/helpers";
import { useUpdateSubmission } from "../../Submissions/useUpdateSubmission";
import { useCompany } from "../Company/useCompany";

const OfferPageHolder = (): JSX.Element => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const { reqId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { state: { company, requisition, submissionId } } = useLocation();
  const navigate = useNavigate();

  const changeStatus = useUpdateSubmission({ submissionId });
  const companyInfo = useCompany(+company?.id);
  const [submission] = requisition.submissions;

  const navToCompany = () => navigate(`../company/${company.id}`, { state: { company } });

  const navBack = (): void => navigate(-1);

  const acceptOffer = async (): Promise<void> => {
    changeStatus({ status: "Candidate Interested" });
    navigate("../applied", { state: { company: companyInfo ?? company } });
  };

  const handleCompanyDismiss = async (): Promise<void> => {
    try {
      await changeStatus({ status: "Candidate Not Interested" });

      setShowNotification(false);
      navigate("/dashboard");
    } catch (error) {
      if (error instanceof Error) {
        errorHandler({ error });
      }
    }
  };

  return (
    <React.Fragment>
      <OfferPage
        company={companyInfo ?? ({} as ICompany)}
        requisition={requisition}
        submission={reqId || ""}
        acceptOffer={acceptOffer}
        navigateNext={navToCompany}
        navigateBack={navBack}
        handleDismissConfirmation={() => setShowNotification(true)}
        isDemo={submission.status !== "Internally Submitted"}
      />
      <Notification
        show={showNotification}
        onCancel={() => setShowNotification(false)}
        header={""}
        footer={
          <div className="center">
            <Button outline onClick={() => setShowNotification(false)}>
              Cancel
            </Button>
            <Button size="big" onClick={handleCompanyDismiss}>
              Not Interested
            </Button>
          </div>
        }
        contentClass={"center"}
      >
        <div>
          <h1>Are you sure you're not interested?</h1>
          <h4>Please confirm if you are not interested in this role.</h4>
        </div>
      </Notification>
    </React.Fragment>
  );
};

export default OfferPageHolder;
