import close from "../../../resources/graphics/close.svg";
import plus from "../../../resources/graphics/plus.svg";
import userIcon from "../../../resources/images/account@3x.png";
import React from "react";
import { Container, ImgContainer, ImgMask, ActionBtn } from "./styles";

interface IAvatar {
  className?: string;
  isButton?: boolean;
  style?: any;
  image: string;
  alt: string;
  width: number;
  readonly?: boolean;
  onClickClear?: () => void;
}

const Avatar = (props: IAvatar): JSX.Element => {
  const isUserIcon = props.image === userIcon;
  const onClickClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.onClickClear) {
      props.onClickClear();
    }
  };

  return (<Container>
    <ImgContainer width={props.width}>
      <ImgMask {...{isUserIcon}}>
        <img src={props.image} alt={props.alt} />
      </ImgMask>
    {!props.readonly &&
      (props.isButton ? (
        <ActionBtn onClick={onClickClear}>
          <img src={close} alt="close" />
        </ActionBtn>
      ) : (
        <ActionBtn>
          <img src={plus} alt="plus" />
        </ActionBtn>
      ))}
    </ImgContainer>
  </Container>);
};

export default Avatar;
