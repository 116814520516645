import { useNavigate } from "react-router-dom";
import moment from "moment";

import pencil from "../../../resources/graphics/pencil.svg";
import { ReactComponent as Briefcase } from "../../../resources/graphics/briefcase_black.svg";
import { ReactComponent as WarningIcon } from "../../../resources/graphics/warning-icon.svg";

import "./WorkHistoryItem.css";

import { IEmploymentObject } from "./WorkExperienceUpdate";

interface IWorkHistoryItemProps {
  index: number;
  historyItem: IEmploymentObject;
  historyList?: Array<IEmploymentObject>;
  isEditing?: boolean;
  customNavigate?: () => void;
}

const WorkHistoryItem = ({ index, historyItem, historyList, customNavigate, isEditing }: IWorkHistoryItemProps) => {
  const navigate = useNavigate();
  const { companyName, title, startDate, endDate, current, isValid } = historyItem ?? {};
  const sDate = moment.utc(startDate);
  const eDate = moment.utc(endDate);
  const currentVal = !endDate || current === "true";

  return (
    <div className="history-item__container">
      <div className="history-item__icon">
        <Briefcase width={22} fill="black" stroke="black" strokeWidth={2} g2={"black"} />
      </div>
      <div className="history-item__info">
        <label className="company">{companyName}</label>
        <br />
        <label>{title}</label>
        <p>
          {startDate ? sDate.format("MMMM YYYY") : ""}
          {" - "}
          {currentVal && "current"}
          {endDate && !currentVal && eDate.format("MMMM YYYY")}
          {startDate && (endDate || currentVal) && " • "}
          {startDate && currentVal && sDate.fromNow()}
          {startDate && endDate && !currentVal && eDate.diff(sDate, "months") + " months"}
        </p>
        {
          !isValid && (
            <div className="work-history-item__invalid">
              <WarningIcon />
              <span>There is some information that needs to be fixed. Please edit this item.</span>
            </div>
          )
        }
      </div>
      <div
        className="history-item__icon clickable"
        onClick={
          customNavigate
            ? customNavigate
            : () =>
              navigate(`/profile/employment/${index}`, {
                state: { history: historyItem, historyList, isEditing },
              })
        }
      >
        <img src={pencil} alt="pencil icon" width="22" />
      </div>
    </div>
  );
};

export default WorkHistoryItem;
