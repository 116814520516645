import { useContext } from "react";
import { AuthContext } from "../../../users/components/context/auth-context";
import "./MainHeader.css";

interface IMainHeaderProps {
  children: React.ReactNode;
}

const MainHeader = ({ children }: IMainHeaderProps) => {
  const auth = useContext(AuthContext);
  return <header className={!auth.isOnboarding ? "main-header__loggedin" : "main-header"}>{children}</header>;
};

export default MainHeader;
