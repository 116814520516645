import { AxiosError } from 'axios'
import { QueryClient } from "@tanstack/react-query";

import "react-toastify/dist/ReactToastify.css";
import { errorHandler } from "../shared/util/helpers";

const queryErrorHandler = (error: unknown): void => {
  if (error instanceof AxiosError) {
    errorHandler({ error });
  } else {
    errorHandler({ error: new Error("Error connecting to the server") });
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      staleTime: 600000, // 10 minutes
      cacheTime: 900000, // 15minutes
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: queryErrorHandler,
    }
  },
});