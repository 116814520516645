import NotFound from "../NotFound";
import BasicData from "../BasicData";
import LocationInfo from "../location_info";
import TraitSelection from "../TraitSelection";
import { Route, Routes, useNavigate } from "react-router-dom";
import ResumeUpdate from "../profile/ResumeUpdate";
import PersonalSituation from "../PersonalSituation";
import LinkedInProfile from "../profile/LinkedInProfile";
import ProfileRedirection from "../profile/ProfileRedirection";

import WorkHistory from "../work/WorkHistory";
import ProfilePage from "../../pages/ProfilePage";
import EducationLevel from "../education/EducationLevel";
import EducationUpdate from "../education/EducationUpdate";
import EducationHistory from "../education/EducationHistory";
import WorkExperienceUpdate from "../work/WorkExperienceUpdate";
import Industry from "../industry";
import JobTitle from "../jobTitle";
import CandidateLocation from "../location_info/CandidateLocation";

import "../../pages/SignUp.css";
import VideoRecordingRouter from "./VideoRecordingRouter";


const UserProfileRouter = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<ProfileRedirection />} />
      <Route path="resume" element={<ResumeUpdate onSubmit={() => navigate(-1)} />} />
      <Route path="linkedin" element={<LinkedInProfile />} />
      <Route path="basics" element={<BasicData />} />
      <Route path="job-status" element={<PersonalSituation />} />
      <Route path="traits" element={<TraitSelection />} />
      <Route path="level" element={<EducationLevel />} />
      <Route path="education" element={<EducationUpdate />} />
      <Route path="education/history" element={<EducationHistory />} />
      <Route path="education/:educationId" element={<EducationUpdate />} />
      <Route path="work-history" element={<WorkHistory />} />
      <Route path="employment" element={<WorkExperienceUpdate />} />
      <Route path="employment/:workId" element={<WorkExperienceUpdate />} />
      <Route path="industry" element={<Industry />} />
      <Route path="job-title" element={<JobTitle />} />
      <Route path="location" element={<LocationInfo />} />
      <Route path="video/*" element={<VideoRecordingRouter />} />
      <Route path="page" element={<ProfilePage />} />
      <Route path="current-location" element={<CandidateLocation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default UserProfileRouter;
