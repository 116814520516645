import React, { useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { IInterviewLink } from "./ExternalInterview";
import DateTimeSelector from "./DateTimeSelector";
import { AlertText } from "./Styles";
import { CalendlyLinkWrapper, CalendlyWidgetWrapper } from "./CalendlyLink.styles";

const CalendlyLink = ({ companyName, interviewSchedulingLink, date, setDate, handleSubmit, isLoading }: IInterviewLink) => {
  const [isDone, setIsDone] = useState(false);

  useCalendlyEventListener({
    onEventScheduled: () => setIsDone(true)
  });

  return (
    <CalendlyLinkWrapper>
      {!isDone
        ? <p>Please select a date and time below. When you are done you will be prompted to confirm your interview in our systems.</p>
        : (
          <>
            <AlertText>Thank you for scheduling your interview with {companyName}.</AlertText>
            <p>We don't integrate with that calendar system (yet) so please let us know when you scheduled your interview so we can help remind and prepare you!</p>
          </>
        )
      }

      {!isDone
        ? (
          <CalendlyWidgetWrapper>
            <InlineWidget
              url={interviewSchedulingLink}
              styles={{ height: "680px" }}
            />
          </CalendlyWidgetWrapper>
        )
        : (
          <DateTimeSelector
            date={date}
            setDate={setDate}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        )
      }
    </CalendlyLinkWrapper>
  );
};

export default CalendlyLink;