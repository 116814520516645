import Modal from "../../shared/components/UIElements/Modal";
import React from "react";

const Encourage = ({ showModal }: { showModal: boolean }) => {
  return (
    <Modal
      show={showModal}
      onCancel={() => { }}
      header={""}
      footer={undefined}
      contentClass={"center"}
      fullscreen={true}
    >
      <div>
        <h1>Amazing! You're Almost done!</h1>
        <h4>Just a couple more steps</h4>
      </div>
    </Modal>
  );
};

export default Encourage;
