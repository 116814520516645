import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button } from "@vendition/vendition-frontend";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";
import { ReactComponent as Close } from "../../../resources/graphics/close_grey.svg";
import { ReactComponent as ImagoType } from "../../../resources/graphics/logo_emblem.svg";
import { ReactComponent as Film } from "../../../resources/graphics/film.svg";
import VideoThumbnail from "./videoThumbnail";
import Notification from "../../../shared/components/UIElements/Notification";

import "./VideoUpdate.css";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

const prompts: { [key: string]: string } = {
  empty: "",
  about: "Tell us about yourself",
  motivation: "What motivates you?",
  proud: "Tell us about a time you accomplished something you’re proud of",
  unique: "What makes your story unique?",
  great: "What do you think makes a great salesperson?",
  difference: "What differentiates a good from a great salesperson?",
};

interface IVideo {
  keyName: string;
  src: string;
  prompt: string;
  navPath: string;
}

const VideoUpdate = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const location = useLocation();

  const navigate = useNavigate();
  const { isEditing = false, videos: videoStr = [] } = location.state || {};
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const { mutate: updateCandidate } = useUpdateCandidate();

  const getPrompt = (sourceURL: string) => {
    if (sourceURL && typeof sourceURL === "string") {
      const lLimit = sourceURL.lastIndexOf("_");
      const rLimit = sourceURL.lastIndexOf(".");
      const promptSuffix = lLimit !== -1 && rLimit !== -1 ? sourceURL.substring(lLimit + 1, rLimit) : "empty";
      return prompts[promptSuffix] || "";
    } else {
      return "";
    }
  };

  const [videos, setVideos] = useState<Array<IVideo>>([
    {
      keyName: "video1",
      src: videoStr.video1,
      prompt: prompts["about"],
      navPath: "/profile/video/recording",
    },
    {
      keyName: "video2",
      src: videoStr.video2,
      prompt: getPrompt(videoStr.video2),
      navPath: "/profile/video/question",
    },
  ]);

  const deleteNotify = (video: string) => {
    setSelectedVideo(video);
    setShowNotification(true);
  };

  const deleteVideo = async () => {
    // execute mmutation
    updateCandidate({ [selectedVideo]: "" });
    // Let user know call was successful
    setToast({
      type: "Success",
      title: "",
      message: "Your video was deleted",
    });

    setVideos(
      videos.map((m) => {
        if (selectedVideo === m.keyName) {
          return selectedVideo === "video1" ? { ...m, src: "" } : { ...m, src: "", prompt: "" };
        } else {
          return m;
        }
      })
    );

    setSelectedVideo("");
    setShowNotification(false);
    setTimeout(() => setToast(null), 3000);
  };

  const VideoItem: React.FC<IVideo> = ({ src, prompt, navPath, keyName }) => (
    <div className="videos-list__item">
      <div className="videos-list__video-container">
        {src ? (
          <VideoThumbnail src={src} />
        ) : (
          <div
            className="placeholder clickable"
            onClick={() =>
              isEditing
                ? navigate(navPath, { state: { isEditing }, replace: true })
                : navigate(navPath, { replace: true, state: { isEditing } })
            }
          >
            <Film style={{ margin: "0.5rem 0 1rem" }} />
            <span>Create new video</span>
          </div>
        )}
      </div>
      <div className="videos-list__prompt">
        <span>Prompt:</span>
        <br />
        {prompt}
        {src && (
          <div className="actions">
            <button
              className="replace"
              onClick={() =>
                isEditing
                  ? navigate(navPath, { state: { isEditing }, replace: true })
                  : navigate(navPath, { replace: true, state: { isEditing } })
              }
            >
              Replace with new video
            </button>
            <br />
            <button className="delete" onClick={() => deleteNotify(keyName)}>
              Delete video
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <Card className={"video-edit__container"}>
        <div className="header__close">
          <ImagoType width={53} />
          <span onClick={() => navigate(isEditing ? "/profile/page" : "/profile")}>
            <Close width={24} height={24} stopColor={"#000"} />
          </span>
        </div>
        <h1>Edit Profile</h1>
        {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
        <div className="videos-list__container">
          {videos.map((v) => (
            <VideoItem key={v.keyName} keyName={v.keyName} src={v.src} navPath={v.navPath} prompt={v.prompt} />
          ))}
        </div>
      </Card>
      <Notification
        show={showNotification}
        onCancel={() => setShowNotification(false)}
        header={""}
        footer={
          <div className="center">
            <Button outline onClick={() => setShowNotification(false)}>
              Cancel
            </Button>
            <Button danger size="big" onClick={deleteVideo}>
              Delete
            </Button>
          </div>
        }
        contentClass={"center"}
      >
        <div>
          <h1>Confirm Deleting Video</h1>
          <h4>Candidates with a video are 90% more likely to get a Sales Apprenticeship.</h4>
        </div>
      </Notification>
    </>
  );
};

export default VideoUpdate;
