import React from "react";
import { useNavigate } from "react-router-dom";
import { IEducationObject } from "../education/EducationUpdate";
import { ReactComponent as Pencil } from "../../../resources/graphics/pencil.svg";
import { ReactComponent as Hat } from "../../../resources/graphics/graduation-cap.svg";
import  WarningIcon from "../../../resources/graphics/warning-icon.svg";

import moment from "moment";
import "./EducationSummary.css";

interface IEducationSummary {
  education: Array<IEducationObject>;
  isEditing?: boolean;
}

const EducationSummary = ({ education, isEditing }: IEducationSummary) => {
  const navigate = useNavigate();

  return (
    <div className="education-summary__container">
      {education.map((educationItem, index) => (
        <div key={`education_index_${index}`} className="education-summary__content">
          <div>
            <Hat width={33} style={{ margin: "1.2rem 1rem 0 0" }} />
          </div>
          <div className="education-summary__info">
            <div className="education-summary__headings">
              <h3>{educationItem.school}</h3>
            </div>
            <ul>
              <li>
                <b>Status:</b> {educationItem.status || (moment().isAfter(moment(educationItem.endDate)) && "Graduated")}
              </li>
              <li className="education-summary__info-date">
                <b>From:</b>
                {educationItem.startDate ? moment.utc(educationItem.startDate).format("MMM YYYY") : ""}
                {" to "}
                {educationItem.endDate ? moment.utc(educationItem.endDate).format("MMM YYYY") : ""}
              </li>
              <li>
                <b>Degree:</b> {educationItem.degree}
              </li>
              <li>
                <b>Major:</b> {educationItem.major}
              </li>
              <li>
                {educationItem.gpa && (
                  <React.Fragment>
                    <b>GPA:</b> <span>{educationItem.gpa}</span>
                  </React.Fragment>
                )}
              </li>
              {
                educationItem.comments?.length > 0 && (
                  <li>
                    <b>Extra curricular activities:</b>
                    <ul>
                      <li key={`activity_${index}`}>{educationItem.comments}</li>
                    </ul>
                  </li>
                )
              }
            </ul>
            {
              !educationItem.isValid && (
                <div className="education-summary__invalid">
                  <img src={WarningIcon} alt="Warning icon" />
                  <p>There is some information that needs to be fixed. Please edit this item.</p>
                </div>
              )
            }
          </div>
          <div
            className="education-summary__edit clickable"
            onClick={() =>
              navigate(`/profile/education/${index}`, {
                state: {
                  education: educationItem,
                  educationList: education,
                  isEditing: isEditing,
                },
              })
            }
          >
            <Pencil width={33} style={{ margin: "1.2rem 1rem 0 0" }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default EducationSummary;
