import { Button } from "@vendition/vendition-frontend";
import { useState } from "react";
import styled from "styled-components";
import Modal from 'react-modal';

const completedActionTypes = [
  "Online Learning",
  "Mentorship Session with Vendition",
  "Mentorship Session with Direct Manager",
  "Live Cohort Coaching Session",
]

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Wrapper = styled.div`
  margin: 2rem auto;
  padding: 1rem;
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 8px rgba(0,0,0,0.26);
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & input {
    padding: 0.5rem;
    width: 90%;
  }

  & select {
    height: 40px;
  }

  & label {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
`;

const groupByMonth = (startDate, actions) => {
  const grouped = {};

  const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  actions.forEach(action => {
    const month = monthDiff(new Date(startDate), new Date(action.actionDate));
    if (!grouped[month]) {
      grouped[month] = [];
    }
    grouped[month].push(action);
  });

  return grouped;
};

const getMonthName = (starDate, month) => {
  const date = new Date(starDate);
  date.setMonth(date.getMonth() + month);
  return date.toLocaleString('default', { month: 'long' });
}

const CompletedActions = ({ startDate, completedActions = [], updateApprenticeship }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const actionsByMonth = groupByMonth(startDate, completedActions);
  const handleSubmit = (e) => {
    e.preventDefault();
    const type = e.target.querySelector("#type").value;
    const topic = e.target.querySelector("#topic").value;
    const date = e.target.querySelector("#date").value;
    const dateObj = new Date(date);
    const parsedDate =  dateObj.valueOf() + dateObj.getTimezoneOffset() * 60000;
    
    const newAction = {
      type,
      topic,
      actionDate: parsedDate
    };

    updateApprenticeship({ completedActions: [...completedActions, newAction] });
    setIsModalOpen(false);
  }

  return (
    <>
      <Wrapper>
        <FlexWrapper>
          <h4>Completed Actions</h4>
          <Button onClick={() => setIsModalOpen(!isModalOpen)}>+ Add Completed Actions</Button>
        </FlexWrapper>

        {Object.keys(actionsByMonth).map(month => {
          return (
            <div key={month}>
              <h5 style={{ marginBottom: "0" }}>Month {month} - {getMonthName(startDate, parseInt(month))}</h5>
              <ul>
                {actionsByMonth[month].map(action => (
                  <li key={`${action.type}-${action.topic}`} style={{ fontSize: "14px" }}>
                    <strong>{action.type}:</strong> {action.topic}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </Wrapper>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Example Modal"
        style={customModalStyles}
        appElement={document.getElementById('root')}
      >
        <Form onSubmit={handleSubmit}>
          <h2>Add Completed Actions</h2>
          <div>
            <label htmlFor="type">Type</label>
            <select id="type">
              {completedActionTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="topic">Topic</label>
            <input type="text" id="topic" />
          </div>
          <div>
            <label htmlFor="date">Date</label>
            <input type="date" id="date" defaultValue={new Date().toISOString().split('T')[0]} />
          </div>
          <Button type="submit">Submit</Button>
        </Form>
      </Modal>
    </>
  )
}

export default CompletedActions;
