import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { IRequisition, Card, Button, ISubmission } from "@vendition/vendition-frontend";

import { useCompany } from "../../../components/pages/Company/useCompany";
import { ClientForCard } from "./Dashboard/DashboardHelper";

import "./CompanyItem.css";

export type InterviewStatus = "Match" | "InterviewRequest" | "Upcoming" | "Pending";

export interface ICompanyItemProps {
  company: ClientForCard;
  requisition: IRequisition;
  validationStatus: string;
  submissionId: number;
  submission?: ISubmission;
  appointmentStartDate?: number;
}

const CompanyItem = ({ company, requisition, validationStatus, submissionId, submission, appointmentStartDate }: ICompanyItemProps) => {
  const navigate = useNavigate();
  const rqCompany = useCompany(company.id);
  let validationCopy = "";

  switch (validationStatus) {
    case "Upcoming":
      if (!appointmentStartDate) break;
      
      const timezoneDate = moment(appointmentStartDate).tz(moment.tz.guess()).format("h:mm A (z)");
      validationCopy = `Your interview is at ${timezoneDate} on ${moment(appointmentStartDate).format("MMM DD")}`;
      break;
    case "Pending":
      validationCopy = "We’ll reach out with any next steps";
      break;
  }

  const handleCardClick = () => {
    if (validationStatus === "InterviewRequest") {
      navigate("schedule", {
        state: { requisition, company, submissionId, submission }
      })
    } else {
      navigate(
        `offer/${requisition.id}`,
        { state: { requisition, company, submissionId, isDemo: requisition.status !== "Accepting Candidates" } }
      )
    }
  };

  return (
    <Card className="company__holder" onClick={handleCardClick}>
      <div className="company-container">
        <div className="company-container__header">
          {company.logoURL || rqCompany?.logoURL ? (
            <img
              src={rqCompany?.logoURL ?? company.logoURL}
              className="company-container__placeholder"
              alt={rqCompany?.name ?? company.name}
            />
          ) : (
            <div className="company-container__placeholder">{rqCompany?.name?.[0]}</div>
          )}
          <span>{rqCompany?.name ?? company.name}</span>
        </div>
        <div className="company-container__description">
          <p>{company.employmentType}</p>
          <p>{company.employmentLocation}</p>
          <p className={`validation ${validationStatus || ""}`}>{validationCopy}</p>
        </div>
        <div className="company-container__footer no-border">
          {validationStatus && (
            <React.Fragment>
              {["InterviewRequest"].includes(validationStatus) && (
                <div className="company-container__footer-button">
                  <Button
                    onClick={() => navigate("schedule", {
                      state: { requisition, company, submissionId, submission }
                    })}
                    size="whole">Schedule Interview</Button>
                </div>
              )}
              {["Match", "Pending"].includes(validationStatus) && (
                <div className="company-container__footer-button">
                  <Button
                    size="whole">View {validationStatus === "Match" ? "Match" : "Job"}</Button>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </Card>
  );
};

export default CompanyItem;
