import { AuthContext } from "../../context/auth-context";
import { useContext, useEffect, useState } from "react";
import Blog from "../Blog";
import Bootcamp from "../Bootcamp";
import ProgressBar, { IProfileStatusItem } from "../ProgressBar";

import "./Dashboard.css";
import DashboardHeader from "../DashboardHeader";
import { useNavigate } from "react-router-dom";
import { ClientForCard, filterCompanies, filterInterviewsReadyToSchedule } from "./DashboardHelper";
import { useResume } from "../../../../shared/hooks/useResume";
import { useRequisitions } from "../../../../components/Requisitions/hooks/useRequisitions";
import { errorHandler } from "../../../../shared/util/helpers";
import { isProfileCompleted } from "../../profile/ProfileRedirection";
import ActionPanel from "./ActionItemComponent/ActionPanel";
import ErrorPage from "../../../pages/ErrorPage";

export interface ProfileCompletion {
  isComplete: boolean;
  section: string;
  payload?: Object;
}

export const paginateArray = (array: Array<any>, page_size: number, page_number: number) =>
  array.slice((page_number - 1) * page_size, page_size * page_number);

const DUMMY_PROGRESS: Array<IProfileStatusItem> = [
  {
    title: "We'll review your profile",
    description: "We'll determine if you're fit for a Sales Apprenticeship",
    status: false,
  },
  {
    title: "Choose your opportunities",
    description: "We'll match you to employers, and you can choose who you want to work with",
    status: false,
  },
  {
    title: "Start Interviewing",
    description: "Get trained and start interviewing for a full-time job",
    status: false,
  },
  {
    title: "Start Your New Job",
    description: "Get placed at a partner company and start your job with additional coaching and resources from Vendition",
    status: false,
  },
];

export interface IProfileCompletion {
  isComplete: boolean;
  section: string;
}

const Dashboard = (): JSX.Element => {
  const navigate = useNavigate();
  const { clearStoredData } = useResume();

  const [companies, setCompanies] = useState<Array<ClientForCard>>([]);

  const { thorUser, isLoggedIn, isLoggingIn, setIsOnboarding } = useContext(AuthContext);
  const { requisitions, error } = useRequisitions();

  const [profileComplete, setProfileComplete] = useState<IProfileCompletion>({
    isComplete: false,
    section: "",
  });

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      if (!isLoggingIn && !thorUser?.email) {
        navigate('/signup/resume');
      } else {
        setIsOnboarding(false);
        clearStoredData();
      }
    }
  }, [setIsOnboarding, isLoggedIn, isLoggingIn, navigate, clearStoredData, thorUser?.email]);

  useEffect(() => {
    try {
      if (thorUser) {
        const completion = isProfileCompleted(thorUser);
        setProfileComplete(completion);
      }
    } catch (error) {
      if (error instanceof Error) errorHandler({ error });
    }
  }, [thorUser]);

  useEffect(() => {
    if (requisitions) {
      const filtered = filterCompanies(requisitions, ['Internally Submitted']);
      if (filtered) {
        setCompanies(filtered);
      }
    }
  }, [requisitions]);

  if (error) return <ErrorPage />;

  return (
    <div className="dashboard-container">
      <DashboardHeader profileCompletion={profileComplete} companiesInterested={companies.length > 0} />
      <div className="dashboard-container__content">
        <ActionPanel
          actionItems={[
            {
              title: "Complete Your Profile",
              description: "Your profile helps our team know who you are and match you to employers.",
              actionCTA: "Complete Your Profile",
              action: () => navigate("/profile/"),
              shouldShow: !profileComplete.isComplete,
            },
            {
              title: "You Got Matches",
              description: "Choose the companies you’re interested to work with.",
              actionCTA: "Select Companies",
              action: () => navigate('/interviews'),
              shouldShow: (companies.length > 0),
            },
            {
              title: "VSAT Assessment",
              description: "Potential over Credentials! Help us identify your social, emotional, and behavioral skills. We'll email you the results and use it to match you to job opportunities.",
              actionCTA: "Take the Vendition Success Aptitude Test",
              action: () => navigate('/vsat'),
              shouldShow: !(thorUser?.sebResults),
            },
            {
              title: "Schedule Interview",
              description: "Schedule an interview with your top companies.",
              actionCTA: "Schedule Interview",
              action: () => navigate('/interviews'),
              shouldShow: !!filterInterviewsReadyToSchedule(requisitions).length,
            },
            {
              title: "Review your Profile",
              description: "We noticed some bad data in your profile. Please review and update your profile.",
              action: () => navigate("/profile/page"),
              actionCTA: "Review Profile",
              shouldShow: (thorUser?.education ?? []).some((education) => !education.isValid) || (thorUser?.employment ?? []).some((job) => !job.isValid),
            },
          ]}
        />
        <div className="dashboard-container__section">
          <ProgressBar progress={DUMMY_PROGRESS} isComplete={profileComplete} />
        </div>
        <div className="dashboard-container__section">
          <Bootcamp />
        </div>
        <div className="dashboard-container__section">
          <Blog />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
