import { useContext, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router";
import { Buffer } from "buffer";
import * as Sentry from "@sentry/react";

// @ts-ignore
import { VideoRecorder } from "@vendition/video-recorder";
import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";
import { useState } from "react";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import { errorHandler } from "../../../shared/util/helpers";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import FileUpload from "../../../shared/components/FormElements/FileUpload";

import { AuthContext } from "../context/auth-context";
import "./VideoAbout.css";
declare global {
  interface Window {
    Buffer: any;
  }
}

window.Buffer = Buffer;

const mimeTypes: { [key: string]: string } = {
  "video/x-msvideo": "avi",
  "video/mp4": "mp4",
  "video/mpeg": "mpeg",
  "video/mp2t": "ts",
  "video/webm": "webm",
  "video/quicktime": "mov"
};

const descriptions = ["", "motivation", "proud", "unique", "great", "difference"];

const VideoAbout = (): JSX.Element => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email, getToken } = useContext(AuthContext);
  const [videoBlob, setVideoBlob] = useState<any>();
  const { isCompleting = false, answer = null, isEditing = false } = location.state || {};
  const { mutateAsync: updateCandidate } = useUpdateCandidate();

  useEffect(() => {
    if (!email) navigate("/");
  }, [email, navigate]);

  const handleRecordingComplete = (videoBlob: any) => {
    if (videoBlob) {
      setVideoBlob(videoBlob);
      setIsValid(true);
    }
  };

  const handleFileUpload = (_, __, fileIsvalid, file) => {
    if(file) {
      const blob = new Blob([file], {type: file.type});
      setVideoBlob(blob);
      setIsValid(fileIsvalid);
    }
  }

  const handleSubmit = async () => {
    let profileURL = "";
    let desc = "about";
    if (videoBlob) {
      const [contentType] = videoBlob.type.split(";");
      let ext = "mp4";

      if (mimeTypes[contentType] && mimeTypes[contentType] !== 'undefined') {
        ext = mimeTypes[contentType];
      } else {
        Sentry.captureMessage(`Invalid video type: ${contentType}; Data: ${videoBlob.type}`);
      }

      if (answer) {
        desc = descriptions[answer.value] || "err";
      }

      setIsValid(false);

      const token = await getToken();
      const url = `${process.env.REACT_APP_AWS_DEV_URL}/videoPostURL/${email}?suffix=_${desc}.${ext}`;
      const videoUrl = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      if (videoUrl.status === 200) {
        try {
          const putVid = await axios.put(videoUrl.data.putURL, videoBlob, {
            headers: { "Content-Type": contentType },
          });

          if (putVid.status === 200) {
            profileURL = videoUrl.data.getURL;
          }
        } catch (error) {
          if (error instanceof Error) {
            errorHandler({ error });
          }
        }
      }
    }

    let bodyObj = { [`video${answer ? "2" : "1"}`]: profileURL };
    await updateCandidate(bodyObj);
    setIsValid(true);
    if (answer) {
      navigate("/dashboard");
    } else {
      navigate(isEditing ? "/profile/page" : "/profile/video/question");
    }
  };

  return (
    <Card className={"signup-content__container video-about__container"}>
      <ProfileHeader backNavigation="/profile/" progress={90} />
      <div className="signup-form__container">
        <h1>{isCompleting ? answer?.label || "Tell us About Yourself" : "Create New Video"}</h1>
        {!isCompleting && (
          <p>
            <strong>Your prompt: </strong>
            {answer?.label || "Tell us about yourself"}
          </p>
        )}
        <p>Only we'll see the video. Keep it casual and please keep your recording to 1 minute or less!</p>
        <div className="video-container">
          <VideoRecorder onCaptureCompleted={handleRecordingComplete} />
          {videoBlob && !isValid && (
            <div className="spinner-overlay">
              <LoadingSpinner />
            </div>
          )}
        </div>
        <FileUpload
              center
              id={"videoBlob"}
              onInput={handleFileUpload}
              label={""}
              buttonText="Upload Existing Video File"
              type="File"
              acceptedTypes="video/*"
            />
        <div className="edit-form__button-container">
          <Button size="big" onClick={handleSubmit} disabled={!isValid}>
            Submit Video
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default VideoAbout;