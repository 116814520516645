import { Suspense } from "react";
import HighRes from "../../resources/images/503@3x.png";
import LowRes from "../../resources/images/503.png";
import "./ErrorPage.css";

const ErrorPage = () => {
  return (
    <div className="error-page__container">
      <div className="error-page__info">
        <h1>It's Not You, It's Us</h1>
        <p>
          Something unexpected happened and we're looking into it. In the meantime, you can try refreshing the page, trying again
          in a few minutes, or contacting us
        </p>
      </div>
      <div className="error-page__image">
        <Suspense fallback={<img src={LowRes} alt="low_res" />}>
          <img src={HighRes} alt="high_res" />
        </Suspense>
      </div>
    </div>
  );
};

export default ErrorPage;
