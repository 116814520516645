import ReactDOM from "react-dom";
import "./SideDrawer.css";

interface ISideDrawerProps {
  children: React.ReactNode;
  show: boolean;
  onSideDrawerClick: () => void;
}

const SideDrawer = ({ children, show, onSideDrawerClick }: ISideDrawerProps): JSX.Element => {
  const content = show && (
    <aside className="side-drawer" onClick={onSideDrawerClick}>
      {children}
    </aside>
  );

  return ReactDOM.createPortal(content, document.getElementById("drawer-hook") as HTMLElement);
};

export default SideDrawer;
