import React, { HTMLAttributes, useContext } from "react";
import ReactDOM from "react-dom";
import { AuthContext } from "../../../users/components/context/auth-context";
import Backdrop from "./Backdrop";
import "./Notification.css";

interface INotificationOverlayProps {
  className?: string;
  headerClass?: string;
  header: string;
  contentClass?: string;
  footerClass?: string;
  onNotificationSubmit?: () => void;
  style?: HTMLAttributes<HTMLDivElement>;
  children: React.ReactNode;
  footer: React.ReactNode;
}

interface INotificationProps extends INotificationOverlayProps {
  show: boolean;
  onCancel: () => void;
}

const NotificationOverlay = ({
  className,
  style,
  headerClass,
  header,
  contentClass,
  onNotificationSubmit,
  children,
  footerClass,
  footer,
}: INotificationOverlayProps): JSX.Element => {
  const { isLoggedIn } = useContext(AuthContext);
  const content = (
    <div className={`${className !== undefined ? className : ""} notification ${isLoggedIn ? "logged" : "signup"}`} style={style}>
      <header className={`notification__header ${headerClass}`}>
        <h2>{header}</h2>
      </header>
      <form onSubmit={onNotificationSubmit ? onNotificationSubmit : (event) => event.preventDefault()}>
        <div className={`notification__content ${contentClass}`}>{children}</div>
        <footer className={`notification__footer ${footerClass}`}>{footer}</footer>
      </form>
    </div>
  );

  return ReactDOM.createPortal(content, document.getElementById("notification-hook") as HTMLElement);
};

const Notification = (props: INotificationProps) => {
  return (
    <>
      {props.show && (
        <>
          <Backdrop onClick={props.onCancel} />
          <NotificationOverlay {...props} />
        </>
      )}
    </>
  );
};

export default Notification;
