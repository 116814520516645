const ADMIN_LOCALSTORAGE_KEY = "isAdmin";

export const getStoredAdmin = (): boolean => {
    const storedUser = localStorage.getItem(ADMIN_LOCALSTORAGE_KEY);
    return storedUser ? JSON.parse(storedUser) : false;
}

export const setStoredAdmin = (isAdmin: boolean): void => {
    localStorage.setItem(ADMIN_LOCALSTORAGE_KEY, JSON.stringify(isAdmin));
}

export const clearStoredAdmin = (): void => {
    localStorage.removeItem(ADMIN_LOCALSTORAGE_KEY);
  };