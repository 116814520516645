import React, { useContext, useEffect, useState } from "react";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import Card from "../../shared/components/UIElements/Card";
import {
  VALIDATOR_CONTAINS_NUMBERS,
  VALIDATOR_CONTAINS_UPPERCASE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_SPECIAL_CHARACTERS,
} from "../../shared/util/validators";

import { IFormDispatch, IFormState, useForm } from "../../shared/hooks/form-hook";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { AuthContext } from "./context/auth-context";
import { errorHandler } from "../../shared/util/helpers";

const PasswordReset = (): JSX.Element => {
  const navigate = useNavigate();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const { isLoggedIn, setIsOnboarding } = useContext(AuthContext);
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const [formState, inputHandler] = useForm(
    {
      emailAddress: {
        value: "",
        isValid: false,
      },
      newPassword: {
        value: "",
        isValid: !codeSent,
      },
      code: {
        value: "",
        isValid: !codeSent,
      },
    },
    false
  );

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    try {
      const { emailAddress, newPassword, code } = formState.inputs;
      if (!codeSent) {
        const reset = await Auth.forgotPassword(emailAddress.value);
        if (reset) setCodeSent(true);
      } else {
        const reset = await Auth.forgotPasswordSubmit(emailAddress.value, (code.value as string).trim(), newPassword.value);
        if (reset === "SUCCESS") navigate("/login");
      }
    } catch (error) {
      if (error instanceof Error)
        setToast({
          type: "Error",
          message: errorHandler({ error, customMessage: "There was an error reseting your password" }).message,
          title: "",
        });
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("dashboard");
    setIsOnboarding(true);
  });

  return (
    <Card className="signup-content__container">
      <div className="signup-form__container">
        <h1>Please enter your email</h1>
        <form className="form-container" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
          <Input
            id="emailAddress"
            element="input"
            type={"text"}
            label="Email Address"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
            errorText="Enter a valid email"
            inputCallback={inputHandler as IFormDispatch}
            placeholder="jane.doe@example.com"
          />
          {codeSent && (
            <React.Fragment>
              <Input
                id="new-password"
                element="input"
                type={"password"}
                label="Password"
                validators={[
                  VALIDATOR_REQUIRE(),
                  VALIDATOR_MINLENGTH(8),
                  VALIDATOR_CONTAINS_UPPERCASE(),
                  VALIDATOR_SPECIAL_CHARACTERS(),
                  VALIDATOR_CONTAINS_NUMBERS(),
                ]}
                errorText="Enter a valid password (minimum of 8 characters)"
                inputCallback={inputHandler as IFormDispatch}
                placeholder="Create a password"
                autocomplete="new-password"
                verbose
              />
              <Input
                id="code"
                element="input"
                type={"text"}
                label="Code"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter the code we've sent you"
                inputCallback={inputHandler as IFormDispatch}
                placeholder="------"
              />
            </React.Fragment>
          )}
          <Button disabled={!(formState as IFormState).isValid} size={"whole"}>
            Send
          </Button>
        </form>
      </div>
    </Card>
  );
};

export default PasswordReset;
