import "./LoadingSpinner.css";

interface ILoadingSpinner {
  asOverlay?: boolean;
  className?: string;
}

const LoadingSpinner = (props: ILoadingSpinner): JSX.Element => {
  return (
    <div className={`spinner ${props.asOverlay && "loading-spinner__overlay"} `}>
      <div className={`lds-dual-ring ${props.className || ""}`}></div>
    </div>
  );
};

export default LoadingSpinner;
