import "./MainNavigation.css";
import MainHeader from "./MainHeader";
import NavLinks, { NavLinkItem } from "./NavLinks";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../../resources/graphics/vendition-logo.svg";
import React, { useContext, useState, useEffect, useCallback, useRef } from "react";
import { AuthContext } from "../../../users/components/context/auth-context";

import home from "../../../resources/graphics/home.svg";
import comments from "../../../resources/graphics/comments.svg";
import account from "../../../resources/graphics/account.svg";
import book from "../../../resources/graphics/book.svg";
import cog from "../../../resources/graphics/cog.svg";
import door from "../../../resources/graphics/door.svg";
import openBook from "../../../resources/graphics/open-book.svg";

import SideDrawer from "./SideDrawer";
import * as Sentry from "@sentry/react";
import { useResume } from "../../hooks/useResume";
import { useApprenticeship } from "../../../components/pages/Apprenticeship/useApprenticeship";

const mobileCheck = function () {
  const mobileBreakpoint = 768;
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
        a.substr(0, 4)
      ) ||
      window.innerWidth <= mobileBreakpoint ||
      window.screen.width <= mobileBreakpoint
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

const MainNavigation = (): JSX.Element => {
  const { apprenticeship } = useApprenticeship();
  const { isOnboarding, isLoggedIn, logout } = useContext(AuthContext);
  const { clearStoredData } = useResume();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(!mobileCheck());
  const navigate = useNavigate();
  const location = useLocation();
  let timeout = useRef(-1);

  const resizeHandler = useCallback(() => {
    const mobileBreakpoint = 768;
    const debounceTime = 100;
    clearTimeout(timeout.current);

    timeout.current = window.setTimeout(function () {
      if (window.innerWidth <= mobileBreakpoint || window.screen.width <= mobileBreakpoint) {
        setDrawerIsOpen(false);
      } else {
        setDrawerIsOpen(true);
      }
    }, debounceTime);
  }, []);

  useEffect(() => {
    try {
      window.gtag("set", "page_path", location.pathname);
      window.gtag("event", "page_view");
    } catch (error) {
      // This may happen if the user is using an ad blocker
      Sentry.captureMessage("GA tag not fired");
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, false);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);

  const createNavLinks = (): Array<NavLinkItem> => {
    if (!isOnboarding && isLoggedIn) {
      return [
        {
          path: "/dashboard",
          label: "your dashboard",
          icon: home,
        },
        {
          path: "/interviews",
          label: "interviews",
          icon: comments,
        },
        {
          path: "/profile/page",
          label: "profile",
          icon: account,
        },
        ...(apprenticeship?.status === "Active"
          ? [{
            path: "/apprenticeship",
            label: "apprenticeship",
            icon: openBook,
          }]
          : []),
        {
          path: process.env.REACT_APP_LMS!,
          label: "online training",
          icon: book,
          external: true,
        },
        {
          path: "/settings",
          label: "settings",
          icon: cog,
        },
        {
          path: "/login",
          label: "logout",
          icon: door,
          clickHandler: () => {
            logout();
            clearStoredData();
          },
        },
      ];
    }

    if (!location?.pathname.includes("login") &&
      location.pathname !== "/signup" &&
      !location.pathname.includes("/forgot") &&
      !location.pathname.includes("/signup/confirmation/") &&
      !location.pathname.includes("/signup/resume") &&
      !location.pathname.includes("/job-search-status")) {
      return [
        {
          path: "/login",
          label: "Save & Exit",
          clickHandler: () => {
            clearStoredData();
            navigate("/dashboard");
          },
        },
      ]
    }

    return [];

  };

  if (!isOnboarding) return (
    <React.Fragment>
      <SideDrawer
        show={drawerIsOpen}
        onSideDrawerClick={() => {
          if (mobileCheck()) setDrawerIsOpen(false);
        }}
      >
        <div className="side-drawer__logo">
          <Link to="/dashboard">
            <img src={logo} alt="Vendition" />
          </Link>
        </div>
        <nav className="main-navigation__drawer-nav">
          <NavLinks links={createNavLinks()} />
        </nav>
      </SideDrawer>
      <MainHeader>
        <div className="main-navigation__logo">
          <Link to="/">
            <img src={logo} alt="Vendition" width="100" />
          </Link>
        </div>
        <button className={"main-navigation__menu-btn"} onClick={() => setDrawerIsOpen(!drawerIsOpen)}>
          <span />
          <span />
          <span />
        </button>
      </MainHeader>
    </React.Fragment>
  )

  return (
    <MainHeader>
      <div className="main-navigation__logo" onClick={() => navigate(`${isOnboarding ? "/login" : "/dashboard"}`)}>
        <img src={logo} width={100} alt="Vendition" />
      </div>
      <nav className={"main-navigation__header-nav"}>
        {location.pathname !== "/" && location.pathname !== "/auth" && <NavLinks links={createNavLinks()} />}
      </nav>
    </MainHeader>
  )
};

export default MainNavigation;
