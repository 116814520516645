const VALIDATOR_TYPE_REQUIRE = "REQUIRE";
const VALIDATOR_TYPE_MINLENGTH = "MINLENGTH";
const VALIDATOR_TYPE_MAXLENGTH = "MAXLENGTH";
const VALIDATOR_TYPE_MIN = "MIN";
const VALIDATOR_TYPE_MAX = "MAX";
const VALIDATOR_TYPE_EMAIL = "EMAIL";
const VALIDATOR_TYPE_FILE = "FILE";
const VALIDATOR_TYPE_PASSWORD_HAS_SC = "SPECIAL_CHARACTER";
const VALIDATOR_TYPE_HAS_UPPER_CASE = "UPPERCASE";
const VALIDATOR_TYPE_HAS_NUMBERS = "NUMBERS";
const VALIDATOR_TYPE_NUMERIC = "NUMERIC";
const VALIDATOR_TYPE_LOGIN = "LOGIN";
const VALIDATOR_TYPE_LINKEDIN = "LINKEDIN";
export interface IValidator {
  type: string;
  message: string;
  val: number;
  isValid: boolean;
}

export const VALIDATOR_REQUIRE = () => ({
  type: VALIDATOR_TYPE_REQUIRE,
  val: 0,
  message: "Must not be empty",
  isValid: false,
});
export const VALIDATOR_FILE = () => ({
  type: VALIDATOR_TYPE_FILE,
  val: 0,
  message: `Must be of type file.`,
  isValid: false,
});
export const VALIDATOR_MINLENGTH = (val: number) => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val,
  message: `Must be at least ${val} characters.`,
  isValid: false,
});
export const VALIDATOR_MAXLENGTH = (val: number) => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val,
  message: `Must be maximum ${val} characters.`,
  isValid: false,
});
export const VALIDATOR_MIN = (val: number) => ({
  type: VALIDATOR_TYPE_MIN,
  val: val,
  message: `Must be greater than ${val}.`,
  isValid: false,
});
export const VALIDATOR_MAX = (val: number) => ({
  type: VALIDATOR_TYPE_MAX,
  val: val,
  message: `Must be smaller than ${val}.`,
  isValid: false,
});
export const VALIDATOR_SPECIAL_CHARACTERS = () => ({
  type: VALIDATOR_TYPE_PASSWORD_HAS_SC,
  val: 0,
  message: `Must contain at least 1 special character.`,
  isValid: false,
});
export const VALIDATOR_EMAIL = () => ({
  type: VALIDATOR_TYPE_EMAIL,
  val: 0,
  message: `Must be an email.`,
  isValid: false,
});
export const VALIDATOR_CONTAINS_UPPERCASE = () => ({
  type: VALIDATOR_TYPE_HAS_UPPER_CASE,
  val: 0,
  message: `Must contain at least 1 Uppercase letter.`,
  isValid: false,
});

export const VALIDATOR_CONTAINS_NUMBERS = () => ({
  type: VALIDATOR_TYPE_HAS_NUMBERS,
  val: 0,
  message: `Must contain at least 1 number.`,
  isValid: false,
});

export const VALIDATOR_NUMERIC = () => ({
  type: VALIDATOR_TYPE_NUMERIC,
  val: 0,
  message: `Must contain only numbers.`,
  isValid: false,
});

export const VALIDATOR_LOGIN = () => ({
  type: VALIDATOR_TYPE_LOGIN,
  val: 0,
  message: `Invalid format.`,
  isValid: false,
});

export const VALIDATOR_LINKEDIN = () => ({
  type: VALIDATOR_TYPE_LINKEDIN,
  val: 0,
  message: `Must be a valid LinkedIn URL.  For example "https://www.linkedin.com/in/my-profile/"`,
  isValid: false,
});

export const validate = (value: any, validators: Array<IValidator>) => {
  let isValid = true;
  for (const validator of validators) {
    switch (validator.type) {
      case VALIDATOR_TYPE_REQUIRE:
        if (typeof value === "boolean") isValid = isValid && value;
        else if (typeof value === "number") isValid = isValid && !isNaN(value);
        else if (typeof value === "string") isValid = isValid && value.trim().length > 0;
        validator.isValid = isValid;
        break;
      case VALIDATOR_TYPE_MINLENGTH:
        isValid = isValid && value.trim().length >= validator.val;
        validator.isValid = value.trim().length >= validator.val;
        break;
      case VALIDATOR_TYPE_MAXLENGTH:
        isValid = isValid && value.trim().length <= validator.val;
        validator.isValid = value.trim().length <= validator.val;
        break;
      case VALIDATOR_TYPE_MIN:
        isValid = isValid && +value >= validator.val;
        validator.isValid = +value >= validator.val;
        break;
      case VALIDATOR_TYPE_MAX:
        isValid = isValid && +value <= validator.val;
        validator.isValid = +value <= validator.val;
        break;
      case VALIDATOR_TYPE_EMAIL:
        isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
        validator.isValid = /^\S+@\S+\.\S+$/.test(value);
        break;
      case VALIDATOR_TYPE_PASSWORD_HAS_SC:
        isValid = isValid && /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);
        validator.isValid = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);
        break;
      case VALIDATOR_TYPE_HAS_UPPER_CASE:
        isValid = isValid && /[A-Z]/.test(value);
        validator.isValid = /[A-Z]/.test(value);
        break;
      case VALIDATOR_TYPE_HAS_NUMBERS:
        isValid = isValid && /\d/.test(value);
        validator.isValid = /\d/.test(value);
        break;
      case VALIDATOR_TYPE_NUMERIC:
        isValid = isValid && !isNaN(value);
        validator.isValid = !isNaN(value);
        break;
      case VALIDATOR_TYPE_LOGIN:
        isValid = isValid && (/^\S+@\S+\.\S+$/.test(value) || /^\S+@\S+\.\S+ as \S+@\S+\.\S+$/.test(value));
        validator.isValid = (/^\S+@\S+\.\S+$/.test(value) || /^\S+@\S+\.\S+ as \S+@\S+\.\S+$/.test(value));
        break;
      case VALIDATOR_TYPE_LINKEDIN:
        const linkedinRegex = /^https:\/\/((www|[a-z]{2})\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9]+/i;
        const isValidLink = linkedinRegex.test(value) || value === "";

        isValid = isValid && isValidLink;
        validator.isValid = isValidLink;
        break;
      default:
        isValid = true;
        break;
    }
  }
  return { isValid, validators };
};
