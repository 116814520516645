import { ReactNode } from "react";
import "./ToastNotification.css";

type TNotification = "Error" | "Success" | "Warning" | "Custom";

export interface IToastNotificationProps {
  title: string;
  message: string;
  footer?: ReactNode;
  type: TNotification;
  className?: string;
}

const ToastNotification = ({ title, message, type, className, footer }: IToastNotificationProps): JSX.Element => {
  return (
    <div className={`toast-notification__container ${className ? className : ""} ${type}`}>
      <div className="toast-notification__container-info">
        {title !== "" && <h2>{title}</h2>}
        <p>{message}</p>
      </div>
      <div className="toast-notification__container-footer">{footer}</div>
    </div>
  );
};

export default ToastNotification;
