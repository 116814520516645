import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import { BrowserTracing } from "@sentry/tracing";
import CandidateApp from "./components/CandidateApp/CandidateApp";
import "./index.css";

Sentry.init({
  dsn: "https://85bff78b150741b4b0920c9f2f2240c7@o1203034.ingest.sentry.io/6328816",
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  environment: process.env.REACT_APP_SENTRY_ENV ?? "default",
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<CandidateApp />);
reportWebVitals();
