import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../users/components/context/auth-context";

import chevron from "../../../resources/graphics/arrow-back.svg";
import { ReactComponent as Close } from "../../../resources/graphics/close_grey.svg";
import { ReactComponent as ImagoType } from "../../../resources/graphics/logo_emblem.svg";

//import './ProfileHeader.css'

interface ProfileHeaderProps {
  progress: number;
  backNavigation?: string;
  closeNavigation?: string;
}

const ProfileHeader = ({ progress, backNavigation, closeNavigation = "/profile/page" }: ProfileHeaderProps) => {
  const { isOnboarding } = useContext(AuthContext);
  const navigate = useNavigate();

  return isOnboarding ? (
    <React.Fragment>
      <div className="progress-bar">
        <div className="progress-bar__progress" style={{ width: `${progress}%` }} />
      </div>
      <div className="signup-header__chevron">
        {backNavigation && (
          <span onClick={() => navigate(backNavigation)}>
            <img src={chevron} alt="back" width="20" height="20" />
          </span>
        )}
      </div>
    </React.Fragment>
  ) : (
    <div className="signup-header__close">
      <ImagoType />
      <span className="clickable" onClick={() => navigate(closeNavigation)}>
        <Close width={19} height={19} stopColor={"#000"} />
      </span>
    </div>
  );
};

export default ProfileHeader;
