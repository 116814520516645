import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';

import Button from "../../../shared/components/FormElements/Button";
import { AuthContext } from "../../../users/components/context/auth-context";

const Container = styled.div`
  width: 100%;
  max-width: 100vw;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Card = styled.div`
  width: 600px;
  margin: 1rem auto;
  padding: 2.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
  }

  h1 {
    margin: 0 0 0.625rem;
    font-size: 2.75rem;
    line-height: 3.75rem;
    text-transform: capitalize;
  }

  p {
    font-size: 0.85rem;
    margin: 0 0 1.85rem;
  }

  @media (max-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem 1rem;
    margin: 0;

    h1 {
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
  }
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em auto 0;
  max-width: 400px;
`;

const JobSearchStatus = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setIsOnboarding } = useContext(AuthContext);
  const [accepted, setAccepted] = useState<boolean>(false);

  useEffect(() => {
    setIsOnboarding(true);
    const hash = searchParams.get("hash");
    const action = searchParams.get("action");

    if (!hash || !action) navigate("/dashboard");

    setAccepted(action === 'looking');

    fetch(`${process.env.REACT_APP_AWS_DEV_URL}/candidate/jobSearchStatus?hash=${hash}&action=${action}`, {
      method: 'PATCH',
    });

    return () => {
      setIsOnboarding(false);
    }
  }, [setIsOnboarding, navigate, searchParams]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const title = accepted ? 'Great to have you back!' : 'Thanks for updating us!';
  const message = accepted
    ? 'We look forward to helping you find your next job.'
    : `If you're in the market again don't hesitate to login and update your job search status. Our free online training will always be here as a resource for you.`

  return (
    <Container>
      <Card>
        <h1>{title}</h1>
        <p>{message}</p>
        <FlexBox>
          <Button onClick={() => navigate("/dashboard")} centred>Go to Dashboard</Button>
          <Button onClick={() => window.open(process.env.REACT_APP_LMS, "_blank")} centred>Online Training</Button>
        </FlexBox>
      </Card>
    </Container>
  );
};

export default JobSearchStatus;
