import { Route, Routes } from 'react-router-dom';

import Login from "../../users/pages/Login";
import Signup from "../../users/pages/Signup";
import Settings from "../../users/pages/Settings";
import NotFound from "../../users/components/NotFound";
import PasswordReset from "../../users/components/PasswordReset";
import CompanyPageHolder from "../pages/Company/CompanyPage";
import InterviewRouter from "../../users/components/router/InterviewRouter";
import UserProfileRouter from "../../users/components/router/UserProfileRouter";
import ProfileRedirection from "../../users/components/profile/ProfileRedirection";
import Dashboard from '../../users/components/dashboard/Dashboard/Dashboard';
import VSATPreparation from '../pages/VSATQuiz/preparation';
import VSATQuiz from '../pages/VSATQuiz';
import ThankYou from '../pages/VSATQuiz/ThankYou';
import JobSearchStatus from '../pages/JobSearchStatus';
import Apprenticeship from '../pages/Apprenticeship';

const AppRoutes = () => (
  <Routes>
    <Route path="/*" element={<ProfileRedirection />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/interviews/*" element={<InterviewRouter />} />
    <Route path="/company/:companyId" element={<CompanyPageHolder />} />
    <Route path="/profile/*" element={<UserProfileRouter />} />
    <Route path="/apprenticeship" element={<Apprenticeship />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/login" element={<Login />} />
    <Route path="/auth/:redirectURL" element={<Login />} />
    <Route path="/signup/*" element={<Signup />} />
    <Route path="/forgot" element={<PasswordReset />} />
    <Route path="/vsat" element={<VSATPreparation />} />
    <Route path="/vsat/quiz" element={<VSATQuiz />} />
    <Route path="/vsat/thank-you" element={<ThankYou />} />
    <Route path="/job-search-status" element={<JobSearchStatus />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
export default AppRoutes;