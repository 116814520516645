import { IRequisition, ISubmission } from "@vendition/vendition-frontend";
import { InterviewStatus } from "../../../users/components/dashboard/CompanyItem";
import { ClientForCard } from "../../../users/components/dashboard/Dashboard/DashboardHelper";
import { IInterview } from "./Interviews.types";

const getSumissionStatus = (
  status: string,
  req: IRequisition
): InterviewStatus => {
  if (status === "Internally Submitted") return "Match";
  if (req.interviewSchedulingLink) {
    if (status === "Resume Approved") return "InterviewRequest";
    if (
      status === "Candidate Interested" &&
      req.resumeReview === "Not Required"
    )
      return "InterviewRequest";
  }

  return "Pending";
};

export const filterRequisitions = (
  requisitions: IRequisition[]
): IInterview[] => {
  if (!requisitions) return [];

  const interviews: Array<IInterview> = [];

  requisitions.forEach((req: IRequisition) => {
    let company: ClientForCard = {
      id: req.client.id,
      name: req.client.name,
      logoURL: req.client.logoURL,
      employmentLocation: req.location,
      employmentType: req.title,
      startDate: 0,
      interviewSchedulingMethod: req.interviewSchedulingMethod,
      interviewSchedulingLink: req.interviewSchedulingLink,
      resumeReview: req.resumeReview,
    };

    if (req.submissions.length) {
      req.submissions.forEach((submission) => {
        const status = getSumissionStatus(submission.status, req);
        company.startDate = submission.dateLastModified;

        interviews.push({
          id: "" + req.id,
          company,
          status,
          requisition: req,
          submissionId: submission.id,
          submission,
        });
      });
    }

    if (req.appointments.length) {
      const now = new Date().getTime();

      req.appointments.forEach((appointment) => {
        if (!appointment.dateBegin || appointment.dateBegin < now) return;

        interviews.push({
          id: "" + req.id,
          submissionId: appointment.id || 0,
          company,
          status: "Upcoming",
          requisition: req,
          submission: {} as ISubmission,
          appointmentStartDate: appointment.dateBegin,
        });
      });
    }
  });
  return interviews;
};

export const removePendingWhenExistsOnUpcoming = (
  pending: IInterview[],
  upcoming: IInterview[]
): IInterview[] => {
  return pending.filter((interview) => {
    return !upcoming.find(
      (upcomingInterview) =>
        upcomingInterview.company.id === interview.company.id
    );
  });
};
