import React, { FunctionComponent } from "react";
import ActionItemComponent from "./ActionItemComponent";
import { PanelContainer } from "./ActionPanel.styles";
import { IActionPanelProps } from "./ActionPanel.types";

const ActionPanel: FunctionComponent<IActionPanelProps> = ({ actionItems }): JSX.Element => {
  return (
    <PanelContainer className="dashboard-container__section">
      <h1>Action Items</h1>
      <hr />
      {actionItems.every((item) => !item.shouldShow) ? (
        <h2>No action items right now, we'll let you know if we need anything from you!</h2>
      ) : (
        actionItems.map(
          (item, index) =>
            item.shouldShow && (
              <React.Fragment key={`${item.title}_${index}`}>
                <ActionItemComponent
                  title={item.title}
                  description={item.description}
                  action={item.action}
                  shouldShow={item.shouldShow}
                  actionCTA={item.actionCTA}
                  path={item.path}
                  isExternal={item.isExternal}
                />
                <hr />
              </React.Fragment>
            )
        )
      )}
    </PanelContainer>
  );
};

export default ActionPanel;
