import Card from "../../../shared/components/UIElements/Card";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

import Button from "../../../shared/components/FormElements/Button";
import OptionSelect from "../../../shared/components/FormElements/OptionSelect";
import { useOptions } from "../../../shared/hooks/options-hooks";
import { selectInitialOptionsFromState } from "../location_info/selectedLocationsHelper";

import { AuthContext } from "../context/auth-context";
import { errorHandler } from "../../../shared/util/helpers";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";

import "../TraitSelection";
import { JOB_TITLE_OPTIONS } from "../../../shared/util/constants";
import TitleAutocomplete from "../../../shared/components/FormElements/TitleAutocomplete";
import { VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import { FormContainer } from "./styles";

const IndustrySelection = () => {
  // Hooks Usage
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, thorUser } = useContext(AuthContext);
  const { mutateAsync: updateCandidate } = useUpdateCandidate();
  // Redirection evaluation
  const { isEditing = false } = location.state ?? {};

  const initialJobTitles = location.state?.preferredJobTitle || thorUser?.preferredJobTitle || [];

  const initialLabels = initialJobTitles.map(item => (typeof item === "string") ? item : item.title);
  const initialData = initialJobTitles.map(item => (typeof item === "string") ? { title: item } : item);

  const [optionState, optionHandler, selectedJobTitles, addOptionHandler ] = useOptions({
    options: isLoggedIn ? selectInitialOptionsFromState(JOB_TITLE_OPTIONS, initialLabels, initialData) : JOB_TITLE_OPTIONS,
  });

  // Component State
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let requestObject = {
      preferredJobTitle: selectedJobTitles.map(({isSelected, ...rest}) => ({title: rest.value, ...rest.data})),
    };
    try {
      await updateCandidate(requestObject);
      navigate(isEditing ? "/profile/page" : "/profile/location");
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
        });
      }
    }
  };

  const inputHandler = useCallback((value) => {
    addOptionHandler(value.title, value.title, true, value);
  }, [addOptionHandler]);

  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn, navigate]);

  return (
    <Card className={"signup-content__container main-trait__container"}>
      <ProfileHeader progress={55} backNavigation={"/profile/industry"} />
      <h1>What roles are you interested in?</h1>
      <form className="signup-form__container" onSubmit={submitHandler}>
        {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
        <div className="trait-container">
          <div className="form-container list-container">
            <ul className={"trait-list"}>
              {optionState.options.map((item) => (
                <OptionSelect
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  initialSelected={item.isSelected}
                  optionCallback={optionHandler}
                  isDisabled={false}
                />
              ))}
            </ul>
            {optionState.options.filter(item => (item.label === "Other" && item.isSelected)).length > 0 && (
              <FormContainer>
                <TitleAutocomplete
                  id="txtTitle"
                  label="Other Job Titles"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter a title you're interested in"
                  inputCallback={() => {}}
                  placeholder="Enter a title not listed"
                  initialValue={""}
                  onOptionSelect={inputHandler}
                />
              </FormContainer>
            )}
          </div>
          <div className="edit-form__button-container">
            {isEditing ? (
              <>
                <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                  Cancel
                </Button>
                <Button size="big" disabled={!selectedJobTitles.length}>
                  Save
                </Button>
              </>
            ) : (
              <Button size="big" disabled={!selectedJobTitles.length}>
                Next
              </Button>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};

export default IndustrySelection;
