import { NavLink } from "react-router-dom";
import nielsen from "../../../resources/images/nielsen.png";

import styled from "styled-components";

const BootcampContainer = styled.section`  
  padding-bottom: 2rem;
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    img {
      flex: 1;
      max-width: 400px;
      margin: 0 2rem;
    }

    & > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-right: 2rem;
      
      p {
        flex: 1;
        text-align: left;
        max-width: 500px;
        min-width: 300px;
        margin-right: 2rem;
      }

      a {
        width: 200px;
        margin: 0 auto;
      }
    }
  }
  @media (max-width: 1240px) {
    & > div {
      flex-direction: column;

      & > div {
        flex-direction: column;
        p {
          text-align: center;
          width: 90%;
          margin: 1rem;
          padding: 0 5%;
        }
        img {
          margin: 0 auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
`

const Bootcamp = (): JSX.Element => {
  return (
    <BootcampContainer className="section-container">
      <h1>Venditions Sales Bootcamp</h1>
      <div>
        <img src={nielsen} alt="bootcamp" />
        <div>
          <p>
            Get a head start! The Vendition Sales Bootcamp is a free, part time,
            online course for anyone looking to earn a job in tech sales.
          </p>
          <NavLink
            className="button"
            style={{ minHeight: "unset" }}
            to={process.env.REACT_APP_LMS!}
            target="_blank" rel="noreferrer"
          >Explore Bootcamp</NavLink>
        </div>
      </div>
    </BootcampContainer>
  );
};

export default Bootcamp;
