import { Button, Card } from "@vendition/vendition-frontend";
import styled from "styled-components";

export const InterviewWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  background-color: #fff;

  @media (min-width: 768px) {
    min-width: 600px;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  align-items: center;

  & > img {
    height: 22px;
    margin-top: -2px;
    margin-left: -12px;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledCard = styled(Card)`
  overflow: visible;
`;

export const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  background-color: #1f2240;
  color: white;

  &:hover {
    cursor: pointer;
  }

  & > img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  & > h2 {
    width: 100%;
  }

  & > h3 {
    margin-left: 10px;
  }

  & > p {
    width: 100%;
    margin-top: -10px;
  }
`;

export const StyledCardBody = styled.div`
  padding: 20px;
`;

export const ExternaLink = styled.a`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    color: #0056b3;
  }
`;

export const AlertText = styled.p`
  color: #007bff;
  font-weight: bold;
  margin: 20px 0 0;
`;

export const DateConfirmation = styled.div`
  display: flex;
  align-items: center;

  & > img {
    height: 22px;
    margin-right: 10px;
  }
`;

export const EmailConfirmationText = styled.p`
  font-weight: 200;  
  color: #666;
  font-size: 14px;
  margin-top: 0;
`;

export const StyledButton = styled(Button)`
  margin-left: 0px;
`;