import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router";
import Card from "../../../shared/components/UIElements/Card";
import WorkHistoryItem from "./WorkHistoryItem";

import chevron from "../../../resources/images/arrow_back@3x.png";
import plus from "../../../resources/graphics/plus.svg";
import { Button } from "@vendition/vendition-frontend";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth-context";
import { useResume } from "../../../shared/hooks/useResume";
import { BasicUserData } from "../../pages/ProfilePage";
import { errorHandler } from "../../../shared/util/helpers";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import "./WorkHistory.css";

const WorkHistory = () => {
  const navigate = useNavigate();
  const { setThorUser, thorUser } = useContext(AuthContext);
  const { parsedUser, deleteStoredProperty, clearStoredData } = useResume();
  const { mutate: updateCandidate, status: updateStatus } = useUpdateCandidate();

  useEffect(() => {
    if (parsedUser?.employment && !thorUser?.employment) {
      setThorUser({ ...(thorUser as BasicUserData), employment: [...parsedUser.employment] });
      // deleteStoredProperty("employment");
    }
  }, [deleteStoredProperty, parsedUser, setThorUser, thorUser]);

  const handleContinue = async () => {
    const employment = thorUser?.employment || [];

    try {
      if (employment.length > 0) {
        const requestObject = {
          employment,
          employmentExperience: "true",
        };

        updateCandidate(requestObject);
        if (updateStatus === "success") {
          clearStoredData();
        }
      } else {
        clearStoredData();
      }
      navigate("/profile/industry");
    } catch (error) {
      if (error instanceof Error) {
        errorHandler({ error, customMessage: "Could not save Work History records" });
      }
    }
  };

  return (
    <Card className={"signup-content__container"}>
      <div className="progress-bar">
        <div className="progress-bar__progress" style={{ width: "75%" }} />
      </div>
      <div className="signup-header__chevron">
        <span onClick={() => navigate("/profile/education/history")}>
          <img src={chevron} alt="back" width="20" height="20" />
        </span>
      </div>
      <div className="signup-form__container">
        <h1>Your Work Experience</h1>
        <div className="work-history__container">
          {(thorUser?.employment || []).map((item, index) => (
            <WorkHistoryItem
              index={index}
              key={`saved_${item.id || uuid()}`}
              historyItem={item}
              historyList={thorUser?.employment || []}
            />
          ))}
          <div
            className="history-item__container clickable"
            onClick={() =>
              navigate("/profile/employment", {
                state: {
                  historyList: thorUser?.employment || [],
                },
              })
            }
          >
            <div className="history-item__button">
              <img src={plus} alt="plus icon" width="22" />
            </div>
            <div className="history-item__add">
              <label>Add more experience</label>
            </div>
          </div>
        </div>
        <Button type="button" onClick={() => handleContinue()} size={"big"}>
          Next
        </Button>
      </div>
    </Card>
  );
};

export default WorkHistory;
