import { useRef, useState } from "react";
import { ReactComponent as Play } from "../../../resources/graphics/play.svg";
import userIcon from "../../../resources/images/account@3x.png";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";

import "./videoThumbnail.css";

interface Props {
  src: string | undefined;
}

const VideoThumbnail = ({ src }: Props) => {
  const videoRef = useRef<any>(null);
  const [paused, setPaused] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let waiting = false;
  let isFullScr = false;
  let timeout: ReturnType<typeof setTimeout>;

  const play = () => {
    videoRef.current?.play();
    setPaused(false);
  };
  const pause = () => {
    videoRef.current?.pause();
    setPaused(true);
  };

  const handleClick = () => {
    waiting = false;
    timeout = setTimeout(() => {
      if (!waiting) {
        if (paused) play();
        else pause();
      }
    }, 500);
  };

  const handleDoubleClick = async () => {
    if (timeout) clearTimeout(timeout);
    waiting = true;
    if (isFullScr) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
        isFullScr = false;
      }
    } else {
      if (videoRef.current?.webkitRequestFullscreen) {
        await videoRef.current?.webkitRequestFullscreen();
      }
      if (videoRef.current?.msRequestFullscreen) {
        await videoRef.current?.msRequestFullscreen();
      }
      if (videoRef.current?.requestFullscreen) {
        await videoRef.current?.requestFullscreen();
        isFullScr = true;
      }
    }
  };

  return (
    <div className="video-thumbnail__container">
      {src && (
        <video
          ref={videoRef}
          className="video-thumbnail__video clickable"
          onClick={handleClick}
          onEnded={() => {
            setPaused(true);
          }}
          onDoubleClick={handleDoubleClick}
          onWaiting={() => {
            setIsLoading(true);
          }}
          onCanPlay={() => {
            setIsLoading(false);
          }}
          preload="none"
          poster={userIcon}
          controlsList="nodownload noremoteplayback"
        >
          <source src={src} />
        </video>
      )}
      {src && paused && <Play className="video-thumbnail__play-button clickable" onClick={play} />}
      {!paused && isLoading && <LoadingSpinner />}
    </div>
  );
};

export default VideoThumbnail;
