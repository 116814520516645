import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "../context/auth-context";

import PreferredLocation from "./PreferredLocation";
import RoleType from "./RoleType";
import { useLocation, useNavigate } from "react-router-dom";
import { errorHandler } from "../../../shared/util/helpers";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

export interface IGeo {
  lat: number,
  lon: number,
}
export interface ProfileInterface {
  preferredLocation?: Array<string>,
  preferredWorkplaceType?: Array<string>,
  workAuthorized?: string,
  preferredLocationGeo?: Array<IGeo>,
}

const LocationInfo = () => {
  const [step, setStep] = useState(1);
  const { email } = useContext(AuthContext);
  const [selectedRole, setSelectedRole] = useState<Array<string> | undefined>([]);
  const { mutate: updateCandidate } = useUpdateCandidate();
  const navigate = useNavigate();
  const location = useLocation();

  // Authentication verification
  useEffect(() => {
    !email && navigate("/");
  }, [email, navigate]);

  const { isEditing = false } = location.state ?? {};

  const navigateBack = () => navigate(isEditing ? "/profile/page" : "/profile/video");

  const onSelectRoleType = async ({ preferredWorkplaceType, workAuthorized }: ProfileInterface) => {
    setSelectedRole(preferredWorkplaceType)
    const isOnlyRemote = preferredWorkplaceType?.length === 1 && preferredWorkplaceType[0] === 'Remote';
    if (isOnlyRemote) {
      const data = {
        workAuthorized,
        preferredWorkplaceType: preferredWorkplaceType,
        preferredLocation: ["Remote"]
      }
      await onSave(data);
      navigateBack();
    } else {
      const data = {
        workAuthorized,
        preferredWorkplaceType: preferredWorkplaceType,
      }

      onSave(data)
      setStep(2);
    }
  }

  const onSelectLocation = async (payload: ProfileInterface) => {
    const inlcludedData = selectedRole?.includes("Remote")
      ? {preferredLocation: [ ...payload.preferredLocation!, "Remote"], preferredLocationGeo: payload.preferredLocationGeo }
      : payload;

    await onSave(inlcludedData);
    navigateBack();
  }

  const onSave = async (payload: ProfileInterface) => {
    try {
      updateCandidate(payload);
    } catch (error) {
      if (error instanceof Error) errorHandler({ error, customMessage: "Something went wrong updating location" });
    }
  };

  if (step === 1) return (
    <RoleType onSelectRoleType={onSelectRoleType} isEditing={isEditing} />
  );

  return (
    <PreferredLocation onSave={onSelectLocation} isEditing={isEditing} />
  );
}

export default LocationInfo;