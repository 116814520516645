import React from 'react';
import infoIcon from "../../resources/graphics/info_icon.svg";
import "./IconTitle.css";
import ReactTooltip from 'react-tooltip';

interface IIconTitle {
  id: string;
  title: string;
}

const IconTitle = ({ id, title }: IIconTitle) => (
  <div className="form-control__title">
    <label htmlFor={id}>{title}</label>
    <img src={infoIcon} alt={id} data-tip="Any information you provide here is voluntary and will not impact any employment decisions" />
    <ReactTooltip
      backgroundColor="#052D60"
      className="tooltip"
      overridePosition={({ left, top }, _e, _t, node) => ({
        top,
        left: typeof node === 'string' ? left : Math.max(left, 0),
      })}
    />
  </div>
);

export default IconTitle;