import { Route, Routes } from "react-router-dom";
import Interviews from "../../../components/pages/Interviews/Interviews";
import CompanyPageHolder from '../../../components/pages/Company/CompanyPage';
import Applied from "../interviews/applied";
import NotFound from "../NotFound";
import ScheduleInterview from "../interviews/ScheduleInterview";
import OfferPage from "../../../components/pages/Offer/OfferPage";

const InterviewRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Interviews />} />
      <Route path="/schedule" element={<ScheduleInterview />} />
      <Route path="/offer/:reqId" element={<OfferPage />} />
      <Route path="/submission" element={<OfferPage />} />
      <Route path="/company/:companyId" element={<CompanyPageHolder />} />
      <Route path="/applied" element={<Applied />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default InterviewRouter;
