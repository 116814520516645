import "./Blog.css";

import BlogArticle from "./BlogArticle";
import Button from "../../../shared/components/FormElements/Button";

const DUMMY_ARTICLE = [
  {
    id: "203948902384234",
    link: "https://careerkarma.com/blog/a-review-of-vendition-tech-sales-apprenticeship-program/?utm_term=LI%20Career%20Karma%20Post%203-3-22&utm_campaign=2022%20LinkedIn%20Social%20Posts&utm_content=199728528&utm_medium=social&utm_source=linkedin&hss_channel=lcp-18723495",
    image: "https://careerkarma.com/blog/wp-content/uploads/2022/02/Vendition.png",
    title: "How does Vendition work?",
    textPreview: "", // Vendition is a training program that serves as a launchpad for aspiring tech sales professionals.",
  },
  {
    id: "2349829039502394",
    link: "https://vendition.com/reviews/",
    image: "https://vendition.com/wp-content/uploads/2020/10/reviews_header.jpg",
    title: "Vendition Reviews",
    textPreview: "", // Apprentice Reviews",
  },
  {
    id: "1309482350924590235",
    link: "https://www.pipedrive.com/en/blog/sales-development-rep",
    image: "https://www-cms.pipedriveassets.com/blog-assets/sales-development-rep.png",
    title: "What is a SDR?",
    textPreview: "", // Sales development reps (SDR) focus on outbound sales, through various points of contact. Learn what SDR sales skills you should bring to your sales team",
  },
];

const Blog = (): JSX.Element => {
  const handleClick = () => {
    window.open("https://vendition.com/blog/", "_blank");
  };

  return (
    <section className="section-container">
      <h1>From our Blog</h1>
      <div className="blog-container__content">
        {DUMMY_ARTICLE.map((article) => (
          <BlogArticle
            key={article.id}
            link={article.link}
            title={article.title}
            textPreview={article.textPreview}
            image={article.image}
          />
        ))}
      </div>
      <Button size="big" onClick={handleClick}>
        See All Stories
      </Button>
    </section>
  );
};

export default Blog;
