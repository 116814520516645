import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { RadioButton } from "@vendition/vendition-frontend";
import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";

import { IBasicRadio } from "../PersonalSituation";
import { AuthContext } from "../context/auth-context";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";
import { errorHandler } from "../../../shared/util/helpers";
import { useResume } from "../../../shared/hooks/useResume";

const DUMMY_LEVELS = [
  {
    id: "no_education",
    value: "No formal education",
  },
  {
    id: "hsd",
    value: "High School diploma or GED",
  },
  { id: "nd", value: "Some college, but no degree" },
  { id: "college", value: "College" },
];

const EducationLevel = (): JSX.Element => {
  const navigate = useNavigate();
  const { email, thorUser } = useContext(AuthContext);
  const { parsedUser } = useResume();
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const { educationLevel } = thorUser?.educationLevel ? thorUser : (parsedUser || {});
  const optionSelected = DUMMY_LEVELS.find((option) => option.value === educationLevel);
  const [selected, setSelected] = useState<IBasicRadio>(optionSelected || DUMMY_LEVELS[0]);
  const { mutate: updateCandidate } = useUpdateCandidate();

  const submitHandler = async () => {
    const requestObject = {
      educationLevel: selected.value,
    };
    try {
      await updateCandidate(requestObject);
      // Manage static redirection
      if (["High School diploma or GED", "No formal education"].includes(selected.value)) {
        if(parsedUser?.employment?.length || thorUser!.employment?.length) {
          navigate("/profile/work-history");
        } else {
          navigate("/profile/employment");
        }
      }
      else {
        if (parsedUser?.education?.length || thorUser!.education?.length) {
          navigate("/profile/education/history");
        } else {
          navigate("/profile/education/");
        }
      }
    }catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
        });
      }
    }
  };

  useEffect(() => {
    if (!email) navigate("/");
  }, [email, navigate]);

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader progress={44} backNavigation="/profile/level" />
      <div className="signup-form__container">
        <h1>What is your highest level of education?</h1>
        <form className="form-container" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
          {DUMMY_LEVELS.map((situation) => (
            <RadioButton
              name={`rd_${situation.id}`}
              key={situation.id}
              selected={selected}
              value={situation.id}
              handleChange={setSelected}
              text={situation.value}
              size="big"
            />
          ))}
          <div className="edit-form__button-container">
            <Button size={"whole"}>Next</Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default EducationLevel;
