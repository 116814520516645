import { Suspense, useContext, useEffect } from "react";
import { v4 as uuid } from "uuid";
import UserDataCategory from "../components/profile/UserDataCategory";
import WorkHistoryItem from "../components/work/WorkHistoryItem";
import { ReactComponent as Plus } from "../../resources/graphics/plus.svg";
import { ReactComponent as Pencil } from "../../resources/graphics/pencil.svg";

import EducationSummary from "../components/education/EducationSummary";
import Loading from "../../shared/components/UIElements/Loading";
import { IEducationObject } from "../components/education/EducationUpdate";
import { AuthContext } from "../components/context/auth-context";
import { useNavigate } from "react-router-dom";
import { IEmploymentObject } from "../components/work/WorkExperienceUpdate";
import { useResume } from "../../shared/hooks/useResume";
import { IResumeResponse } from "../../shared/hooks/useResume/interfaces";
import { isProfileCompleted } from "../components/profile/ProfileRedirection";
import Video from "../components/profile/videoThumbnail";
import { useCandidate } from "../../components/Candidate/hooks/useCandidate";

import { ProfileCard, ProfileCategoryVideo, ProfileContainer, ProfileVideoContainer } from "../components/profile/ProfilePage.styles";
import { IGeo } from "../components/location_info";

export interface BasicUserData {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  jobSearchStatus: string;
  profilePicture?: any;
  phone: string;
  resumeURL?: any;
  linkedInProfile: string;
  educationLevel?: string;
  education?: Array<IEducationObject>;
  traits?: Array<string>;
  preferredLocation?: Array<string>;
  employmentExperience?: string;
  employment?: Array<IEmploymentObject>;
  resume?: IResumeResponse;
  video1?: string;
  video2?: string;
  canvasID?: string;
  gender: string;
  pronouns: string;
  workAuthorized: string;
  preferredWorkplaceType: Array<string>;
  ethnicity: string;
  veteran: string;
  sebResults?: string;
  preferredIndustry?: Array<string>;
  preferredJobTitle?: Array<string> | Array<object>;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
  preferredLocationGeo?: Array<IGeo>;
}

const ProfilePage = () => {
  const { email, isLoggedIn, thorUser } = useContext(AuthContext);
  const { candidate } = useCandidate();

  const { clearStoredData } = useResume();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
    else if (thorUser && isProfileCompleted(thorUser)) {
      clearStoredData();
    }
  }, [isLoggedIn, navigate, clearStoredData, thorUser]);

  if (!thorUser) return <div />;

  const currentValue = `${thorUser.city}, ${thorUser.state} ${thorUser.zip}, ${thorUser.country}`;

  return (
    <ProfileContainer>
      <ProfileCard>
        <h1>Your Profile</h1>
        <Suspense fallback={<Loading />}>
          <UserDataCategory
            hasImage={true}
            image={candidate?.profilePicture}
            info={[`${thorUser.firstName} ${thorUser.lastName}`, email ?? "", thorUser.phone]}
            title=""
            editLink="/profile/basics"
            payload={{
              profilePicture: candidate?.profilePicture,
              firstName: thorUser.firstName,
              lastName: thorUser.lastName,
              phone: thorUser.phone,
            }}
            placeholder="Please fill up your information"
          />
        </Suspense>
        <UserDataCategory
          hasImage={false}
          title="Resume/CV"
          info={thorUser.resume || thorUser.resumeURL ? ["Resume uploaded"] : []}
          editLink="/profile/resume"
          payload={{ email: thorUser.email }}
          placeholder="Please upload your resume first"
        />
        <UserDataCategory
          hasImage={false}
          title="LinkedIn Profile"
          info={thorUser.linkedInProfile ? [thorUser.linkedInProfile] : []}
          editLink="/profile/linkedIn"
          payload={{ profile: thorUser.linkedInProfile }}
          placeholder="Please update your LinkedIn profile"
        />
        <UserDataCategory
          hasImage={false}
          title="Job search status:"
          info={[thorUser.jobSearchStatus]}
          editLink="/profile/job-status"
          payload={{ status: thorUser.jobSearchStatus }}
          placeholder="Please fill up your job status information"
        />
      </ProfileCard>
      <ProfileCard>
        <UserDataCategory
          hasImage={false}
          title="Job Titles"
          info={thorUser.preferredJobTitle?.map(el => (typeof el === "string" ? el : el.title)) || []}
          editLink="/profile/job-title"
          payload={{ preferredJobTitle: thorUser.preferredJobTitle }}
          placeholder="Please let us know the roles of your interest"
        />
        <UserDataCategory
          hasImage={false}
          title="Industries"
          info={thorUser.preferredIndustry?.map((el) => el.replace(/\/ |\//, ", ")) || []}
          editLink="/profile/industry"
          payload={{ preferredIndustry: thorUser.preferredIndustry }}
          placeholder="Please let us know the industries you want to work in"
        />
        <UserDataCategory
          hasImage={false}
          title="Open to working at"
          info={thorUser.preferredLocation?.map((el) => el.replace(/\/ |\//, ", ")) || []}
          editLink="/profile/location"
          payload={{
            preferredLocation: thorUser.preferredLocation,
            preferredLocationGeo: thorUser.preferredLocationGeo,
            preferredWorkplaceType: thorUser.preferredWorkplaceType,
            workAuthorized: thorUser.workAuthorized,
          }}
          placeholder="Please let us know where you want to work"
        />
        <UserDataCategory
          hasImage={false}
          title="Current Location"
          info={[ currentValue ]}
          editLink="/profile/current-location"
          payload={{ currentValue }}
          placeholder="Please let us know where are you currently located"
        />
      </ProfileCard>
      <ProfileCard>
        <div className="profile__basic-info__category">
          <h2>My Videos</h2>
          <div
            className="clickable"
            onClick={() =>
              navigate("/profile/video/edit", {
                state: {
                  videos: {
                    video1: thorUser.video1,
                    video2: thorUser.video2,
                  },
                  isEditing: true,
                },
              })
            }
          >
            <Pencil />
          </div>
        </div>
        <ProfileCategoryVideo>
          <ProfileVideoContainer>
            <Video src={thorUser.video1} />
          </ProfileVideoContainer>
          <ProfileVideoContainer>
            <Video src={thorUser.video2} />
          </ProfileVideoContainer>
        </ProfileCategoryVideo>
      </ProfileCard>
      <ProfileCard>
        <div className="profile__basic-info__category">
          <h2>Work History</h2>
          <div
            className="profile__basic-info__category-icon-container clickable"
            onClick={() =>
              navigate("/profile/employment", {
                state: { history: {}, historyList: thorUser.employment, isEditing: true },
              })
            }
          >
            <Plus width={11} />
          </div>
        </div>
        {thorUser.employment &&
          thorUser.employment.map((item, index) => (
            <WorkHistoryItem
              index={index}
              key={item.id || uuid()}
              historyItem={item}
              historyList={thorUser.employment}
              isEditing
            />
          ))}
      </ProfileCard>
      <ProfileCard>
        <div className="profile__basic-info__category clickable">
          <h2>Education</h2>
          <div
            className="profile__basic-info__category-icon-container clickable"
            onClick={() =>
              navigate("/profile/education", {
                state: {
                  educationList: thorUser.education || [],
                  education: {},
                  isEditing: true,
                },
              })
            }
          >
            <Plus width={11} />
          </div>
        </div>
        <EducationSummary education={thorUser.education || []} isEditing />
      </ProfileCard>
    </ProfileContainer>
  );
};

export default ProfilePage;
