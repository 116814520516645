import React, { useEffect, useState } from "react";

import { Card } from "@vendition/vendition-frontend";

import { ReactComponent as Briefcase } from "../../../resources/graphics/briefcase.svg";

import { useRequisitions } from "../../Requisitions/hooks/useRequisitions";
import { IInterview } from "./Interviews.types";
import { filterRequisitions, removePendingWhenExistsOnUpcoming } from "./InterviewsHelper";

import "./Interviews.css";
import CompanySection from "./CompanySection";
import { usePrefetchCompany } from "../Company/useCompany";
import ErrorPage from "../../../users/pages/ErrorPage";

const Interviews = () => {
  const [interviews, setInterviews] = useState<Array<IInterview>>([]);

  const { requisitions, error } = useRequisitions();

  useEffect(() => {
    if (requisitions) {
      setInterviews(filterRequisitions(requisitions));
    }
  }, [requisitions]);

  const interviewOptions = interviews.reduce((acc: any, interview) => {
    switch (interview.status) {
      case "Match": return { ...acc, matches: [...acc.matches, interview] };
      case "InterviewRequest": return { ...acc, interviewRequests: [...acc.interviewRequests, interview] };
      case "Pending": return { ...acc, pendingInterviews: [...acc.pendingInterviews, interview] };
      case "Upcoming": return { ...acc, upcomingInterviews: [...acc.upcomingInterviews, interview] };
      default: return acc;
    }
  }, { matches: [], interviewRequests: [], pendingInterviews: [], upcomingInterviews: [] }
  );

  let { matches, interviewRequests, pendingInterviews, upcomingInterviews } = interviewOptions;
  pendingInterviews = removePendingWhenExistsOnUpcoming(pendingInterviews, upcomingInterviews);

  usePrefetchCompany([
    ...matches.map(({ company: { id } }) => id),
    ...interviewRequests.map(({ company: { id } }) => id),
    ...upcomingInterviews.map(({ company: { id } }) => id),
    ...pendingInterviews.map(({ company: { id } }) => id),
  ]);

  if (error) return <ErrorPage />;

  if (!interviews.length) return (
    <div className="no-interview-container">
      <Card className="no-interview-container__content">
        <div className="logo">
          <Briefcase width={45} height={40} fill="white" />
        </div>
        <h1>Interviews</h1>
        <h3>You don't have interviews yet. We'll let you know when you get an interview request!</h3>
      </Card>
    </div>
  );

  return (
    <div className="interview-container">
      <CompanySection title="Matches" list={matches} />
      <CompanySection title="Interview Requests" list={interviewRequests} />
      <CompanySection title="Upcoming Interviews" list={upcomingInterviews} />
      <CompanySection title="Pending Interviews" list={pendingInterviews} />
    </div>
  )
};

export default Interviews;
