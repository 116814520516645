import { useContext, useState } from "react";
import axios from 'axios';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Contact, IAppointment, IRequisition, ISubmission } from "@vendition/vendition-frontend";

import { queryKeys } from "../../../../react-query/constants";
import { addMinutes } from "../../../../shared/util/helpers";
import { AuthContext } from "../../context/auth-context";

interface IPostInterview {
  requisition: IRequisition;
  submission: ISubmission;
  date: Date;
  interviewDuration: number;
  companyName?: string;
};

const postInterview = async (getToken, data) => {
  const { requisition, submission, date, interviewDuration, companyName } = data as IPostInterview;
  const token = await getToken();

  const createAppointmentTitle = () => {
    let title = `Job #${requisition.id} ${requisition.title}`;

    if (submission.sendingUser) {
      title += ` - ${submission.sendingUser.firstName + " " + submission.sendingUser.lastName}`;
    }

    title += `: ${companyName || ""} Phone Screen`;
    
    return title;
  }

  const payload: IAppointment = {
    subject: createAppointmentTitle(),
    clientContactId: requisition.clientContactId,
    communicationMethod: "Phone",
    lastUpdateSource: "WEBAPP",
    candidateId: submission.candidateId,
    ownerId: requisition.ownerId,
    requisition: {
      id: requisition.id,
      title: requisition.title,
    },
    requisitionId: requisition.id,
    clientContact: requisition.clientContact,
    candidate: {
      ...submission.candidate,
      id: submission.candidateId,
    }  as Contact,
    owner: submission.sendingUser || ({} as Contact),
    type: "Phone Screen",
    dateBegin: date.getTime(),
    dateEnd: addMinutes(date, interviewDuration).getTime(),
  };

  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  };

  const [updatedAppointment, updatedSubmission] = await Promise.all([
    axios.put(`${process.env.REACT_APP_AWS_DEV_URL}/appointment`, payload, headers),
    axios.patch(`${process.env.REACT_APP_AWS_DEV_URL}/submission/${submission.id}`, { status: "Phone Screen" }, headers),
  ]);

  const response = {
    appointment: updatedAppointment.data,
    submission: updatedSubmission.data,
  };

  return response;
};

export const useScheduleInterview = () => {
  const [interviewScheduled, setInterviewScheduled] = useState(false);
  const { getToken } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data: IPostInterview) => postInterview(getToken, data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([queryKeys.requisitions]);
        queryClient.refetchQueries([queryKeys.client]);
        setInterviewScheduled(true);
      }
    }
  );

  return {
    ...mutation,
    interviewScheduled
  }
};