import { IBasicRadio } from "../PersonalSituation";
import { useForm } from "../../../shared/hooks/form-hook";
import React, { useContext, useEffect, useState } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";
import Input from "../../../shared/components/FormElements/Input";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Card, RadioButton } from "@vendition/vendition-frontend";
import { VALIDATOR_NUMERIC, VALIDATOR_REQUIRE } from "../../../shared/util/validators";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";

import moment from "moment";
import { AuthContext } from "../context/auth-context";
import { BasicUserData } from "../../pages/ProfilePage";
import { errorHandler } from "../../../shared/util/helpers";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import { DUMMY_MONTHS, DUMMY_YEARS, MONTHS } from "../../../shared/util/constants";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import "./Education.css";

export interface IEducationObject {
  id?: number | string;
  school: string;
  status: string;
  startDate: number;
  endDate: number;
  degree: string;
  major: string;
  minor: string;
  gpa: string;
  comments: string;
  isValid?: boolean;
}

export const statusMapping = (status: string) => {
  switch (status) {
    case "Graduated":
      return "graduated";
    case "In Progress":
      return "progress";
    case "Incomplete":
      return "incomplete";
    default:
      return "graduated";
  }
};

export const getDateSelectObject = (
  dateToSeek: string,
  arr: { value: string; label: string }[]
): { value: string; label: string } => {
  const found = arr.filter((month) => month.label === dateToSeek);
  return found.length > 0 ? found[0] : ({} as { value: string; label: string });
};

const EducationUpdate = (): JSX.Element => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { email, thorUser, setThorUser, isOnboarding } = useContext(AuthContext);
  const { isEditing = false, education = {} as IEducationObject, educationList = [] } = location.state || thorUser || {};
  const { mutate: updateCandidate, status: updateStatus } = useUpdateCandidate();
  const { degree, school, startDate, endDate, major, minor, gpa, comments, status } = education;
  const sDate = startDate ? moment(startDate) : moment();
  const eDate = endDate ? moment(endDate) : moment();

  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const [selected, setSelected] = useState<IBasicRadio>(
    status
      ? { id: statusMapping(status), value: status }
      : {
          id: "graduated",
          value: "Graduated",
        }
  );
  const [selectedDegree, setSelectedDegree] = useState<IBasicRadio>(
    degree
      ? {
          id: degree.toLowerCase(),
          value: degree,
        }
      : {
          id: "associates",
          value: "Associates",
        }
  );

  const [formState, inputHandler] = useForm(
    {
      schoolName: {
        value: school || "",
        isValid: !!school,
      },
      slMonthStart: {
        value: sDate.format("MMMM"),
        isValid: true,
      },
      slYearStart: {
        value: sDate.format("YYYY"),
        isValid: true,
      },
      slMonthEnd: {
        value: eDate.format("MMMM"),
        isValid: true,
      },
      slYearEnd: {
        value: eDate.format("YYYY"),
        isValid: true,
      },
      major: {
        value: major || "",
        isValid: !!major,
      },
      minor: {
        value: minor || "",
        isValid: !!minor,
      },
      gpa: {
        value: gpa || "",
        isValid: !!gpa,
      },
      extraCurricular: {
        value: comments || "",
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    if (!email) navigate("/");
  }, [email, navigate]);

  const isValidDateRange = (): boolean =>
    moment({
      year: +formState.inputs.slYearEnd.value,
      month: MONTHS.indexOf(formState.inputs.slMonthEnd.value),
      day: 1,
    }).isAfter(
      moment({
        year: +formState.inputs.slYearStart.value,
        month: MONTHS.indexOf(formState.inputs.slMonthStart.value),
        day: 1,
      })
    );

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidDateRange()) {
      setToast({
        message: "Please make sure the end date is after the start date",
        title: "",
        type: "Error",
      });
      return;
    }

    let allEdRecords = [...(educationList as IEducationObject[])];

    const { schoolName, slMonthStart, slMonthEnd, slYearStart, slYearEnd, major, minor, gpa, extraCurricular } = formState.inputs;

    const newEdRecord: IEducationObject = {
      school: schoolName.value,
      status: selected.value,
      startDate: +moment({
        year: +slYearStart.value,
        month: MONTHS.indexOf(slMonthStart.value),
        day: 1,
      }),
      endDate: +moment({
        year: +slYearEnd.value,
        month: MONTHS.indexOf(slMonthEnd.value),
        day: 1,
      }),
      degree: selectedDegree.value,
      major: major.value,
      minor: minor.value,
      gpa: "" + gpa.value,
      comments: extraCurricular.value,
    };

    if (params.educationId !== undefined) {
      allEdRecords[+params.educationId] = newEdRecord;
    } else {
      allEdRecords.push(newEdRecord);
    }

    if (isEditing) {
      const requestObject = {
        educationLevel: "College",
        education: allEdRecords,
      };

      try {
        updateCandidate(requestObject);
        if (updateStatus === "success") {
          navigate("/profile/page");
        } else {
          setToast({
            type: "Error",
            title: "",
            message: "Your information was not updated",
          });
          navigate(-1);
        }
      } catch (error) {
        if (error instanceof Error) {
          setToast({
            type: "Error",
            title: "",
            message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
          });
        }
      }
    } else {
      setThorUser({ ...(thorUser as BasicUserData), education: allEdRecords });
      navigate("/profile/education/history");
    }
  };

  const handleSelectChange = (
    newValue: SingleValue<{
      value: string;
      label: string;
    }>,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    inputHandler(actionMeta.name || "", newValue?.label || "", true);
  };

  const backNav = educationList.length ? "/profile/education/history" : "/profile/level";

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader progress={44} backNavigation={backNav} />
      <div className="signup-form__container" style={{ width: "100%" }}>
        <h1>{isEditing ? "Edit" : "Your"} Education</h1>
        <form className="form-container" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
          <Input
            id="schoolName"
            element="input"
            type={"text"}
            label="School Name"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a school name"
            inputCallback={inputHandler}
            placeholder="Enter your school name"
            initialValue={school || ""}
            initialIsValid={!!school}
          />
          <label>Status</label>
          <RadioButton
            name={`rd_status_graduated`}
            selected={selected}
            value="graduated"
            handleChange={setSelected}
            text="Graduated"
            size="small"
          />
          <RadioButton
            name={`rd_status_progress`}
            selected={selected}
            value="progress"
            handleChange={setSelected}
            text="In Progress"
            size="small"
          />
          <RadioButton
            name={`rd_status_incomplete`}
            selected={selected}
            value="incomplete"
            handleChange={setSelected}
            text="Incomplete"
            size="small"
          />
          <div className="work-form__select-container">
            <label htmlFor="slCities">
              Start Date<span>*</span>
            </label>
            <div className="work-form__select-group">
              <Select
                id="slMonthStart"
                name={"slMonthStart"}
                options={DUMMY_MONTHS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(sDate.format("MMMM"), DUMMY_MONTHS)}
              />
              <Select
                id="slYearStart"
                name={"slYearStart"}
                options={DUMMY_YEARS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(sDate.format("YYYY"), DUMMY_YEARS)}
              />
            </div>
          </div>
          <div className="work-form__select-container">
            <label htmlFor="slCities">
              {selected.id === "progress" ? "Estimated End Date" : "End Date"}
              <span>*</span>
            </label>
            <div className="work-form__select-group">
              <Select
                id="slMonthEnd"
                name={"slMonthEnd"}
                options={DUMMY_MONTHS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(eDate.format("MMMM"), DUMMY_MONTHS)}
              />
              <Select
                id="slYearEnd"
                name={"slYearEnd"}
                options={DUMMY_YEARS}
                onChange={handleSelectChange}
                defaultValue={getDateSelectObject(eDate.format("YYYY"), DUMMY_YEARS)}
              />
            </div>
          </div>
          <label>Degree</label>
          <RadioButton
            name={`rd_degree_associates`}
            selected={selectedDegree}
            value="associates"
            handleChange={setSelectedDegree}
            text="Associates"
            size="small"
          />
          <RadioButton
            name={`rd_degree_bachelors`}
            selected={selectedDegree}
            value="bachelors"
            handleChange={setSelectedDegree}
            text="Bachelors"
            size="small"
          />
          <RadioButton
            name={`rd_degree_masters`}
            selected={selectedDegree}
            value="masters"
            handleChange={setSelectedDegree}
            text="Masters"
            size="small"
          />
          <RadioButton
            name={`rd_degree_doctorate`}
            selected={selectedDegree}
            value="doctorate"
            handleChange={setSelectedDegree}
            text="Doctorate"
            size="small"
          />
          <RadioButton
            name={`rd_degree_none`}
            selected={selectedDegree}
            value="none"
            handleChange={setSelectedDegree}
            text="None of the above"
            size="small"
          />
          <Input
            id="major"
            element="input"
            type={"text"}
            label="Major"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter your major degree"
            inputCallback={inputHandler}
            placeholder="Enter your major"
            initialValue={major || ""}
            initialIsValid={!!major}
          />
          <Input
            id="minor"
            element="input"
            type={"text"}
            label="Minor"
            validators={[]}
            initialIsValid={true}
            errorText="Please enter your minor degree"
            inputCallback={inputHandler}
            placeholder="Enter your minor (optional)"
            initialValue={minor || ""}
          />
          <Input
            id="gpa"
            element="input"
            type={"text"}
            label="GPA"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMERIC()]}
            errorText="Please enter your GPA"
            inputCallback={inputHandler}
            placeholder="Enter your GPA"
            initialValue={gpa || ""}
            initialIsValid={!!gpa}
          />
          <Input
            id="extraCurricular"
            element="textarea"
            type={"text"}
            label="Extracurricular activities"
            validators={[]}
            errorText="Please enter a valid input"
            inputCallback={inputHandler}
            placeholder="List your extracurricular activities"
            initialValue={comments || ""}
            initialIsValid={true}
            rows={6}
          />
          <div className="edit-form__button-container">
            {isOnboarding ? (
              <Button disabled={!formState.isValid}>Next</Button>
            ) : (
              <>
                <Button outline onClick={() => (isEditing ? navigate("/profile/page") : navigate(-1))}>
                  Cancel
                </Button>
                <Button disabled={!formState.isValid}>Save</Button>
              </>
            )}
          </div>
        </form>
      </div>
    </Card>
  );
};

export default EducationUpdate;
