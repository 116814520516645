import React from "react";
import { NavLink } from "react-router-dom";
import "./NavLinks.css";

export interface NavLinkItem {
  path: string;
  label: string;
  clickHandler?: (event: React.MouseEvent) => void;
  icon?: string;
  external?: boolean;
}

interface INavLinksProps {
  links: Array<NavLinkItem>;
}

const NavLinks = ({ links }: INavLinksProps) => {
  return (
    <ul className="nav-links">
      {links.map((link, index) => (
        <li key={`link_${index}`}>
          {link.external ? (
          <a href={link.path} target="_blank" rel="noreferrer">
            {link.icon && <img src={link.icon} alt={link.label} />}
            {link.label}
          </a>
          ) : (
          <NavLink to={link.path} onClick={link.clickHandler} target={link.external ? "_blank" : undefined}>
            {link.icon && <img src={link.icon} alt={link.label} />}
            {link.label}
          </NavLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavLinks;
