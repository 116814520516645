export const quiz = [
  "Follow a schedule",
  "Stop myself from worrying",
  "Win arguments",
  "Take charge of a situation",
  "Make plans to achieve a goal",
  "Make sense of complex information",
  "Work with people toward a shared goal",
  "Try new things",
  "Win debates with other people",
  "Meet new people",
  "Plan out my time",
  "Try something that's unfamiliar",
  "Lead a group of people",
  "Settle down when I'm feeling nervous",
  "Stay positive when something bad happens",
  "Put things back in their proper place",
  "Invent things",
  "Keep a positive attitude",
  "Use my imagination",
  "Keep working until a task is finished",
  "Repeat a task consistently",
  "Think before acting",
  "Do tasks that are routine or repetitive",
  "Speak up when I disagree with others",
  "Get things done by myself",
  "Make careful decisions",
  "Look on the bright side of things",
  "Make decisions on my own",
  "Work toward my goals",
  "Concentrate on a task",
  "Start a conversation",
  "Organize my personal spaces",
  "Do the same task over and over again",
  "Keep things neat and tidy",
  "Set clear goals",
  "Cooperate with other people",
  "Assert myself as a leader",
  "Calm down when I'm feeling anxious",
  "Come up with new ideas",
  "Talk to people",
  "Find logical solutions to problems",
  "Adapt to change",
  "Do things on my own",
  "Think things through carefully",
  "Work as part of a group",
  "Show up for things on time",
  "Learn things quickly",
  "Work efficiently, without wasting time"
];