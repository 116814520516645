import React from 'react';
import { Moment } from 'moment';
import DateTimeSelector from './DateTimeSelector';
import { ExternaLink } from './Styles';
import { ExternalLinkWrapper } from './ExternalInterview.styles';


export interface IInterviewLink {
  companyName: string;
  interviewSchedulingLink: string;
  date: Moment;
  setDate: (date: Moment) => void;
  handleSubmit: () => void;
  isLoading: boolean;
  interviewDuration?: number;
}

const ExternalLink = ({ companyName, interviewSchedulingLink, date, setDate, handleSubmit, isLoading }: IInterviewLink) => {
  return (
    <ExternalLinkWrapper>
      <div>
        <p>By clicking the link below, you will be redirected to a third-party scheduling tool to schedule an interview with {companyName}.</p>
        <ExternaLink href={interviewSchedulingLink} target="_blank" rel="noreferrer">Schedule Interview</ExternaLink>
      </div>
      <p>Once you have scheduled the interview on the external site, please return to this page to confirm the date and time.</p>
      <DateTimeSelector
        date={date}
        setDate={setDate}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </ExternalLinkWrapper>
  )
};

export default ExternalLink;