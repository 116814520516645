import { useCallback, useEffect, useReducer, useState } from "react";

export interface IOptionSelection {
  value: string;
  label: string;
  isSelected: boolean;
  data?: any;
}

interface IOptionsList {
  [key: string]: { value: string; isSelected: boolean };
}

export interface IOptionsState {
  options: Array<IOptionSelection>;
}

interface IReducerAction {
  type: string;
  option?: string;
  value?: string;
  isSelected: boolean;
  options?: IOptionsList;
  data?: any;
}

export type OptionHandler = (value: string, label: string, isSelected: boolean, data?: any) => void;

const optionsReducer = (state: IOptionsState, action: IReducerAction) => {
  switch (action.type) {
    case "OPTION_CHANGE":
      const newOptions: Array<IOptionSelection> = [];
      state.options.forEach((o) => {
        newOptions.push({
          ...o,
          isSelected: o.value === action.option ? action.isSelected : o.isSelected,
        });
      });
      return { options: newOptions };
    case "ADD_OPTION":
      return state.options.filter((option) => option.value === action.option).length > 0
        ? state
        : {
            ...state,
            options: [
              ...state.options,
              {
                value: action.option || "",
                label: action.value || "",
                isSelected: action.isSelected,
                data: action.data,
              },
            ],
          };
    default:
      return state;
  }
};

export const useOptions = (
  initialOptions: IOptionsState
): [IOptionsState, OptionHandler, IOptionSelection[], typeof addOptionHandler] => {
  const [optionsForm, dispatch] = useReducer(optionsReducer, initialOptions);
  const [selectedOptions, setSelectedOptions] = useState<IOptionSelection[]>([]);

  const optionHandler = useCallback((value: string, label: string, isSelected: boolean, data?: any) => {
    dispatch({
      type: "OPTION_CHANGE",
      option: value,
      value: label,
      isSelected,
      data,
    });
  }, []);

  const addOptionHandler = useCallback((value: string, label: string, isSelected = true, data?: any) => {
    dispatch({ type: "ADD_OPTION", option: value, value: label, isSelected, data });
  }, []);

  useEffect(() => {
    setSelectedOptions(optionsForm.options.filter((option) => option.isSelected === true && option.value !== "Other"));
  }, [optionsForm.options]);

  return [optionsForm, optionHandler, selectedOptions, addOptionHandler];
};
