import { createContext, Dispatch, SetStateAction } from "react";
import { BasicUserData } from "../../pages/ProfilePage";

export interface IAuthContext {
  logout: () => void;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  token: string | null;
  email: string | null;
  userId: string | null;
  isOnboarding: boolean;
  thorUser: BasicUserData | null;
  setIsOnboarding: Dispatch<SetStateAction<boolean>>;
  loginToThor: (uid: string, value: string, expirationDate: number, email: string) => void;
  assessLoggedInState: (onboarding: boolean, candidateEmail?: string) => Promise<void>;
  setThorUser: Dispatch<SetStateAction<BasicUserData | null>>;
  getToken: () => Promise<string>;
}

export const AuthContext = createContext<IAuthContext>({
  userId: null,
  token: null,
  email: null,
  thorUser: null,
  logout: () => {},
  isLoggingIn: false,
  isLoggedIn: false,
  isOnboarding: false,
  loginToThor: () => {},
  setThorUser: () => {},
  setIsOnboarding: () => {},
  assessLoggedInState: (onboarding: boolean) => new Promise(() => {}),
  getToken: () => new Promise(() => {}),
});
