import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  `

export const ImgContainer = styled.div`
  position: relative;
  margin: 0 auto;
  cursor: pointer;
  ${props => css`
    width: ${props.width}px;
    height: ${props.width}px;
  `}
  `

export const ImgMask = styled.div`
  background-color: #1f2240;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  width: inherit;
  height: inherit;
  
  & img {
    margin: auto;
    ${
      props => props.isUserIcon ? css`
        width: 45%;
        border-radius: 0;
      ` : css`
        width: inherit;
        height: inherit;
        object-fit: cover;
        border-radius: 50%;
      `
    }
  }
`;

export const ActionBtn = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #0A9AFF;
  border:1px solid white;
  position: absolute;
  display: flex;justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;

  & img {
    width: 40%;
    height: 40%;
    display: block;
    background-color: transparent;
  }
`
