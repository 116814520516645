import { useCallback, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProfileCompletion } from "../dashboard/Dashboard/Dashboard";
import { BasicUserData } from "../../pages/ProfilePage";
import { AuthContext } from "../context/auth-context";
import { useResume } from "../../../shared/hooks/useResume";
import axios from "axios";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import ErrorPage from "../../pages/ErrorPage";
import { errorHandler } from "../../../shared/util/helpers";
import { useCandidate } from "../../../components/Candidate/hooks/useCandidate";

export const isProfileCompleted = (userProfile: BasicUserData, parsedUser?: BasicUserData): ProfileCompletion => {
  if (!userProfile)
    return { isComplete: false, section: "" };

  if (userProfile.email) {
    if (!userProfile.firstName || !userProfile.lastName || !userProfile.phone) return { isComplete: false, section: "basics" };

    if (!userProfile.jobSearchStatus) return { isComplete: false, section: "job-status" };

    if (!userProfile.preferredIndustry || userProfile.preferredIndustry?.length <= 0) return { isComplete: false, section: "industry" };

    if (!userProfile.preferredJobTitle || userProfile.preferredJobTitle?.length <= 0) return { isComplete: false, section: "job-title" };

    if (!userProfile.educationLevel) {
      if (parsedUser && parsedUser.education && parsedUser.education.length > 0)
        return {
          isComplete: false,
          section: "education/history",
          payload: { parsedEducation: parsedUser.education },
        };
      else return { isComplete: false, section: "level" };
    } else if (
      userProfile.educationLevel !== "No formal education" &&
      userProfile.educationLevel !== "High School diploma or GED"
    ) {
      if (
        parsedUser &&
        parsedUser.education &&
        parsedUser.education.length > 0 &&
        userProfile &&
        (!userProfile.education || userProfile.education.length <= 0)
      )
        return {
          isComplete: false,
          section: "education/history",
          payload: { parsedEducation: parsedUser.education },
        };
      else if (!userProfile.education || userProfile.education?.length <= 0) return { isComplete: false, section: "education" };
    }

    if (userProfile.employmentExperience === "true" || !userProfile.employmentExperience) {
      if (!userProfile.employment || userProfile.employment.length <= 0) {
        if (parsedUser && parsedUser.employment && parsedUser.employment.length > 0)
          return {
            isComplete: false,
            section: "work-history",
            payload: { parsedHistory: parsedUser.employment },
          };
        return { isComplete: false, section: "employment" };
      }
    }
    if (!userProfile.preferredLocation || userProfile.preferredLocation?.length <= 0)
      return { isComplete: false, section: "location" };

    if (!userProfile.video1) return { isComplete: false, section: "video" };

    if (!userProfile.video2) return { isComplete: false, section: "video/question" };

    return { isComplete: true, section: "" };
  }
  return { isComplete: false, section: "" };
};

const ProfileRedirection = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { email, thorUser, setThorUser } = useContext(AuthContext);
  const { parsedUser } = useResume();

  const { candidate, isLoading, refetch, error } = useCandidate();

  // This is the main routing function, it tests your profile to see what
  // information, considered to have a complete profile, is missing and
  // redirects you to fill that gap.
  const reloadData = useCallback(async () => {
    if (candidate && candidate.email && parsedUser && !isLoading) {
      const profile = isProfileCompleted(candidate, parsedUser || ({} as BasicUserData));

      if (!profile.isComplete) {
        navigate(`/profile/${profile.section}`, {
          state: { isCompleting: true, ...profile.payload },
        });
      } else {
        navigate(`/dashboard`);
      }
    }
  }, [candidate, isLoading, navigate, parsedUser]);

  useEffect(() => {
    if (!email) navigate("/signup");
    else reloadData();
  }, [location.pathname, thorUser, email, navigate, reloadData]);

  useEffect(() => {
    if (email) {
      try {
        refetch();
      } catch (error) {
        if (error instanceof Error && !axios.isCancel(error)) {
          errorHandler({ error });
        }
      }
    }
  }, [thorUser, refetch, email]);

  useEffect(() => {
    if (candidate) {
      setThorUser(candidate);
    }
  }, [candidate, setThorUser]);

  if (isLoading) {
    return (
      <div className="isLoading-backdrop">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) return <ErrorPage />;

  return <div />;
};

export default ProfileRedirection;
