import { useNavigate } from "react-router-dom";
import Avatar from "../../../shared/components/UIElements/Avatar";
import pencil from "../../../resources/graphics/pencil.svg";
import userIcon from "../../../resources/images/account@3x.png";

import "./UserDataCategory.css";

interface IUserDataCategoryProps {
  title: string;
  info: Array<string> | Array<object>;
  hasImage: boolean;
  image?: string;
  editLink: string;
  payload: Object;
  placeholder: string;
}

const UserDataCategory = ({
  title,
  info,
  hasImage,
  editLink,
  payload,
  image,
  placeholder,
}: IUserDataCategoryProps): JSX.Element => {
  const navigate = useNavigate();

  return hasImage ? (
    <div className="profile__basic-info__personal">
      <div className="profile__basic-info__personal__user">
        <Avatar image={image ? image : userIcon} alt="profile_pic" width={100} isButton={image ? true : false} readonly />
        <div className="profile__basic-info__personal__info">
          {info.map((infoLine, index) => (
            <span key={"info-" + index}>{infoLine}</span>
          ))}
        </div>
      </div>
      <div
        className="profile__basic-info__personal__edit clickable"
        onClick={() => navigate(editLink, { state: { ...payload, isEditing: true } })}
      >
        <img src={pencil} alt="edit" />
      </div>
    </div>
  ) : (
    <div className="profile__basic-info__category">
      <div className="profile__basic-info__category-info">
        <h2>{title}</h2>
        {info.length > 0 ? (
          info.map((infoLine, index) => (
            <span key={"info-" + index}>{infoLine === "Anywhere" || infoLine === "Remote" ? <b>{infoLine}</b> : infoLine}</span>
          ))
        ) : (
          <span className="placeholder">{placeholder}</span>
        )}
      </div>
      <div
        className="profile__basic-info__personal__edit clickable"
        onClick={() => navigate(editLink, { state: { ...payload, isEditing: true } })}
      >
        <img src={pencil} alt="edit" />
      </div>
    </div>
  );
};

export default UserDataCategory;
