import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Card from "../../shared/components/UIElements/Card";
import { RadioButton } from "@vendition/vendition-frontend";
import Button from "../../shared/components/FormElements/Button";

import ProfileHeader from "../../shared/components/navigation/ProfileHeader";

import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { AuthContext } from "./context/auth-context";
import { errorHandler } from "../../shared/util/helpers";
import { useUpdateCandidate } from "../../components/Candidate/hooks/useUpdateCandidate";

export interface IBasicRadio {
  id: string;
  value: string;
}

const DUMMY_SITUATIONS = [
  {
    id: "first",
    value: "Currently in school",
  },
  {
    id: "second",
    value: "Currently unemployed & looking for new opportunities",
  },
  { id: "third", value: "Currently employed & looking for new opportunities" },
  { id: "fourth", value: "Just here to learn and poke around" },
];

const PersonalSituation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, isLoggedIn, thorUser } = useContext(AuthContext);
  const { mutate: updateCandidate } = useUpdateCandidate();

  // Redirection evaluation
  const { isEditing = false, jobSearchStatus } = location.state || thorUser || {};

  const optionSelected = DUMMY_SITUATIONS.find((option) => option.value === jobSearchStatus);

  const [selected, setSelected] = useState<IBasicRadio>(optionSelected || DUMMY_SITUATIONS[0]);
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const selectInitialValue = useCallback(() => {
    const selected = DUMMY_SITUATIONS.filter((situation) => situation.value === isEditing);
    if (selected.length > 0) setSelected({ id: selected[0].id, value: selected[0].value });
  }, [isEditing, setSelected]);

  useEffect(() => {
    if (email) {
      selectInitialValue();
    }
  }, [email, selectInitialValue]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const requestObject = {
      jobSearchStatus: selected.value,
      timeJobSearchStatusUpdated: new Date().getTime(),
    };
    try {
      updateCandidate(requestObject);
      navigate(isEditing ? "/profile/page" : "/profile/level");
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Your information was not updated" }).message,
        });
      }
    }
  };

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader progress={44} backNavigation="/profile/basics" />
      <div className="signup-form__container">
        {!isLoggedIn ? (
          <React.Fragment>
            <h1>I am...</h1>
            <h3>Pick one</h3>
          </React.Fragment>
        ) : (
          <h1>Edit Job Search Status</h1>
        )}
        <form className="form-container" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
          {DUMMY_SITUATIONS.map((situation) => (
            <RadioButton
              name={`rd${situation.id}`}
              key={situation.id}
              selected={selected}
              value={situation.id}
              handleChange={setSelected}
              text={situation.value}
              size="big"
            />
          ))}
          {isEditing ? (
            <div className="edit-form__button-container">
              <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                Cancel
              </Button>
              <Button size="big">Save</Button>
            </div>
          ) : (
            <Button size={"whole"}>Next</Button>
          )}
        </form>
      </div>
    </Card>
  );
};

export default PersonalSituation;
