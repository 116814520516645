import check from "../../../resources/graphics/check.svg";
import { ProfileCompletion } from "./Dashboard/Dashboard";
import "./ProgressBar.css";

export interface IProfileStatusItem {
  title: string;
  description: string;
  status: boolean;
}

interface IProgressBarProps {
  progress: Array<IProfileStatusItem>;
  isComplete: ProfileCompletion;
}

const ProgressBar = ({ progress, isComplete }: IProgressBarProps): JSX.Element => {
  return (
    <section className="section-container">
      <h1>What to Expect Next</h1>
      <h3>We're here to help you land your dream job</h3>
      <div className="progress-container">
        <div className="middle-line"></div>
        {progress.map((p_item, index) => (
          <div key={p_item.title + "_" + index} className="progress-bar__item">
            <div className="circle-container">
              <div className={`progress-bar__item-circle ${isComplete.isComplete && index === 0 && "item-circle__active"}`}>
                <img src={check} alt="check_svg" />
              </div>
            </div>
            <div className="progress-bar__text">
              <label>{p_item.title}</label>
              <span>{p_item.description}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProgressBar;
