import { useCallback, useReducer } from "react";
import { snakeCaseToCamelCase } from "../util/helpers";

interface IFormInput {
  [key: string]: { value: any; isValid: boolean };
}

export interface IFormState {
  inputs: IFormInput;
  isValid: boolean;
}

export type IFormDispatch = (id: string, value: any, isValid: boolean) => void;

interface IReducerAction {
  type: string;
  inputId?: string;
  value?: any;
  isValid: boolean;
  inputs?: IFormInput;
}

type IFormSetData = (inputData: IFormInput, formValidity: boolean) => void;

const formReducer = (state: IFormState, action: IReducerAction) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;
      for (const inputId in state.inputs) {
        if (inputId === action.inputId) formIsValid = formIsValid && action.isValid;
        else formIsValid = formIsValid && state.inputs[inputId].isValid;
      }
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId || "random"]: {
            value: action.value,
            isValid: action.isValid,
          },
        },
        isValid: formIsValid,
      };
    case "SET_DATA":
      return {
        inputs: action.inputs || { random: { value: "", isValid: false } },
        isValid: action.isValid,
      };
    default:
      return state;
  }
};

export const useForm = (initialInputs: IFormInput, formIsValid: boolean): [IFormState, IFormDispatch, IFormSetData] => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: initialInputs,
    isValid: formIsValid,
  });

  const setFormData = useCallback((inputData: IFormInput, formValidity: boolean) => {
    dispatch({ type: "SET_DATA", isValid: formValidity, inputs: inputData });
  }, []);

  const inputHandler = useCallback((id: string, value: any, isValid: boolean) => {
    dispatch({ type: "INPUT_CHANGE", inputId: snakeCaseToCamelCase(id), value, isValid });
  }, []);

  return [formState, inputHandler, setFormData];
};
