import { FunctionComponent } from "react";
import { ActionContainer, AnchorButton, StyledButton } from "./ActionPanel.styles";
import { IActionItem } from "./ActionPanel.types";

const ActionItemComponent: FunctionComponent<IActionItem> = ({
  title,
  description,
  action,
  actionCTA,
  isExternal,
  path
}): JSX.Element => {
  return (
    <ActionContainer>
      <div className="title-holder">
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
      {(isExternal && path) ? (
        <AnchorButton href={path}>
          {actionCTA}
        </AnchorButton>
      ) : (
        <StyledButton onClick={action} size={"big"} type="button">
          {actionCTA}
        </StyledButton>
      )}
    </ActionContainer>
  );
};

export default ActionItemComponent;
