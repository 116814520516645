import React from 'react'
import Datetime from 'react-datetime';
import moment, { Moment } from 'moment';

import { CalendarWrapper, StyledButton } from './Styles';
import CalendarIcon from '../../../../resources/graphics/icon-system-calendar.svg'


const DateTimeSelector = ({ date, setDate, handleSubmit, isLoading }) => {
  const elRef = React.useRef<any>();

  return (
    <>
      <CalendarWrapper>
        <Datetime
          value={date}
          isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, 'day'))}
          onChange={(date) => setDate(date as Moment)}
          timeConstraints={{
            minutes: { step: 15, min: 0, max: 60 },
            seconds: { step: 0, min: 0, max: 0 },
          }}
          ref={elRef}
        />
        <img src={CalendarIcon} alt="Calendar Icon" onClick={elRef.current?._openCalendar} />
      </CalendarWrapper>

      <StyledButton onClick={handleSubmit} disabled={isLoading}>Submit</StyledButton>
    </>
  )
};

export default DateTimeSelector;