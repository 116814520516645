import { Link } from "react-router-dom";
import "./Button.css";

type ButtonType = "button" | "submit" | "reset" | undefined;

type ButtonSize = "small" | "big" | "whole" | undefined;

interface IButtonProps {
  size?: ButtonSize;
  inverse?: boolean;
  href?: string;
  to?: string;
  danger?: boolean;
  outline?: boolean;
  type?: ButtonType;
  disabled?: boolean;
  icon?: string;
  centred?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const Button = (props: IButtonProps): JSX.Element => {
  if (props.href) {
    return (
      <a
        className={
          `button button--${props.size || "default"}
          ${props.inverse ? "button--inverse": ""}
          ${props.danger ? "button--danger": ""}
          ${props.centred ? "button--centred": ""}`
        }
        href={props.href}
      >
        {props.children}
      </a>
    );
  }
  if (props.to) {
    return (
      <Link
        to={props.to}
        className={`
          button button--${props.size || "default"}
          ${props.inverse ? "button--inverse" : ""}
          ${props.danger ? "button--danger" : ""}
          ${props.centred ? "button--centred" : ""}
        `}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={`
        center button button--${props.size || "default"}
        ${props.inverse && !props.outline ? "button--inverse" : ""}
        ${props.outline ? "button--outline" : ""}
        ${props.danger ? "button--danger" : ""}
        ${props.centred ? "button--centred" : ""}
       `}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.icon && <img src={props.icon} alt="paper-clip" width="21" height="21" />}
      {props.children}
    </button>
  );
};

export default Button;
