import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Auth } from "aws-amplify";
import * as AWS from "aws-amplify";

import { Button } from "@vendition/vendition-frontend";
import Input from "../../shared/components/FormElements/Input";

import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { IFormDispatch, IFormState, useForm } from "../../shared/hooks/form-hook";
import { errorHandler, NotImplementedError } from "../../shared/util/helpers";
import { VALIDATOR_LOGIN, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { AuthContext } from "../components/context/auth-context";
import * as Sentry from "@sentry/react";
import "./Login.css";
import { AmplifyConfig, adminConfig } from "../../config";
import { setStoredAdmin } from "../../components/Candidate/hooks/admin-storage";
import { useQueryClient } from "@tanstack/react-query";

const Login = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.clear();
  }, [queryClient]);

  const { setIsOnboarding, assessLoggedInState, isLoggedIn, logout } = useContext(AuthContext);
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  // Navigation related variables
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formState, inputHandler] = useForm(
    {
      emailAddress: {
        value: "",
        isValid: false,
      },
      currentPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
    else setIsOnboarding(true);
  }, [setIsOnboarding, isLoggedIn, navigate]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let adminEmail = "";
    let candidateEmail = "";
    // check if system needs a redirection
    const redirectURL = searchParams.get("redirect_url");
    const {
      emailAddress: { value: emailAddress },
      currentPassword,
    } = formState.inputs;
    const lowercaseEmail = (emailAddress as string).trim().toLowerCase();
    const isAdminLogin = /^\S+@\S+\.\S+ as \S+@\S+\.\S+$/.test(lowercaseEmail);

    await logout();

    try {
      if (isAdminLogin) {
        setStoredAdmin(true);
        AWS.Amplify.configure(adminConfig);
        [adminEmail, candidateEmail] = lowercaseEmail.split(" as ");
      }
      // Set user email in Sentry
      Sentry.setUser({ email: adminEmail || lowercaseEmail });
      // Await user to SignIn to Cognito
      const user = await Auth.signIn(adminEmail || lowercaseEmail, currentPassword.value);
      if (user.challengeName)
        throw new NotImplementedError("Please reset your password using the link below", user.challengeName);
      // if user has response log into Thor
      if (user) await assessLoggedInState(false, candidateEmail);

      redirectURL
        ? window.location.replace(redirectURL.includes("http") ? redirectURL : `http://${redirectURL}`)
        : navigate("/dashboard");
    } catch (error) {
      if (error instanceof Error) {
        const handledError = errorHandler({ error, payload: { email: emailAddress, password: currentPassword.value } });
        //Display message and get redirection route
        setToast({ title: "", message: handledError.message, type: "Error" });
        if (handledError.navigation) {
          setTimeout(() => navigate(handledError.navigation.route, { state: handledError.navigation.state }), 1000);
        }
      }
    }
    if (isAdminLogin) {
      AWS.Amplify.configure(AmplifyConfig);
    }
  };

  return (
    <div className="login-container">
      <React.Suspense fallback={<div className="login-container__image-lowres" />}>
        <div className="login-container__image" />
      </React.Suspense>
      <div className="login-container__form-container">
        <h1>Login</h1>
        <form className="form-container" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
          <Input
            id="emailAddress"
            element="input"
            type={"text"}
            label="Email Address"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_LOGIN()]}
            errorText="Enter a valid email"
            inputCallback={inputHandler as IFormDispatch}
            placeholder="jane.doe@example.com
          "
          />
          <Input
            id="current-password"
            element="input"
            type={"password"}
            label="Password"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(8)]}
            errorText="Enter a valid password (minimum of 8 characters)"
            inputCallback={inputHandler as IFormDispatch}
            placeholder="Your password"
            autocomplete="current-password"
          />
          <Button disabled={!(formState as IFormState).isValid} size={"whole"}>
            Let's Go!
          </Button>
          <hr />
          <div className="link clickable">
            <span onClick={() => navigate("/forgot")}>Forgot your password?</span>
            <br />
          </div>
          <div className="link-separator">
            <span>Don't have an account?</span>
            <br />
          </div>
          <Button outline type="button" onClick={() => navigate("/signup")}>
            Get Started
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
