import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "./context/auth-context";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import FileUpload from "../../shared/components/FormElements/FileUpload";
import Card from "../../shared/components/UIElements/Card";

import chevron from "../../resources/graphics/arrow-back.svg";
import clip from "../../resources/images/icon_paperclip@3x.png";

import "./Curriculum.css";
import { useForm } from "../../shared/hooks/form-hook";
import Input from "../../shared/components/FormElements/Input";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorPage from "../pages/ErrorPage";
import { useResume } from "../../shared/hooks/useResume";
import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import { errorHandler, getFileExtension } from "../../shared/util/helpers";
import { useUpdateCandidate } from "../../components/Candidate/hooks/useUpdateCandidate";
import { VALIDATOR_LINKEDIN } from "../../shared/util/validators";

const Curriculum = () => {
  const navigate = useNavigate();
  const { email, setIsOnboarding, isOnboarding, thorUser, setThorUser, isLoggingIn } = useContext(AuthContext);
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const { setResume, setType, loading: lResume, error: rError, parsedUser, fileLocation } = useResume(email);

  const { mutateAsync: patchCandidate } = useUpdateCandidate();

  const [formState, inputHandler] = useForm(
    {
      cvFile: {
        value: null,
        isValid: true,
      },
      linkedIn: {
        value: thorUser?.linkedInProfile ?? "",
        isValid: true,
      },
    },
    true
  );

  useEffect(() => {
    const fileObject = formState.inputs.cvFile.value;
    if (fileObject) {
      setType(getFileExtension((fileObject as string).split("/")[1]));
    }
  }, [formState.inputs.cvFile.value, setType]);

  const preCreateCandidate = useCallback(async () => {
    if (!email) return;

    try {
      const data = await axios.put(`${process.env.REACT_APP_AWS_DEV_URL}/candidate/`, { email })
      setThorUser(data.data);
    } catch (error) {
      if (error instanceof Error) {
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "There is some wrong with your user. Please contact support" }).message,
        })
      }
    }
  }, [email, setThorUser])

  useEffect(() => {
    if (!isLoggingIn && !thorUser?.email) {
      preCreateCandidate();
    }
  }, [isLoggingIn, preCreateCandidate, thorUser?.email]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { cvFile, linkedIn } = formState.inputs;

    if (cvFile.value === null && linkedIn.value === "") {
      patchCandidate({ email })
      navigate("/profile/basics");
    }
    else {
      try {
        const requestObject = {
          linkedInProfile: linkedIn.value,
        };

        if (cvFile.value !== null) {
          await patchCandidate(requestObject);
          setResume(cvFile.value);
        } else {
          await patchCandidate(requestObject);
          navigate("/profile/basics");
        }
      } catch (error) {
        if (error instanceof Error)
          setToast({
            type: "Error",
            title: "",
            message: errorHandler({ error, customMessage: "There was an error saving your data" }).message,
          });
      }
    }
  };

  const saveResumeLink = useCallback(async () => {
    try {
      const requestObject = {
        resumeURL: fileLocation,
      };

      await patchCandidate(requestObject);
      navigate("/profile/basics");
    } catch (error) {
      if (error instanceof Error)
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "There was an error saving resume url" }).message,
        });
    }
  }, [fileLocation, navigate, patchCandidate]);

  useEffect(() => {
    if (!lResume && !rError && parsedUser && fileLocation) saveResumeLink();
  }, [lResume, rError, parsedUser, fileLocation, saveResumeLink]);

  useEffect(() => {
    setIsOnboarding(true);
  }, [setIsOnboarding, isOnboarding]);

  if (lResume)
    return (
      <div className="loading-backdrop">
        <LoadingSpinner />
      </div>
    );

  if (rError || !email) {
    return <ErrorPage />;
  }

  return (
    <Card className={"signup-content__container"}>
      <div className="progress-bar">
        <div className="progress-bar__progress" style={{ width: "11%" }} />
      </div>
      <div className="signup-header__chevron">
        <span onClick={() => navigate("/signup")}>
          <img src={chevron} alt="back" width="20" height="20" />
        </span>
      </div>
      <div className="signup-form__container">
        <h1>Create Your profile</h1>
        <h3>
          LinkedIn and Resume are <b>optional</b> but you can speed things up by uploading your resume and we'll automatically
          complete as much of your profile information as we can. On average, Candidates who upload their resumes complete their
          profiles in <b>less than 2 minutes</b>.
        </h3>
        <form className="curriculum-form" onSubmit={submitHandler}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
          <FileUpload
            center
            id={"cvFile"}
            onInput={inputHandler}
            label={"Resume/CV"}
            buttonText="Attach Resume"
            icon={clip}
            type="File"
            acceptedTypes=".doc, .docx, .pdf"
            initialFileName={thorUser?.resume ? "Resume Uploaded" : ""}
            key={thorUser?.resume ? "resume" : "no-resume"}
          />
          <div className="resume-inputs__container">
            <Input
              id="linkedIn"
              type="text"
              placeholder="https://www.linkedin.com/in/"
              label="LinkedIn Profile"
              validators={[VALIDATOR_LINKEDIN()]}
              initialIsValid={true}
              element="input"
              inputCallback={inputHandler}
              initialValue={thorUser?.linkedInProfile}
              verbose
            />
          </div>
          <div className="edit-form__button-container">
            <Button size="big" disabled={!formState.isValid}>Next</Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default Curriculum;
