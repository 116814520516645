import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "react-google-autocomplete";

import { useOptions } from "../../../shared/hooks/options-hooks";
import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import { DUMMY_CITIES } from "../../../shared/util/constants";

import Card from "../../../shared/components/UIElements/Card";
import OptionSelect from "../../../shared/components/FormElements/OptionSelect";
import Button from "../../../shared/components/FormElements/Button";

import { selectInitialOptionsFromState } from "./selectedLocationsHelper";
import "./LocationInfo.css";
import { AuthContext } from "../context/auth-context";

interface PreferredLocationProps {
  onSave: (data: any) => void;
  isEditing: boolean;
}

const PreferredLocation = ({ onSave, isEditing }: PreferredLocationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { thorUser } = useContext(AuthContext);
  const { preferredLocation, preferredLocationGeo } = location.state || thorUser || {};

  const [optionState, optionHandler, selectedCities, addOptionHandler] = useOptions({
    options: preferredLocation ? selectInitialOptionsFromState(DUMMY_CITIES, preferredLocation.map(i => i.replace("/", ",")), preferredLocationGeo) : DUMMY_CITIES,
  });

  const formIsValid = (): boolean => {
    if (selectedCities.length > 0) {
      if (selectedCities.length === 1) return selectedCities[0].label.toLowerCase() === "other" ? false : true;
      return true;
    }

    return false;
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const locArray = selectedCities.filter((l) => l.label !== "Other")
    const preferredLocation = locArray.map((city) => city.label);
    const preferredLocationGeo = locArray.map((city) => city.data);
    
    const requestObject = {
      preferredLocation,
      preferredLocationGeo,
    };
    
    onSave(requestObject);
  };

  const autocompleteHandler = (place: google.maps.places.PlaceResult) => {
    let location = "";
    if (place.formatted_address) {
      const locArr = place.formatted_address.split(", ");
      location = `${locArr[0]}, ${locArr[1].substring(0, 2)}`;
    }

    if (!place?.place_id) return;

    if(!optionState.options.find(item => item.label === location)) {
      const lat = place.geometry?.location?.lat();
      const lon = place.geometry?.location?.lng();
      const geo = lat && lon ? { lat, lon } : undefined;
      addOptionHandler(place.place_id || "place_id", location, true, geo);
    }
  };

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader progress={44} backNavigation="/profile/job-title" /> 
      <div className="signup-form__container">
        <h1>Where do you want to work?</h1>
        <form className="form-container" onSubmit={submitHandler}>
          <div className="location-form__select">
            <label>Suggested Cities</label>
            <div className="form-container list-container">
              <ul className={"trait-list"}>
                {optionState.options.map((trait) => (
                  <OptionSelect
                    key={trait.value}
                    value={trait.value.replace(/\/ |\//, ', ')}
                    label={trait.label.replace(/\/ |\//, ', ')}
                    isDisabled={false}
                    initialSelected={trait.isSelected}
                    optionCallback={optionHandler}
                  />
                ))}
              </ul>
            </div>
          </div>
          {selectedCities.filter((city) => city.label.toLowerCase() === "other").length > 0 && (
            <div className="form-control">
              <label htmlFor="txtAuto">
                Other Cities <span>*</span>
              </label>
              <AutoComplete
                id="txtAuto"
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                options={{fields: ["place_id", "formatted_address", "geometry"]}}
                onPlaceSelected={autocompleteHandler}
              />
            </div>
          )}
          {isEditing ? (
            <div className="edit-form__button-container">
              <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
                Cancel
              </Button>
              <Button disabled={!formIsValid()} size="big">
                Save
              </Button>
            </div>
          ) : (
            <Button disabled={!formIsValid()} size={"whole"}>
              Next
            </Button>
          )}
        </form>
      </div>
    </Card>
  );
};

export default PreferredLocation;
