import { LoadingSpinner } from "@vendition/vendition-frontend";
import { FunctionComponent, ReactElement } from "react";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

const Loading: FunctionComponent = (): ReactElement => {
  const isFetching = useIsFetching() > 0 ? true : false;
  const isMutating = useIsMutating() > 0 ? true : false;

  return <LoadingSpinner show={isFetching || isMutating} asOverlay={true} />;
};

export default Loading;