import { useState, useRef } from 'react';
import { Container, List, ListItem } from './styles';


import Input, { IInput } from '../Input';
import { useTitle } from '../../../hooks/useTitle';
import useClickOutside from '../../../hooks/useClickOutside';


const TitleAutocomplete = ({ initialValue = '', onOptionSelect, ...props }: IInput) => {
  const [ selectedTitle, setSelectedTitle ] = useState(initialValue);
  const { titles, searchForMatches } = useTitle(true);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const ref = useRef();

  useClickOutside(ref, () => {
    setShowSuggestions(false);
  });

  const handleInputChange = (partialTitle: string) => {
    searchForMatches(partialTitle);
    !showSuggestions && setShowSuggestions(true)
  };

  const handleSuggestionClick = (title: any) => {
    setSelectedTitle(title.title);
    setShowSuggestions(false);
    onOptionSelect && onOptionSelect(title);
  };

  return (
    <Container ref={ref} onFocus={() => setShowSuggestions(true)}>
      <Input
        {...props}
        type="text"
        onChange={handleInputChange}
        autocomplete='off'
        initialValue={selectedTitle}
      />
      {showSuggestions && titles.length > 0 && (
        <List>
          {titles.map((title, idx) => (
            <ListItem key={idx} onClick={() => handleSuggestionClick(title)} id={`opt_${idx}`}>
              {title.title}
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
};

export default TitleAutocomplete;