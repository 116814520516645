import { UseMutateFunction, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { baseUrl } from "../../axiosInstance/constants";
import { queryKeys } from "../../react-query/constants";
import { AuthContext } from "../../users/components/context/auth-context";

type PartialCandidate = Record<string, any>;

interface IPatchCandidateProps {
  data: PartialCandidate;
  getToken: () => Promise<string>;
  submissionId: string;
}

async function updateNewCandidate({ data, getToken, submissionId }: IPatchCandidateProps): Promise<void> {
  const token = await getToken();

  if (!data || !Object.keys(data).length) {
    return;
  }

  if (!submissionId || !token) {
    return;
  }

  await axios.patch(`${baseUrl}/submission/${submissionId}`, { ...data }, { headers: {  Authorization: `Bearer ${token}` } });
}

export const useUpdateSubmission = ({submissionId}): UseMutateFunction<void, unknown, PartialCandidate, unknown> => {
  const queryClient = useQueryClient();
  const { getToken } = useContext(AuthContext);

  const { mutateAsync } = useMutation(
    (candidate: PartialCandidate) => updateNewCandidate({ data: candidate, getToken, submissionId }),
    {
      onSuccess: () => {
        // Invalidate the query in cache
        queryClient.removeQueries([queryKeys.requisitions]);
      },
    }
  );

  return mutateAsync;
};