import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";
import Button from "../../../shared/components/FormElements/Button";

import { AuthContext } from "../context/auth-context";
import { useResume } from "../../../shared/hooks/useResume";
import { useForm } from "../../../shared/hooks/form-hook";

import { ReactComponent as Close } from "../../../resources/graphics/close_grey.svg";
import FileUpload from "../../../shared/components/FormElements/FileUpload";
import Card from "../../../shared/components/UIElements/Card";
import clip from "../../../resources/images/icon_paperclip@3x.png";
import { ReactComponent as ImagoType } from "../../../resources/graphics/logo_emblem.svg";
import { getFileExtension } from "../../../shared/util/helpers";

const ResumeUpdate = ({ onSubmit }: { onSubmit?: () => void }): JSX.Element => {
  // State
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  // Router State
  const navigate = useNavigate();
  // Context and hooks variables;
  const { email, thorUser } = useContext(AuthContext);
  const { loading, error, setResume, setType } = useResume(email, onSubmit);

  const [formState, inputHandler] = useForm(
    {
      resumeFile: {
        value: null,
        isValid: false,
      },
    },
    false
  );
  // --------------------------------------------------------------------------------------------

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setResume(formState.inputs.resumeFile.value);
  };

  useEffect(() => {
    const fileObject = formState.inputs.resumeFile.value;
    if (fileObject) {
      setType(getFileExtension((fileObject as string).split("/")[1]));
    }
  }, [formState.inputs.resumeFile.value, setType]);

  if (loading)
    return (
      <div className="loading-backdrop">
        <LoadingSpinner />
      </div>
    );

  if (error && !toast)
    setToast({
      type: "Error",
      title: "",
      message: `There was an error sending the File. ${error}`,
    });

  return (
    <div className="basic-data__container">
      <Card className={"signup-content__container"}>
        <div className="signup-header__close">
          <ImagoType width={53} />
          <span onClick={() => navigate(-1)}>
            <Close width={24} height={24} stopColor={"#000"} />
          </span>
        </div>
        <div className="signup-form__container">
          <h1>Your Resume</h1>
          {toast && <ToastNotification title={toast.title} message={toast.message} type="Error" />}
          <form className="curriculum-form" onSubmit={submitHandler} encType="multipart/form-data">
            <FileUpload
              center
              id={"resumeFile"}
              onInput={inputHandler}
              label={""}
              buttonText="Upload Resume"
              icon={clip}
              type="File"
              acceptedTypes=".pdf,.docx,.doc"
              initialFileName={thorUser?.resume ? "Resume Uploaded" : ""}
            />
            <div className="edit-form__button-container">
              <Button size="big" type="button" onClick={() => navigate("/profile/page")} outline>
                Cancel
              </Button>
              <Button size="big" disabled={!formState.isValid}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default ResumeUpdate;
