import axios, { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { baseUrl } from "../../../axiosInstance/constants";
import { queryKeys } from "../../../react-query/constants";
import { BasicUserData } from "../../../users/pages/ProfilePage";
import { useContext } from "react";
import { AuthContext } from "../../../users/components/context/auth-context";
import validatedCandidate from "./candidateValidation";

interface IFetchCandidateProps {
  email?: string;
  getToken(): Promise<string>;
}

const fetchCandidateData = async ({
  email,
  getToken,
}: IFetchCandidateProps): Promise<BasicUserData | undefined> => {
  const token = await getToken();
  // !If user is undefined return before fetching
  if (!email || !token) return {} as BasicUserData;

  const { data } = await axios.get(`${baseUrl}/candidate/${email}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  
  return validatedCandidate(data);
};

export const useCandidate = (): {
  candidate: BasicUserData | undefined;
  isLoading: boolean;
  error: AxiosError;
  refetch: () => any;
} => {
  const { email, getToken } = useContext(AuthContext);
  const {
    data: candidate,
    isLoading,
    refetch,
    error,
  } = useQuery(
    [queryKeys.candidate],
    () => fetchCandidateData({ email: email ?? undefined, getToken }),
    {
      enabled: !!email,
    }
  );
  
  return { candidate, isLoading, refetch, error: error as AxiosError };
};

export const usePrefetchCandidate = (): void => {
  const queryClient = useQueryClient();
  const { email, getToken } = useContext(AuthContext);

  queryClient.prefetchQuery([queryKeys.candidate], () =>
    fetchCandidateData({ email: email ?? undefined, getToken })
  );
};
