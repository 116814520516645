
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const isEmptyArray = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
}

const isEmpty = (value) => {
  return value === "" || isEmptyObject(value) || isEmptyArray(value);
}

export const removeEmpty = (data) => {
  if (Array.isArray(data)) {
    return data
      .filter(item => !isEmpty(removeEmpty(item)))
      .map(item => removeEmpty(item));
  }

  if (typeof data === "object" && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const cleanedValue = removeEmpty(value);
      if (!isEmpty(cleanedValue)) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});
  }

  return data;
}