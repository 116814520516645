import { Auth } from "aws-amplify";
import { useState, useContext } from "react";
import Card from "../../shared/components/UIElements/Card";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import { IFormDispatch, useForm } from "../../shared/hooks/form-hook";
import { VALIDATOR_CONTAINS_NUMBERS, VALIDATOR_CONTAINS_UPPERCASE, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_SPECIAL_CHARACTERS } from "../../shared/util/validators";
import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";

import { useSearchParams } from "react-router-dom";
import { errorHandler } from "../../shared/util/helpers";
import { AuthContext } from "../components/context/auth-context";

import ErrorPage from "./ErrorPage";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import "./Settings.css";

const Settings = (): JSX.Element => {
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoggedIn } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [formState, inputHandler] = useForm(
    {
      currentPassword: {
        value: "",
        isValid: false,
      },
      newPassword: {
        value: "",
        isValid: false,
      },
      newPasswordConfirmation: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const { currentPassword, newPassword, newPasswordConfirmation } = formState.inputs;
      if (newPassword.value === newPasswordConfirmation.value) {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();
        const changeResp = await Auth.changePassword(user, currentPassword.value, newPassword.value);
        setLoading(false);
        if (changeResp === "SUCCESS") {
          setToast({
            type: "Success",
            title: "",
            message: "Your information is updated",
          });
        }
      } else {
        setToast({
          type: "Error",
          title: "",
          message: "Please check entered data",
        });
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error)
        setToast({
          type: "Error",
          title: "",
          message: errorHandler({ error, customMessage: "Please check entered data" }).message,
        });
    }
  };

  if (!isLoggedIn) return <ErrorPage />;

  return (
    <div className="settings__container">
      <div className="settings__section">
        <h1>Account Settings</h1>
        <Card>
          <h1>Change Password</h1>
          <form className="form-container" onSubmit={submitHandler}>
            {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
            {searchParams.get("reset") === null && (
              <Input
                id="current-password"
                element="input"
                type="password"
                label="Old Password"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="This field is required"
                inputCallback={inputHandler as IFormDispatch}
                autocomplete="current-password"
              />
            )}
            <Input
              id="new-password"
              element="input"
              type="password"
              label="New Password"
              validators={[
                VALIDATOR_REQUIRE(),
                VALIDATOR_MINLENGTH(8),
                VALIDATOR_CONTAINS_UPPERCASE(),
                VALIDATOR_SPECIAL_CHARACTERS(),
                VALIDATOR_CONTAINS_NUMBERS(),
              ]}
              errorText="Enter a valid password (minimum of 8 characters)"
              inputCallback={inputHandler as IFormDispatch}
              verbose
              autocomplete="new-password"
            />
            <Input
              id="new-password-confirmation"
              element="input"
              type="password"
              label="Confirm New Password"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="This field is required"
              inputCallback={inputHandler as IFormDispatch}
              autocomplete="new-password"
            />
            <Button type="submit" disabled={!formState.isValid}>
              {loading ? <LoadingSpinner className="small" /> : "Save changes"}
            </Button>
          </form>
        </Card>
      </div>
      <div className="settings__support-section">
        <span>Need help? Email: </span>
        <span className="support clickable">support@vendition.com</span>
      </div>
    </div>
  );
};

export default Settings;
