import React from 'react';
import { AlertText, DateConfirmation, EmailConfirmationText, StyledButton, StyledCard, StyledCardHeader, Wrapper } from './Styles';
import CalendarIcon from '../../../../resources/graphics/icon-system-calendar.svg'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const InterviewConfirmation = ({ company, requisition, appointment }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <StyledCard>
        <h2>Upcoming Interviews</h2>
        <StyledCardHeader>
          <img
            src={company.logoURL}
            alt={company.name}
          />
          <h2>{company.name}</h2>
        </StyledCardHeader>
        <p>{requisition.title}</p>
        <AlertText>Thank you for scheduling your interview with {company.name}.</AlertText>
        <DateConfirmation>
          <img src={CalendarIcon} alt="Calendar Icon" />
          <p>{moment(appointment.data.dateBegin).format('MMMM DD h:mm a')}.</p>
        </DateConfirmation>
        <EmailConfirmationText>You will receive an email confirmation shortly.</EmailConfirmationText>
        <StyledButton onClick={() => navigate('/interviews')}>Go Back</StyledButton>
      </StyledCard>
    </Wrapper>
  )
};

export default InterviewConfirmation;