import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment, { Moment } from 'moment';
import { LoadingSpinner, OfferHeader } from "@vendition/vendition-frontend";

import ErrorPage from "../../../pages/ErrorPage";
import { IInterview } from "../../../../components/pages/Interviews/Interviews.types";
import { useCompany } from "../../../../components/pages/Company/useCompany";
import { getNext15Min } from "../../../../shared/util/helpers";
import { InterviewWrapper, StyledCardBody } from "./Styles";
import { useScheduleInterview } from "./useScheduleInterview";
import ExternalLink from "./ExternalInterview";
import CalendlyLink from "./CalendlyLink";
import InterviewConfirmation from "./InterviewConfirmation";

const ScheduleInterview = ({ props }: { props: IInterview }) => {
  const [date, setDate] = useState<Moment>(moment(getNext15Min()));
  const navigate = useNavigate();

  const company = useCompany(props.company.id);
  const { mutate: scheduleInterview, isLoading, interviewScheduled, data } = useScheduleInterview();

  const handleSubmit = () => {
    scheduleInterview({
      requisition: props.requisition,
      submission: props.submission,
      date: date.toDate(),
      interviewDuration: props.requisition.interviewDuration || 60,
      companyName: company?.name
    });
  };

  if (!company) return <LoadingSpinner show asOverlay />;

  if (interviewScheduled && data) return (
    <InterviewConfirmation
      company={company}
      requisition={props.requisition}
      appointment={data.appointment}
    />
  );

  const isCalendlyLink = props.requisition.interviewSchedulingLink!.includes('calendly');
  const InterviewLink = isCalendlyLink ? CalendlyLink : ExternalLink;

  return (
    <InterviewWrapper>
      <OfferHeader
        company={company}
        submission={""}
        requisition={props.requisition}
        accept={function (): void {}} 
        navigateBack={function (): void { navigate(-1) }}
        navigateNext={function (): void { navigate(`/interviews/company/${company.id}`) }}
        dismiss={function (e: React.MouseEvent<Element, MouseEvent>): void {}}
        isDemo
      />
      <StyledCardBody>
        <InterviewLink
          companyName={company.name!}
          interviewSchedulingLink={props.requisition.interviewSchedulingLink!}
          date={date}
          setDate={setDate}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </StyledCardBody>
    </InterviewWrapper>
  );
};

const ScheduleInterviewWrapper = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  if (!state?.company?.id) {
    navigate("/interviews");
    return <ErrorPage />;
  }
  
  return <ScheduleInterview props={state} />
};

export default ScheduleInterviewWrapper;