import { HTMLAttributes } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import "./Modal.css";

interface IModalOverlayProps {
  className?: string;
  headerClass?: string;
  header: string;
  contentClass?: string;
  footerClass?: string;
  fullscreen?: boolean;
  onModalSubmit?: () => void;
  style?: HTMLAttributes<HTMLDivElement>;
  children: React.ReactNode;
  footer: React.ReactNode;
}

interface IModalProps extends IModalOverlayProps {
  show: boolean;
  onCancel: () => void;
}

const ModalOverlay = ({
  className,
  style,
  headerClass,
  header,
  contentClass,
  onModalSubmit,
  children,
  footerClass,
  footer,
  fullscreen,
}: IModalOverlayProps): JSX.Element => {
  const content = (
    <div className={`${className} ${fullscreen ? "fullscreen" : "modal"}`} style={style}>
      <header className={`modal__header ${headerClass}`}>
        <h2>{header}</h2>
      </header>
      <form onSubmit={onModalSubmit ? onModalSubmit : (event) => event.preventDefault()}>
        <div className={`modal__content ${contentClass}`}>{children}</div>
        <footer className={`modal__footer ${footerClass}`}>{footer}</footer>
      </form>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal-hook") as HTMLElement);
};

const Modal = (props: IModalProps) => {
  return (
    <>
      <CSSTransition in={props.show} mountOnEnter unmountOnExit timeout={200} classNames="modal">
        <ModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};
export default Modal;
