import { useLocation, useNavigate } from "react-router";
import ProfileHeader from "../../shared/components/navigation/ProfileHeader";
import Button from "../../shared/components/FormElements/Button";
import Card from "../../shared/components/UIElements/Card";
import { useEffect, useState } from "react";
import Encourage from "./Encourage";

const AboutYourself = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCompleting = false } = (location.state as { isCompleting: string }) || {};
  const [showModal, setShowModal] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(false)
    }, 1500);
  }, []);

  return (
    <>
      <Encourage showModal={showModal} />
      <Card className={"signup-content__container"}>
        <ProfileHeader backNavigation="/profile/location" progress={90} />
        <div className="signup-form__container">
          <h1>Tell us about yourself</h1>
          <div className="form-container">
            <p>This will help us pair you with the right companies.</p>
            <p>
              Only Vendition will be seeing this video, and we won’t share this with any potential employers without your
              permission.
            </p>
            <p>Feel free to keep it casual!</p>
            <Button size="whole" type="button" onClick={() => navigate("/profile/video/recording", { state: { isCompleting } })}>
              Create my video
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AboutYourself;
