import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { RadioButton, Button, Card } from "@vendition/vendition-frontend";
import { IOptionSelection } from "../../shared/hooks/options-hooks";
import { IBasicRadio } from "./PersonalSituation";
import { AuthContext } from "./context/auth-context";
import ProfileHeader from "../../shared/components/navigation/ProfileHeader";

const DUMMY_QUESTIONS: Array<IOptionSelection> = [
  { value: "1", label: "What motivates you?", isSelected: false },
  {
    value: "2",
    label: "Tell us about a time you accomplished something you’re proud of.",
    isSelected: false,
  },
  { value: "3", label: "What makes your story unique?", isSelected: false },
  {
    value: "4",
    label: "What do you think makes a great salesperson?",
    isSelected: false,
  },
  {
    value: "5",
    label: "What differentiates a good from a great salesperson?",
    isSelected: false,
  },
];

const VideoQuestion = () => {
  const [selected, setSelected] = useState<IBasicRadio>({ id: "", value: "" });
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();
  const { isCompleting = false, isEditing = false } = (location.state as { isCompleting: boolean, isEditing: boolean }) || {};

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const getSelectedOption = (items: Array<IOptionSelection>): IOptionSelection => {
    const selection = items.filter((item) => item.value === selected.id);
    return selection.length > 0 ? selection[0] : ({} as IOptionSelection);
  };

  const handleSubmit = () => {
    navigate(`/profile/video/recording`, {
      state: {
        answer: getSelectedOption(DUMMY_QUESTIONS),
        isEditing
      },
    });
  };

  return (
    <Card className={"signup-content__container"}>
      <ProfileHeader backNavigation="/profile/recording" progress={95} />
      <div className="signup-form__container">
        <h1>{isLoggedIn && !isCompleting ? "Select your prompt" : "Last Step!"}</h1>
        <h3>Which prompt would you like to answer in your next video?</h3>
        <form className="form-container" onSubmit={submitHandler}>
          {DUMMY_QUESTIONS.map((question, index) => (
            <RadioButton
              name={`rd_${index}`}
              key={`question_${index}`}
              selected={question.isSelected ? { id: question.value, value: question.label } : selected}
              value={question.value}
              handleChange={setSelected}
              text={question.label}
              size="big"
            />
          ))}
          <div className="edit-form__button-container">
            <Button size={"whole"} type="button" onClick={handleSubmit} disabled={selected.id === ""}>
              {isCompleting ? "Next" : "Create video with above prompt"}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default VideoQuestion;
