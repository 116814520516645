import { useState, useEffect, useCallback } from "react";
import { useDebounce } from "../useDebounce";

export interface ITitle {
  titles: any[];
  searchForMatches: (title: string) => void;
}

export const fetchTitlesData = async (title: string, searchAsYouType: boolean): Promise<any[]> => {
  if (!title) return [];

  const url = `${process.env.REACT_APP_SAYT_URL}?title=${title}&searchAsYouType=${searchAsYouType}`
  const response = await fetch(url).then((res) => res.json());
  const data = response.hits
    .sort((a: { _score: number; }, b: { _score: number; }) => b._score - a._score)
    .map(({ _source }) => _source)

  return data;
};

export const useTitle = (searchAsYouType: boolean): ITitle => {
  const [titles, setTitles] = useState<any[]>([]);
  const [inputTitle, setInputTitle] = useState("");
  const debouncedTitle = useDebounce(inputTitle, 300);

  const fetchTitles = useCallback(async (title: string) => {
    if (!title) return setTitles([]);
    const data = await fetchTitlesData(title, searchAsYouType);
    setTitles(data);
  }, [searchAsYouType]);

  const searchForMatches = (title: string) => {
    setInputTitle(title);
  }

  useEffect(() => {
    fetchTitles(debouncedTitle);
  }, [debouncedTitle, fetchTitles]);

  return { titles, searchForMatches };
};
