import * as Sentry from "@sentry/react";
interface ErrorHandlerArgs {
  error: Error;
  destination?: string;
  payload?: any;
  customMessage?: string;
}

export const errorHandler = ({
  error,
  destination,
  payload,
  customMessage,
}: ErrorHandlerArgs): { message: string; navigation: { route: string; state: any } } => {
  let message: string = "";
  let navigation: string = "";
  // Send the error to Sentry
  Sentry.captureException(error);
  // Switch with the error name for specific messages
  switch (error.name) {
    case "UserNotConfirmedException":
      message = customMessage ?? "This username exists but is not yet confirmed, please send us your confirmation code so you can access";
      navigation = "/signup/confirmation";
      break;
    case customMessage ?? "NotAuthorizedException":
      message = "Username or Password are not valid.";
      break;
    case customMessage ?? "UsernameExistsException":
      message = "Opps, looks like that email is taken, please check out our login page!";
      navigation = "/login";
      break;
    case customMessage ?? "NEW_PASSWORD_REQUIRED":
      message = "Please reset your password using the link below";
      break;
    case customMessage ?? "CodeMismatchException":
      message = "The confirmation code is not valid."
      break;
    default:
      message = customMessage || `Unsupported error type. ${error.name}`;
      break;
  }

  return {
    message,
    navigation: {
      route: destination || navigation,
      state: payload || {},
    },
  };
};

export class NotImplementedError extends Error {
  constructor(message: string = "", name?: string) {
    super();

    this.name = name || "NotImplementedError";
    this.message = message;
  }
}

export const getFileExtension = (file: string): "pdf" | "docx" | "doc" =>
  file.includes("pdf") ? "pdf" : file.includes("vnd.openxmlformats-officedocument.wordprocessingml.document") ? "docx" : "doc";


export const removeCookiesByPattern = (pattern: string) => {
  const cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    const name = cookie.split("=")[0].trim();
    if (name.match(pattern)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${window.location.hostname}`;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.vendition.com`;
    }
  });
};

export const snakeCaseToCamelCase = (str) => {
  return str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

export const getNext15Min = (date: Date = new Date()) => {
  const minutes = date.getMinutes();
  const minutesRounded = Math.ceil(minutes / 15) * 15;
  date.setMinutes(minutesRounded);
  date.setSeconds(0)
  return date;
};

export const dateInOneHour = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate;
};

export const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes*60000);
}