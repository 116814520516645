import React, { useEffect, useReducer } from "react";
import { IValidator } from "../../util/validators";
import "./Checkbox.scss";

interface ICheckbox {
  id: string;
  children: React.ReactNode;
  className?: string;
  validators: Array<IValidator>;
  initialValue?: boolean;
  inputCallback: (id: string, value: any, isValid: boolean) => void;
}

interface IReducerAction {
  type: string;
  val: boolean;
  validators: Array<IValidator>;
}

interface IInputState {
  value: boolean;
  isValid: boolean;
  isTouched: boolean;
}

const inputReducer = (state: IInputState, action: IReducerAction) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: action.validators.length > 0 ? action.val : true,
        isTouched: true,
      };
    default:
      return state;
  }
};

const Checkbox = ({ id, children, className, validators, inputCallback, initialValue }: ICheckbox) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: initialValue || false,
    isValid: validators.length > 0 ? false : true,
    isTouched: true,
  });

  const { value, isValid } = inputState;

  useEffect(() => {
    inputCallback(id, value, isValid);
  }, [id, inputCallback, value, isValid]);

  const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch({ type: "CHANGE", val: event.currentTarget.checked, validators });
  };

  return (
    <div className="checkbox-control">
      <input
        id={id}
        className={`checkbox-effect ${className}`}
        type="checkbox"
        name={id}
        checked={inputState.value}
        onChange={changeHandler}
      />
      <label htmlFor={id} />
      {children}
    </div>
  );
};

export default Checkbox;
