import { ChangeEvent, useEffect, useRef, useState } from "react";
import "./FileUpload.css";
import Button from "./Button";
import Avatar from "../UIElements/Avatar";
import userIcon from "../../../resources/images/account@3x.png";

type IFileType = "File" | "Image" | "SocialMedia";

interface IFileUpload {
  id: string;
  label: string;
  buttonText: string;
  icon?: string;
  outline?: boolean;
  type: IFileType;
  onInput: (id: string, pickedFile: any, fileIsValid: boolean, file?:any) => void;
  center?: boolean;
  showWarning?: boolean;
  acceptedTypes?: string;
  initialValue?: string;
  disabled?: boolean;
  initialFileName?: string;
}

const FileUpload = ({
  id,
  type,
  onInput,
  showWarning,
  center,
  label,
  buttonText,
  outline,
  icon,
  acceptedTypes,
  initialValue,
  disabled,
  initialFileName
}: IFileUpload): JSX.Element => {
  const [file, setPickedFile] = useState<File>();
  const [previewURL, setPreviewURL] = useState<string | ArrayBuffer | null>(null);
  const [isValid, setIsValid] = useState(false);
  const filePickerRef = useRef<HTMLInputElement>(null);
  const pickImageHandler = () => (filePickerRef.current as HTMLInputElement).click();
  const socialClickHandler = (): void => {};
  const [buttonTextState, setButtonTextState] = useState(buttonText);

  const pickedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let file: File | undefined = undefined;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
      setPickedFile(file);
      setIsValid(true);
      fileIsValid = true;
      setButtonTextState("File Selected Sucessfully");
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    onInput(id, previewURL, fileIsValid, file);
  };

  useEffect(() => {
    if (initialValue) {
      setPreviewURL(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!file) return;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewURL(fileReader.result);
      onInput(id, fileReader.result, true);
    };
    fileReader.readAsDataURL(file);
  }, [file, type, id, onInput]);

  const handleClearButton = () => {
    setPreviewURL(null);
    setPickedFile(undefined);
    onInput(id, null, true);
  };

  return (
    <div className="file-upload__container">
      {type !== "SocialMedia" && (
        <input
          ref={filePickerRef}
          type="file"
          accept={acceptedTypes}
          style={{ display: "none" }}
          id={`${id}`}
          onChange={pickedHandler}
        />
      )}
      <div className={`file-upload ${center && "center"}`}>
        {type === "Image" ? (
          <div className="file-upload__image-container" onClick={pickImageHandler}>
            <Avatar
              image={(previewURL as string) || userIcon}
              alt="Image Preview"
              width={120}
              isButton={previewURL ? true : false}
              onClickClear={handleClearButton}
              readonly={disabled}
            />
            <div className="file-upload__image-description">
              <span>{label}</span>
            </div>
            {previewURL === "" && showWarning && (
              <div className="form-container__notification">
                <p>
                  Candidates with a profile photo are 20% more likely to get interview requests because they are more memorable to
                  employers
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="file-upload__file-container">
            <div className="file-upload__file-container__display">
              {label !== "" && <span>{label}</span>}
              <Button
                outline={outline}
                inverse
                type="button"
                icon={icon}
                onClick={type !== "SocialMedia" ? pickImageHandler : socialClickHandler}
              >
                {buttonTextState}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
