import styled from 'styled-components';

export const ExternalLinkWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background-color: white;
  border-radius: 5px;
`;

