import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BasicUserData } from "../../users/pages/ProfilePage";
import { errorHandler, removeCookiesByPattern } from "../util/helpers";
import * as Sentry from "@sentry/react";
import { clearStoredAdmin } from "../../components/Candidate/hooks/admin-storage";
import validatedCandidate from "../../components/Candidate/hooks/candidateValidation";

let logoutTimer: NodeJS.Timeout;

export const useAuthentication = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [thorUser, setThorUser] = useState<BasicUserData | null>(null);
  const [expirationDate, setExpirationDate] = useState<number | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const loginToThor = useCallback(async (uid: string, value: string, expirationDate: number, email: string) => {
    try {
      setToken(value);
      setUserId(uid);
      setEmail(email);
      setExpirationDate(expirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          email,
          token: value,
          expDate: expirationDate,
        })
      );
      const { status, data } = await axios.get(`${process.env.REACT_APP_AWS_DEV_URL}/candidate/${email}`, {
        headers: { Authorization: `Bearer ${value}` },
      });

      if (status === 403) console.log("Token is invalid, access denied");
      status === 200 && setThorUser(data);
    } catch (error) {
      if (error instanceof Error) errorHandler({ error, customMessage: "Could not login to THOR" });
    }
  }, []);

  const cleanAuthState = useCallback(() => {
    setToken(null);
    setUserId(null);
    setEmail(null);
    setExpirationDate(null);
    clearStoredAdmin();
    localStorage.removeItem("userData");
    removeCookiesByPattern("CognitoIdentityServiceProvider");
  }, []);

  const logout = useCallback(async () => {
    try {
      cleanAuthState();
      await Auth.signOut();
    } catch (error) {
      if (error instanceof Error) errorHandler({ error, customMessage: "There was an error logging out" });
    }
  }, [cleanAuthState]);

  const assessLoggedInState = useCallback(
    async (isOnboarding: boolean, candidateEmail?: string) => {
      try {
        const session = await Auth.currentAuthenticatedUser();
        if (session) {
          setIsLoggingIn(true);
          const lowercaseEmail = (candidateEmail || session.attributes.email as string).trim().toLowerCase();
          await loginToThor(
            session.signInUserSession.accessToken.payload.client_id,
            session.signInUserSession.idToken.jwtToken,
            session.signInUserSession.accessToken.payload.exp,
            lowercaseEmail
          );
          setIsLoggingIn(false);
          setIsOnboarding(isOnboarding);
          Sentry.setUser({ email: lowercaseEmail });

          if (!userId) {
            axios.patch(`${process.env.REACT_APP_AWS_DEV_URL}/candidate/${lowercaseEmail}`, {
              timeLastLogin: new Date().getTime()
            }, {
              headers: { Authorization: `Bearer ${session.signInUserSession.idToken.jwtToken}` },
            });
          }
        }
      } catch (error) {
        logout();
        if (error instanceof Error) errorHandler({ error, customMessage: "There was an error verifying Cognito Session" });
      }
    },
    [logout, loginToThor]
  );

  const getToken = async () => {
    const isTokenExpired = expirationDate && expirationDate < new Date().getTime() / 1000;

    if (isTokenExpired) {
      try {
        const session = await Auth.currentAuthenticatedUser();
        const newToken = session.signInUserSession.idToken.jwtToken;
        setToken(session.signInUserSession.idToken.jwtToken);
        setExpirationDate(session.signInUserSession.accessToken.payload.exp);
        return newToken;
      } catch (error) {
        if (error instanceof Error) errorHandler({ error, customMessage: "There was an error refreshing the token" });
        await logout();
        window.location.replace('/login');
      }
    }

    return token;
  }

  useEffect(() => {
    if (token && expirationDate) logoutTimer = setTimeout(logout, expirationDate);
    else clearTimeout(logoutTimer);
  }, [token, logout, expirationDate]);

  return {
    token,
    userId,
    email,
    logout,
    thorUser: validatedCandidate(thorUser as BasicUserData) || null,
    setThorUser,
    loginToThor,
    isOnboarding,
    setIsOnboarding,
    assessLoggedInState,
    getToken,
    isLoggingIn
  };
};
