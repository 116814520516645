import { useContext } from "react";
import axios, { AxiosError } from "axios";
import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { queryKeys } from "../../../react-query/constants";
import { baseUrl } from "../../../axiosInstance/constants";
import { AuthContext } from "../../../users/components/context/auth-context";
import { errorHandler } from "../../../shared/util/helpers";

type PartialCandidate = Record<string, any>;

type MutationStatus = "error" | "idle" | "loading" | "success";

interface IPatchCandidateProps {
  data: PartialCandidate;
  getToken: () => Promise<string>;
  email?: string;
}

async function updateNewCandidate({
  data,
  getToken,
  email,
}: IPatchCandidateProps): Promise<any> {
  const token = await getToken();

  if (!data || !Object.keys(data).length) {
    return;
  }

  if (!token || !email) {
    return;
  }

  const response = await axios.patch(
    `${baseUrl}/candidate/${email}`,
    {
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
}

export const useUpdateCandidate = (): {
  mutate: UseMutateFunction<void, unknown, PartialCandidate, unknown>;
  mutateAsync: UseMutateFunction<void, unknown, PartialCandidate, unknown>;
  response: void | undefined;
  status: MutationStatus;
  isLoading: boolean;
  error: AxiosError;
} => {
  const { setThorUser, email, getToken } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const {
    mutate,
    mutateAsync,
    data: response,
    status,
    isLoading,
    error,
  } = useMutation(
    (candidate: PartialCandidate) =>
      updateNewCandidate({ data: candidate, email: email ?? "", getToken }),
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([queryKeys.candidate]);
        setThorUser(data);
      },
      onError: (error) => {
        if (error instanceof Error) {
          errorHandler({ error });
        }
      }

    }
  );

  return { mutate, mutateAsync, response, status, isLoading, error: error as AxiosError };
};
