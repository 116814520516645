import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IRequisition } from "@vendition/vendition-frontend";
import axios from "axios";
import { useContext } from "react";
import { baseUrl } from "../../../axiosInstance/constants";
import { queryKeys } from "../../../react-query/constants";
import { AuthContext } from "../../../users/components/context/auth-context";

interface IFetchRequisitionProps {
  email?: string;
  getToken(): Promise<string>;
}

interface IUseRequisitionsResponse {
  requisitions: IRequisition[] | undefined;
  error: unknown;
}

const fetchRequisitionData = async ({ email, getToken }: IFetchRequisitionProps): Promise<IRequisition[] | undefined> => {
  const token = await getToken();
  // !If user is undefined return before fetching
  if (!email || !token) return undefined;

  const result = await axios.get(`${baseUrl}/requisitions/${email}`, { headers: { Authorization: `Bearer ${token}` } });
  const requisitions = result.data.filter((requisition: IRequisition) => requisition.status === "Accepting Candidates");
  return requisitions;
};

export const useRequisitions = (): IUseRequisitionsResponse => {
  const { email, getToken } = useContext(AuthContext);
  const { data: requisitions, error } = useQuery(
    [queryKeys.requisitions],
    () => fetchRequisitionData({ email: email ?? undefined, getToken }),
    {
      enabled: !!email,
    }
  );

  return { requisitions, error };
};

export const usePrefetchRequisitions = (user: IFetchRequisitionProps): void => {
  const queryClient = useQueryClient();
  const { getToken } = useContext(AuthContext);
  queryClient.prefetchQuery([queryKeys.requisitions], () => fetchRequisitionData({ email: user.email, getToken }));
};
