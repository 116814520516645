import { Card } from "@vendition/vendition-frontend";
import styled from "styled-components";

export const ProfileContainer = styled.div`
  width: 83vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
  padding: 1rem 0;

  h1 {
    width: 100%;
    align-self: center;
    text-align: center;
  }

  @media (max-width: 840px) {
    width: 100vw;
    padding: 0;
  }
`;

export const ProfileCard = styled(Card)`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const ProfileVideoContainer = styled.div`
  width: 152px;
  height: 152px;
  border-radius: 4px;
  display: flex;
  margin: 0 0.5rem;
  border: 1px solid #eee;
  position: relative;
`;

export const ProfileCategoryVideo = styled.div`
  display: flex;
  flex-direction: row;
`;
