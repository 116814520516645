import { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { IFormDispatch, useForm } from "../../shared/hooks/form-hook";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "../../shared/util/validators";
import Card from "../../shared/components/UIElements/Card";
import chevron from "../../resources/graphics/arrow-back.svg";
import Input from "../../shared/components/FormElements/Input";
import ToastNotification, { IToastNotificationProps } from "../../shared/components/FormElements/ToastNotification";
import Button from "../../shared/components/FormElements/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { errorHandler } from "../../shared/util/helpers";

const CodeConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assessLoggedInState, isLoggedIn, setIsOnboarding, isOnboarding } = useContext(AuthContext);

  const { email = "", password = "" } = location.state ?? {};

  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const [formState, inputHandler] = useForm(
    {
      emailAddress: {
        value: "",
        isValid: false,
      },
      verificationCode: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const handleCognitoSignUp = async (username: string, code: string): Promise<Boolean> => {
    try {
      const signup = await Auth.confirmSignUp(username, code);
      if (signup) return true;

      return false;
    } catch (error) {
      if (error instanceof Error) {
        setToast({ type: "Error", title: "", message: errorHandler({ error }).message });
        if (error.message.includes("Current status is CONFIRMED")) return true;
      }
      return false;
    }
  };

  const confirmSignup = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const { emailAddress: email, verificationCode: code } = formState.inputs;
    try {
      let lowercaseEmail = email.value.trim().toLowerCase();
      // Send verification code to Amazon
      const signupResp = await handleCognitoSignUp(lowercaseEmail, code.value.trim());
      // Do a Cognito Login
      if (signupResp) {
        await Auth.signIn(lowercaseEmail, password);
        // Make sure we create context
        await assessLoggedInState(true);

        navigate("/signup/resume", {
          state: { tempSession: lowercaseEmail },
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        setToast({ type: "Error", title: "", message: errorHandler({ error }).message });
      }
    }
  };

  useEffect(() => {
    if (!location.state?.email) navigate("/signup");
  }, [location.state, navigate]);

  useEffect(() => {
    if (isLoggedIn) navigate("/dashboard");
    else setIsOnboarding(true);
  }, [setIsOnboarding, isOnboarding, isLoggedIn, navigate]);

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(location.state?.email);
      setToast({
        title: "",
        message: "Code Resent successfully",
        type: "Success",
      });
    } catch (error) {
      if (error instanceof Error) {
        const handledError = errorHandler({ error, customMessage: "Couldn't re-send code" });
        setToast({
          title: "",
          message: handledError.message,
          type: "Error",
        });
        if (handledError.navigation.route) {
          setTimeout(() => navigate(handledError.navigation.route, { state: handledError.navigation.state }), 1000);
        }
      }
    }
  };

  return (
    <Card className={"signup-content__container"}>
      <div className="progress-bar">
        <div className="progress-bar__progress" style={{ width: "11%" }} />
      </div>
      <div className="signup-header__chevron">
        <span onClick={() => navigate("/signup")}>
          <img src={chevron} alt="back" width="20" height="20" />
        </span>
      </div>
      <div className="signup-form__container">
        <h1>Please confirm your Email</h1>
        <h3>We've sent a code to your email, please share it with us</h3>
        <form className="curriculum-form" onSubmit={confirmSignup}>
          {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
          <Input
            id="emailAddress"
            element="input"
            type={"text"}
            label="Username"
            disabled={email ? true : false}
            initialValue={email}
            initialIsValid={email ? true : false}
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
            errorText="Enter a valid email"
            inputCallback={inputHandler as IFormDispatch}
            placeholder="jane.doe@example.com"
          />
          <Input
            id="verificationCode"
            element="input"
            type={"text"}
            label="Verification Code"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
            errorText="Enter a valid code"
            inputCallback={inputHandler as IFormDispatch}
            placeholder="------"
          />
          <div className="link-separator">
            <span>
              Didn't get the code? Be sure to check your spam folders and wait a minute for it to arrive. If you still can't find
              it, resend the email{" "}
              <b onClick={handleResendCode} className={"link"}>
                here
              </b>
            </span>
          </div>
          <Button disabled={!formState.isValid} size={"whole"}>
            Let's Go!
          </Button>
        </form>
      </div>
    </Card>
  );
};

export default CodeConfirmation;
