import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AutoComplete from "react-google-autocomplete";

import ProfileHeader from "../../../shared/components/navigation/ProfileHeader";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";

import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";

import { errorHandler } from "../../../shared/util/helpers";
import "./LocationInfo.css";

const CandidateLocation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentValue } = location.state ?? {};
  const { mutateAsync: updateCandidate } = useUpdateCandidate();
  const [currentLocation, setCurrentLocation] = useState({});


  const formIsValid = (): boolean => {
    return !!Object.keys(currentLocation).length;
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let requestObject = {...currentLocation};
    try {
      await updateCandidate(requestObject);
      navigate("/profile/page");
    } catch (error) {
      if (error instanceof Error) {
        console.log(errorHandler({ error, customMessage: "Your information was not updated" }).message);
      }
    }
  };


  const autocompleteHandler = (place: google.maps.places.PlaceResult) => {
    const getFromPlace = (type:string) => (place?.address_components?.find(item => item.types?.find(item => item === type)));
    const lat = place.geometry?.location?.lat();
    const lon = place.geometry?.location?.lng();
    let loc = {
      country: getFromPlace("country")?.short_name,
      state: getFromPlace("administrative_area_level_1")?.short_name,
      city: getFromPlace("locality")?.long_name,
      zip: getFromPlace("postal_code")?.long_name,
      geo: {
        time_zone: {
          id: "",
          code: ""
        },
        ip: "",
        type: "",
        continent_code: "",
        continent_name: "",
        country_code: getFromPlace("country")?.short_name,
        country_name: getFromPlace("country")?.long_name,
        region_code: getFromPlace("administrative_area_level_1")?.short_name,
        region_name: getFromPlace("administrative_area_level_1")?.long_name,
        city:getFromPlace("locality")?.long_name,
        zip:getFromPlace("postal_code")?.long_name,
        latitude: lat,
        longitude: lon,
      },
      physicalLocationGeo: { lat, lon },
    };

    setCurrentLocation(loc);
  };

  return (
    <Card className="signup-content__container">
      <ProfileHeader progress={44} backNavigation="/profile/job-title" /> 
      <div className="signup-form__container">
        <h1>Where are you currently located?</h1>
        <p>We approximate this information based on your IP address. You can edit your current location here if our approximation is inaccurate.  We only use this data to help match you to nearby jobs.</p>
        <form className="form-container" onSubmit={submitHandler}>
          <div className="form-control">
            <AutoComplete
              id="txtAuto"
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              onPlaceSelected={autocompleteHandler}
              options={{
                fields: ["address_components", "geometry"],
                types: ["postal_code", "locality", "sublocality"]
              }}
              defaultValue={currentValue}
            />
          </div>
          <div className="edit-form__button-container">
            <Button size="big" outline type="button" onClick={() => navigate("/profile/page")}>
              Cancel
            </Button>
            <Button disabled={!formIsValid()} size="big">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default CandidateLocation;
