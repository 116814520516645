import { useContext } from "react";
import { OverlayContext } from "../../../users/components/context/overlay-context";
import LoadingSpinner from "./LoadingSpinner";
import "./Overlay.css";

const Overlay = () => {
  const { wait } = useContext(OverlayContext);
  return wait ? (
    <div className="overlay">
      <LoadingSpinner />
    </div>
  ) : null;
};
export default Overlay;
