import React, { useEffect, useState } from "react";
import { ReactComponent as ImagoType } from "../../../resources/graphics/logo_emblem.svg";
import { ReactComponent as Close } from "../../../resources/graphics/close_grey.svg";
import { useLocation, useNavigate } from "react-router-dom";

import Card from "../../../shared/components/UIElements/Card";
import Button from "../../../shared/components/FormElements/Button";
import Input from "../../../shared/components/FormElements/Input";
import { VALIDATOR_LINKEDIN } from "../../../shared/util/validators";
import { IFormDispatch, IFormState, useForm } from "../../../shared/hooks/form-hook";
import "./LinkedInProfile.css";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useUpdateCandidate } from "../../../components/Candidate/hooks/useUpdateCandidate";
import ToastNotification, { IToastNotificationProps } from "../../../shared/components/FormElements/ToastNotification";

const LinkedInProfile = (): JSX.Element => {
  const [toast, setToast] = useState<IToastNotificationProps | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: updateCandidate, status, isLoading: loading, error } = useUpdateCandidate();

  const [formState, inputCallback] = useForm(
    {
      linkedIn: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    if (status === "success") {
      navigate(-1);
    }
  }, [navigate, status]);

  useEffect(() => {
    if (error) setToast({ title: "", message: error.message, type: "Error" });
  }, [error]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const requestObject = {
      linkedInProfile: formState.inputs.linkedIn.value,
    };

    updateCandidate(requestObject);
  };

  if (loading)
    return (
      <div className="loading-backdrop">
        <LoadingSpinner />
      </div>
    );

  return (
    <div className="basic-data__container">
      <Card className={"signup-content__container linkedin-container"}>
        <div className="signup-header__close">
          <ImagoType width={53} />
          <span onClick={() => navigate(-1)}>
            <Close width={24} height={24} stopColor={"#000"} />
          </span>
        </div>
        <div className="signup-form__container">
          <h1>Your Linkedin Profile</h1>
          <form className="form-container" onSubmit={submitHandler}>
            {toast && <ToastNotification title={toast.title} message={toast.message} type={toast.type} />}
            <Input
              id="linkedIn"
              placeholder="https://www.linkedin.com/in/"
              label="LinkedIn Profile"
              element="input"
              validators={[VALIDATOR_LINKEDIN()]}
              inputCallback={inputCallback as IFormDispatch}
              initialIsValid={location.state?.profile}
              initialValue={location.state?.profile}
              verbose
            />
            <div className="edit-form__button-container">
              <Button size="big" type="button" onClick={() => navigate(-1)} outline>
                Cancel
              </Button>
              <Button size="big" disabled={!(formState as IFormState).isValid}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
};

export default LinkedInProfile;
